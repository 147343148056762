import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      title: i18n.global.t('main.carts.neurosurgery.antiAdhesionGel.title1'),
      data: i18n.global.t('main.carts.neurosurgery.antiAdhesionGel.text1')
    }, {
      id: 2,
      title: i18n.global.t('main.carts.neurosurgery.antiAdhesionGel.title2'),
      data: i18n.global.t('main.carts.neurosurgery.antiAdhesionGel.text3')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.neurosurgery.antiAdhesionGel.text4')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.neurosurgery.antiAdhesionGel.text5')
    }]);
    const data = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.links.antiAdhesionGel'),
      subtitle: "",
      image: require('@/assets/neurosurgery/anti-adhesion-gel.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.neurosurgery.antiAdhesionGel.dataLeft1'),
        data: i18n.global.t('main.carts.neurosurgery.antiAdhesionGel.dataRight1')
      }, {
        id: 2,
        name: i18n.global.t('main.carts.neurosurgery.antiAdhesionGel.dataLeft2'),
        data: i18n.global.t('main.carts.neurosurgery.antiAdhesionGel.dataRight2')
      }]
    }]);
    return {
      data,
      textData
    };
  }

};