import { computed } from 'vue';
export default {
  name: 'TscScreen',

  setup() {
    const tableData = computed(() => [{
      first: '2,0',
      second: '440',
      third: '35900-20440'
    }, {
      first: '2,5',
      second: '440',
      third: '35900-25440'
    }, {
      first: '3,0',
      second: '440',
      third: '35900-30440'
    }, {
      first: '3,5',
      second: '440',
      third: '35900-35440'
    }, {
      first: '4,0',
      second: '440',
      third: '35900-40440'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/tsc.jpeg'),
      productsParams: [{
        id: 1,
        name: 'Матеріал',
        data: 'сплав титану'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};