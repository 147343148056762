import { computed } from 'vue';
import i18n from '@/i18n';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
export default {
  components: {
    Swiper,
    SwiperSlide
  },

  setup() {
    const carts = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.vertebralEndoprostheses.links.slidingLumbarVertebra'),
      img: require('@/assets/neurosurgery/sliding-lumbar-vertebra.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.vertebralEndoprostheses.links.slindigCervicalVertebra'),
      img: require('@/assets/neurosurgery/slindig-cervical-vertebra.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.vertebralEndoprostheses.links.slindigVertebraThoracicSpine'),
      img: require('@/assets/neurosurgery/slindig-vertebra-thoracicspine.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.vertebralEndoprostheses.links.slindigVertebraThoracicSpine2'),
      img: require('@/assets/neurosurgery/slindig-vertebra-thoracicspine2.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.vertebralEndoprostheses.links.lumbarIntervertebraldiscEdoprosthesis'),
      img: require('@/assets/neurosurgery/lumbar-intervertebraldisc-edoprosthesis.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.vertebralEndoprostheses.links.edoprosthesisCervicalIntervertebraldisc'),
      img: require('@/assets/neurosurgery/edoprosthesis-cervical-intervertebraldisc.png')
    }]);
    return {
      carts,
      modules: [Pagination]
    };
  }

};