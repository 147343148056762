import { ref } from 'vue';
export default {
  name: 'MainHeader',

  setup() {
    const options = ref([{
      name: 'УКР',
      value: 'ua',
      flag: require('@/assets/ua-flag.png')
    }, {
      name: 'ENG',
      value: 'en',
      flag: require('@/assets/en-flag.png')
    }, {
      name: 'РУС',
      value: 'ru',
      flag: require('@/assets/ru-flag.png')
    }]);
    const selected = ref(localStorage.getItem('lang') === 'ua' ? options.value[0] : localStorage.getItem('lang') === 'en' ? options.value[1] : localStorage.getItem('lang') === 'ru' ? options.value[2] : options.value[0]);

    function optionSelect(option) {
      selected.value = option;
    }

    return {
      options,
      selected,
      optionSelect
    };
  }

};