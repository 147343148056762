import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.title1')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.text2')
    }, {
      id: 3,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.text3')
    }, {
      id: 4,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.text4'),
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.title2')
    }, {
      id: 5,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.text5')
    }, {
      id: 6,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.text6')
    }, {
      id: 7,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.text7')
    }, {
      id: 8,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.text8')
    }, {
      id: 9,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.text9')
    }, {
      id: 10,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.text10')
    }, {
      id: 11,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.text11')
    }, {
      id: 12,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.text12')
    }, {
      id: 13,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.text13')
    }, {
      id: 14,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.text14')
    }]);
    const coagulatorData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.links.coagulator'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/equipmentOperatingRooms/coagulator.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataLeft1'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataRight1')
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataLeft2'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataRight2')
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataLeft3'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataRight3')
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataLeft4'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataRight4')
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataLeft5'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataRight5')
      }, {
        id: 6,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataLeft6'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataRight6')
      }, {
        id: 7,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataLeft7'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataRight7')
      }, {
        id: 8,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataLeft8'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataRight8')
      }, {
        id: 9,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataLeft9'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataRight9')
      }, {
        id: 10,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataLeft10'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataRight10')
      }, {
        id: 11,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataLeft11'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataRight11')
      }, {
        id: 12,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataLeft12'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataRight12')
      }, {
        id: 13,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataLeft13'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataRight13')
      }, {
        id: 14,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataLeft14'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataRight14')
      }, {
        id: 15,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataLeft15'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataRight15')
      }, {
        id: 16,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataLeft16'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.coagulator.coagulatorDataRight16')
      }]
    }]);
    return {
      coagulatorData,
      textData
    };
  }

};