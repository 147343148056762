import { computed } from 'vue';
import ChancetanScrew from './grid/ChancetanScrew.vue';
import TscScreen from './grid/TscScreen.vue';
import StaimansRobs from './grid/StaimansRobs.vue';
import StaimansRobsWithNotch from './grid/StaimansRobsWithNotch.vue';
import ShancRobs from './grid/ShancRobs.vue';
import ShancRobsSecond from './grid/ShancRobsSecond.vue';
import ShancRobsThird from './grid/ShancRobsThird.vue';
import ScrewRoster from './grid/ScrewRoster.vue';
import ConicalScrew from './grid/ConicalScrew.vue';
export default {
  name: 'RobsScreen',

  setup() {
    const cardData = computed(() => [{
      title: 'Стержні',
      image: require('@/assets/traumatology/robs.jpeg')
    }]);
    return {
      cardData
    };
  },

  components: {
    ChancetanScrew,
    TscScreen,
    StaimansRobs,
    StaimansRobsWithNotch,
    ShancRobs,
    ShancRobsSecond,
    ShancRobsThird,
    ScrewRoster,
    ConicalScrew
  }
};