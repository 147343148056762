import { computed, ref } from 'vue';
import i18n from '@/i18n';
export default {
  name: 'closedLaminarHook',

  setup() {
    const tableData = ref([{
      first: '5X5',
      second: '36193-05005'
    }]);
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.spinalHooksLinks.closedLaminarHook'),
      subtitle: "",
      image: require('@/assets/neurosurgery/closed-laminar-hook.png'),
      productsParams: []
    }]);
    return {
      cardData,
      tableData
    };
  }

};