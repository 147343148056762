import { computed } from 'vue';
export default {
  name: 'ShancRobs',

  setup() {
    const tableData = computed(() => [{
      first: 110,
      second: 30,
      third: '5.009.110'
    }, {
      first: 120,
      second: 30,
      third: '5.009.120'
    }, {
      first: 140,
      second: 30,
      third: '5.009.140'
    }, {
      first: 160,
      second: 30,
      third: '5.009.160'
    }, {
      first: 180,
      second: 30,
      third: '5.009.180'
    }, {
      first: 200,
      second: 30,
      third: '5.009.200'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/shanc-robs.jpeg'),
      productsParams: [{
        id: 1,
        name: 'Матеріал',
        data: 'нерж. сталь'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};