import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "default_mobile__card"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductInfoCardMobile = _resolveComponent("ProductInfoCardMobile");

  const _component_SpokeFirst = _resolveComponent("SpokeFirst");

  const _component_SpokeSecond = _resolveComponent("SpokeSecond");

  const _component_SpokeShoulder = _resolveComponent("SpokeShoulder");

  const _component_SpokeShoulderTypeK = _resolveComponent("SpokeShoulderTypeK");

  const _component_SpokeSteinman = _resolveComponent("SpokeSteinman");

  const _component_SpokeSimple = _resolveComponent("SpokeSimple");

  const _component_PinMedialThread = _resolveComponent("PinMedialThread");

  const _component_SpokeCondylus = _resolveComponent("SpokeCondylus");

  const _component_SpokeShoulderAndTibial = _resolveComponent("SpokeShoulderAndTibial");

  const _component_KirchenersSpoke = _resolveComponent("KirchenersSpoke");

  const _component_KirchenersSpokeSecond = _resolveComponent("KirchenersSpokeSecond");

  const _component_IlizarovsSpoke = _resolveComponent("IlizarovsSpoke");

  const _component_IlizarovsSpokeSecond = _resolveComponent("IlizarovsSpokeSecond");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ProductInfoCardMobile, {
      image: $setup.cardData[0].image,
      title: $setup.cardData[0].title,
      subtitle: $setup.cardData[0].subtitle,
      productsParams: $setup.cardData[0].productsParams
    }, null, 8, ["image", "title", "subtitle", "productsParams"]), _createVNode(_component_SpokeFirst), _createVNode(_component_SpokeSecond), _createVNode(_component_SpokeShoulder), _createVNode(_component_SpokeShoulderTypeK), _createVNode(_component_SpokeSteinman), _createVNode(_component_SpokeSimple), _createVNode(_component_PinMedialThread), _createVNode(_component_SpokeCondylus), _createVNode(_component_SpokeShoulderAndTibial), _createVNode(_component_KirchenersSpoke), _createVNode(_component_KirchenersSpokeSecond), _createVNode(_component_IlizarovsSpoke), _createVNode(_component_IlizarovsSpokeSecond)])]),
    _: 1
  });
}