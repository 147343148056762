import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const sterilizationCabinetData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerLinks.sterilizationCabinet'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.sterilizationCabinet.title'),
      image: require('@/assets/toolsAndEquipment/sterilization-cabinet.png'),
      productsParams: []
    }]);
    return {
      sterilizationCabinetData
    };
  }

};