import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const bactericidalIrradiator3Lamp225MData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.bactericidalIrradiatorsLinks.bactericidalIrradiator3Lamp225M'),
      subtitle: '',
      image: require('@/assets/toolsAndEquipment/bactericida-iIrradiator-3Lamp225M.png'),
      productsParams: []
    }]);
    return {
      bactericidalIrradiator3Lamp225MData
    };
  }

};