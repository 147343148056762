import { computed } from 'vue';
export default {
  setup() {
    const cardData = computed(() => [{
      title: 'Медипор плюс ПАД',
      image: require('@/assets/surgery/medipore-plus-pad.jpeg')
    }]);
    return {
      cardData
    };
  }

};