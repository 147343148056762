import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  name: 'ZmProduction',

  setup() {
    const sutureMaterialsCardsFirst = computed(() => [{
      title: 'Скотчкаст Плюс',
      img: require('@/assets/surgery/scotchcast-plus.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/zm-production/scotchkast-plus`
    }, {
      title: 'Софткаст',
      img: require('@/assets/surgery/softcast.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/zm-production/softcast`
    }, {
      title: 'Айобан',
      img: require('@/assets/surgery/ayoban.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/zm-production/ayoban`
    }, {
      title: 'Кобан',
      img: require('@/assets/surgery/coban.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/zm-production/coban`
    }, {
      title: 'Пластирний шкірний шов',
      img: require('@/assets/surgery/plaster-skin-suture.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/zm-production/plaster-skin-suture`
    }, {
      title: 'Медіпор',
      img: require('@/assets/surgery/medipore.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/zm-production/medipore`
    }, {
      title: 'Транспор',
      img: require('@/assets/surgery/transpor.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/zm-production/transpor`
    }, {
      title: 'Дюрапор',
      img: require('@/assets/surgery/durapore.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/zm-production/durapor`
    }, {
      title: 'Медіпор плюс Пад',
      img: require('@/assets/surgery/medipore-plus-pad.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/zm-production/medipor-plus-pad`
    }, {
      title: 'Тегадерм',
      img: require('@/assets/surgery/tegaderm.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/zm-production/tegaderm`
    }, {
      title: 'Мікрофом',
      img: require('@/assets/surgery/microfom.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/zm-production/microfom`
    }]);
    return {
      sutureMaterialsCardsFirst
    };
  }

};