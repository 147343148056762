import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_UvCameraLargeMobile = _resolveComponent("UvCameraLargeMobile");

  const _component_MqResponsive = _resolveComponent("MqResponsive");

  const _component_UvCameraLarge = _resolveComponent("UvCameraLarge");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_MqResponsive, {
    target: ['xs', 'sm']
  }, {
    default: _withCtx(() => [_createVNode(_component_UvCameraLargeMobile)]),
    _: 1
  }), _createVNode(_component_MqResponsive, {
    target: ['md', 'lg', 'xl', 'xxl']
  }, {
    default: _withCtx(() => [_createVNode(_component_UvCameraLarge)]),
    _: 1
  })], 64);
}