import { computed } from 'vue';
import i18n from '@/i18n';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
export default {
  components: {
    Swiper,
    SwiperSlide
  },

  setup() {
    const traumatologyCarts = computed(() => [{
      title: i18n.global.t('main.carts.traumatology.links.microscrews'),
      img: require('@/assets/traumatology/microscrews.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/ao-screws/micro-screws/microscrew`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.cancellousScrews'),
      img: require('@/assets/traumatology/cancellous-screws.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/ao-screws/sponge-screw`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.corticalScrews'),
      img: require('@/assets/traumatology/cortical-screws.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/ao-screws/cortical-screw`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.threadResistantScrews'),
      img: require('@/assets/traumatology/screws-thread-stable.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/ao-screws/thread-resistance-screw`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.specialScrews'),
      img: require('@/assets/traumatology/special-screws.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/ao-screws/special-screws`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.screwsInsertsWithAngularStability'),
      img: require('@/assets/traumatology/screws-for-plates-with-angular-stability.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/ao-screws/screws-with-inserts-for-angular-stability`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.cannulatedScrews'),
      img: require('@/assets/traumatology/cannulated-screws.jpeg'),
      link: `/${i18n.global.locale.value}/404`
    }]);
    return {
      traumatologyCarts,
      modules: [Pagination]
    };
  }

};