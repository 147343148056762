import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const BactericidalIrradiatorsCarts = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.bactericidalIrradiatorsLinks.bactericidalIrradiator1Lamp'),
      img: require('@/assets/toolsAndEquipment/bactericida-iIrradiator-1Lamp.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/reflectors-bactericidal/obluchatel-bakteritsidnyi-1-lampovyi-obn-75`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.bactericidalIrradiatorsLinks.bactericidalIrradiator2Lamp150M'),
      img: require('@/assets/toolsAndEquipment/bactericida-iIrradiator-2Lamp150M.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/reflectors-bactericidal/reflectors_bactericidal/obluchatel-bakteritsidnyi-2-lampovyi-obn-150m`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.bactericidalIrradiatorsLinks.bactericidalIrradiator2Lamp150MP'),
      img: require('@/assets/toolsAndEquipment/bactericida-iIrradiator-2Lamp150MP.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/reflectors-bactericidal/obluchatel-bakteritsidnyi-2-lampovyi-obn-150mp`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.bactericidalIrradiatorsLinks.bactericidalIrradiator4Lamp300M'),
      img: require('@/assets/toolsAndEquipment/bactericida-iIrradiator-4Lamp300M.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/reflectors-bactericidal/obluchatel-bakteritsidnyi-4-lampovyi-obn-300m`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.bactericidalIrradiatorsLinks.bactericidalIrradiator3Lamp225M'),
      img: require('@/assets/toolsAndEquipment/bactericida-iIrradiator-3Lamp225M.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/reflectors-bactericidal/obluchatel-bakteritsidnyi-3-lampovyi-peredvizhnoi-obpe-225m`
    }]);
    return {
      BactericidalIrradiatorsCarts
    };
  }

};