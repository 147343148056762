import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "default_mobile__card"
};
const _hoisted_2 = {
  class: "card",
  style: {
    "padding": "10px"
  }
};
const _hoisted_3 = {
  class: "card__product-info"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  class: "card__product-title"
};
const _hoisted_6 = {
  class: "blue-txt"
};
const _hoisted_7 = {
  class: "card__product-data",
  style: {
    "color": "#000",
    "text-align": "left"
  }
};
const _hoisted_8 = {
  key: 1
};
const _hoisted_9 = {
  class: "card__product-title"
};
const _hoisted_10 = {
  class: "blue-txt"
};
const _hoisted_11 = {
  class: "card__product-data",
  style: {
    "color": "#000",
    "text-align": "left"
  }
};
const _hoisted_12 = {
  key: 2
};
const _hoisted_13 = {
  class: "card__product-data",
  style: {
    "color": "#000",
    "text-align": "left"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductInfoCardMobile = _resolveComponent("ProductInfoCardMobile");

  const _component_el_table_column = _resolveComponent("el-table-column");

  const _component_el_table = _resolveComponent("el-table");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ProductInfoCardMobile, {
      image: $setup.cardData[0].image,
      title: $setup.cardData[0].title,
      subtitle: $setup.cardData[0].subtitle,
      productsParams: $setup.cardData[0].productsParams
    }, null, 8, ["image", "title", "subtitle", "productsParams"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.textData, item => {
      return _openBlock(), _createElementBlock("div", {
        class: "card__product-info-inner",
        key: item.id
      }, [item.id === 1 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_5, [_createElementVNode("h3", _hoisted_6, _toDisplayString(item.title), 1)]), _createElementVNode("div", _hoisted_7, _toDisplayString(item.data), 1)])])) : item.id === 2 ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_9, [_createElementVNode("h3", _hoisted_10, _toDisplayString(item.title), 1)]), _createElementVNode("div", _hoisted_11, _toDisplayString(item.data), 1)])])) : (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, _toDisplayString(item.data), 1)]))]);
    }), 128))])]), _createVNode(_component_el_table, {
      "header-cell-style": {
        background: '#00AEEF',
        color: '#FFF'
      },
      "cell-style": {
        padding: '0',
        height: '50px',
        color: '#1B1C1E'
      },
      data: $setup.tableData,
      "scrollbar-always-on": true,
      style: {
        "margin-bottom": "10px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_table_column, {
        label: _ctx.$t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.table.first'),
        prop: "first",
        "min-width": "140px"
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        label: _ctx.$t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.table.second'),
        prop: "second",
        "min-width": "140px"
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        label: _ctx.$t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.table.third'),
        prop: "third",
        "min-width": "140px"
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        label: _ctx.$t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.table.fourth'),
        prop: "fourth",
        "min-width": "140px"
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        label: _ctx.$t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.table.fifth'),
        prop: "fifth",
        "min-width": "140px"
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        label: _ctx.$t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.table.sixth'),
        prop: "sixth",
        "min-width": "140px"
      }, null, 8, ["label"]), _createVNode(_component_el_table_column, {
        label: _ctx.$t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.table.seventh'),
        prop: "seventh",
        "min-width": "140px"
      }, null, 8, ["label"])]),
      _: 1
    }, 8, ["data"])])]),
    _: 1
  });
}