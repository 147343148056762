import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "default_mobile__card"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductInfoCardMobile = _resolveComponent("ProductInfoCardMobile");

  const _component_el_table_column = _resolveComponent("el-table-column");

  const _component_el_table = _resolveComponent("el-table");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ProductInfoCardMobile, {
      image: $setup.cardData[0].image,
      title: $setup.cardData[0].title,
      subtitle: $setup.cardData[0].subtitle,
      productsParams: $setup.cardData[0].productsParams
    }, null, 8, ["image", "title", "subtitle", "productsParams"]), _createVNode(_component_el_table, {
      "header-cell-style": {
        background: '#00AEEF',
        color: '#FFF'
      },
      "cell-style": {
        padding: '0',
        height: '50px',
        color: '#1B1C1E'
      },
      data: $setup.tableData,
      style: {
        "width": "100%",
        "margin-bottom": "10px"
      },
      "scrollbar-always-on": true
    }, {
      default: _withCtx(() => [_createVNode(_component_el_table_column, {
        label: _ctx.$t('main.carts.neurosurgery.connectionHook.table.first'),
        prop: "first",
        "min-width": "140px"
      }, null, 8, ["label"])]),
      _: 1
    }, 8, ["data"])])]),
    _: 1
  });
}