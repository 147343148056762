import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.title1')
    }]);
    const omronNeC30Data = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.links.omronNeC30'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/inhalersNebulizers/omron-ne-C30.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataLeft1'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataRight1')
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataLeft2'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataRight2')
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataLeft3'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataRight3')
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataLeft4'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataRight4')
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataLeft5'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataRight5')
      }, {
        id: 6,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataLeft6'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataRight6')
      }, {
        id: 7,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataLeft7'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataRight7')
      }, {
        id: 8,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataLeft8'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataRight8')
      }, {
        id: 9,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataLeft9'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC30.dataRight9')
      }]
    }]);
    return {
      omronNeC30Data,
      textData
    };
  }

};