import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const stretcherMedicalB03Data = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.stretcherMedicalB03'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB03.subtitle'),
      image: require('@/assets/toolsAndEquipment/medicalFurniture/stretcher-medical-B03.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB03.dataLeft1'),
        data: "196Х56Х865"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB03.dataLeft2'),
        data: "196Х56Х28"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB03.dataLeft3'),
        data: "65"
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB03.dataLeft4'),
        data: "38"
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB03.dataLeft5'),
        data: "44	"
      }, {
        id: 6,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB03.dataLeft6'),
        data: "160"
      }]
    }]);
    return {
      stretcherMedicalB03Data
    };
  }

};