import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const textData = computed(() => []);
    const treatmentCouchKPData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.treatmentCouchKP'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.treatmentCouchKP.subtitle'),
      image: require('@/assets/toolsAndEquipment/medicalFurniture/treatment-couch-KP.png'),
      productsParams: []
    }]);
    return {
      treatmentCouchKPData,
      textData
    };
  }

};