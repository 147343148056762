import { computed } from 'vue';
import i18n from '@/i18n';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
export default {
  components: {
    Swiper,
    SwiperSlide
  },

  setup() {
    const traumatologyCarts = computed(() => [{
      title: i18n.global.t('main.carts.traumatology.links.instrumentSetCalfThighRetrogradeThigh'),
      img: require('@/assets/traumatology/instrument-set-calf-thigh-retrograde-thigh.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/tools/instruments-calf-thigh-retrogradet-thigh`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.setOfToolsForSettingTrochanterPinZType'),
      img: require('@/assets/traumatology/set-of-tools-for-setting-trochanter-pin-z-type.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/tools/z-type-trochanteric-stud-tool-kit`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.humerusInstrument'),
      img: require('@/assets/traumatology/humerus-istrument.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/tools/humerus-tool`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.toolForDtssDcssInsert'),
      img: require('@/assets/traumatology/tool-for-dtss-dcss-insert.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/tools/tool-dtss-dcss-inserts`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.toolTraumatological'),
      img: require('@/assets/traumatology/tool-traumatology.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/tools/tool-traumatology`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.toolTraumatologicalMedimetal'),
      img: require('@/assets/traumatology/tool-traumatological-medimetal.jpeg'),
      link: `/${i18n.global.locale.value}/404`
    }]);
    return {
      traumatologyCarts,
      modules: [Pagination]
    };
  }

};