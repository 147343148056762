import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const gynecologicalChairElectricDriveData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.gynecologicalChairElectricDrive'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairElectricDrive.subtitle'),
      image: require('@/assets/toolsAndEquipment/medicalFurniture/gynecological-chair-electricdrive.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairElectricDrive.dataLeft1'),
        data: "550–1300"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairElectricDrive.dataLeft2'),
        data: "	600"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairElectricDrive.dataLeft3'),
        data: "0-90"
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairElectricDrive.dataLeft4'),
        data: "600"
      }]
    }]);
    return {
      gynecologicalChairElectricDriveData
    };
  }

};