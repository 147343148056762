import { computed } from 'vue';
import i18n from '@/i18n';
import CorticalScrewFirstGrid from './grid/CorticalScrewFirstGrid.vue';
import CorticalScrewSecondGrid from './grid/CorticalScrewSecondGrid.vue';
import CorticalScrewThirdGrid from './grid/CorticalScrewThirdGrid.vue';
export default {
  name: 'MicroScrew',

  setup() {
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.traumatology.microScrews.title'),
      image: require('@/assets/traumatology/microscrews.jpeg')
    }]);
    return {
      cardData
    };
  },

  components: {
    CorticalScrewFirstGrid,
    CorticalScrewSecondGrid,
    CorticalScrewThirdGrid
  }
};