import { computed } from 'vue';
import i18n from '@/i18n';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
export default {
  components: {
    Swiper,
    SwiperSlide
  },

  setup() {
    const carts = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.links.cervicalPlatesTitanium'),
      img: require('@/assets/neurosurgery/cervical-plates-titanium.png'),
      link: `/${i18n.global.locale.value}/catalog/neurosurgery/plates-neurosurgical/plastiny-sheinye-titanovye`
    }, {
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.links.hPlate'),
      img: require('@/assets/neurosurgery/h-plate.png'),
      link: `/${i18n.global.locale.value}/catalog/neurosurgery/plates-neurosurgical/plastina-h-obraznaya`
    }, {
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.links.trapezoidalPlate'),
      img: require('@/assets/neurosurgery/trapezoidal-plate.png'),
      link: `/${i18n.global.locale.value}/catalog/neurosurgery/plates-neurosurgical/plastina-trapetsioobraznaya`
    }]);
    return {
      carts,
      modules: [Pagination]
    };
  }

};