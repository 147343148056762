import { computed } from 'vue';
import i18n from '@/i18n';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
export default {
  name: 'MainScreenMobile',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    msg: {
      type: String,
      default: ''
    }
  },

  setup() {
    const traumatologyLinks = computed(() => [{
      id: 1,
      title: i18n.global.t('main.carts.traumatology.links.intramedullaryPins'),
      path: `/${i18n.global.locale.value}/catalog/traumatology/intramedullary-pins`
    }, {
      id: 2,
      title: i18n.global.t('main.carts.traumatology.links.blockedPlates'),
      path: `/${i18n.global.locale.value}/catalog/traumatology/blocked-plates`
    }, {
      id: 3,
      title: i18n.global.t('main.carts.traumatology.links.tool'),
      path: `/${i18n.global.locale.value}/catalog/traumatology/tool`
    }, {
      id: 4,
      title: i18n.global.t('main.carts.traumatology.links.screwsAT'),
      path: `/${i18n.global.locale.value}/catalog/traumatology/screw-at`
    }, {
      id: 5,
      title: i18n.global.t('main.carts.traumatology.links.JSCPlates'),
      path: `/${i18n.global.locale.value}/catalog/traumatology/jsc-plates`
    }, {
      id: 6,
      title: i18n.global.t('main.carts.traumatology.links.needlesRodsCerclage'),
      path: `/${i18n.global.locale.value}/catalog/traumatology/needles-rods-cerclage`
    }, {
      id: 7,
      title: i18n.global.t('main.carts.traumatology.links.microsurgery'),
      path: `/${i18n.global.locale.value}/catalog/traumatology/microsurgery`
    }, {
      id: 8,
      title: i18n.global.t('main.carts.traumatology.links.ilizarovsApparatus'),
      path: `/${i18n.global.locale.value}/catalog/traumatology/ilizarovs-apparatus`
    }]);
    const surgeryLinks = computed(() => [i18n.global.t('main.carts.surgery.links.sutureMaterials'), i18n.global.t('main.carts.surgery.links.endoprosthesesPolymerNets'), i18n.global.t('main.carts.surgery.links.endoprosthesesForUrogynecology'), i18n.global.t('main.carts.surgery.links.antiAdhesiveAgents'), i18n.global.t('main.carts.surgery.links.woundCovers'), i18n.global.t('main.carts.surgery.links.hemostaticSponge'), i18n.global.t('main.carts.surgery.links.productsPfZMCompany')]);
    const neurosurgeryLinks = computed(() => [i18n.global.t('main.carts.neurosurgery.links.transpedicularFixationOfTheSpine'), i18n.global.t('main.carts.neurosurgery.links.neurosurgicalPlates'), i18n.global.t('main.carts.neurosurgery.links.vertebralEndoprostheses'), i18n.global.t('main.carts.neurosurgery.links.neurosurgicalInstrument'), i18n.global.t('main.carts.neurosurgery.links.antiAdhesionGel')]);
    const toolsAndEquipmentLinks = computed(() => [i18n.global.t('main.carts.toolsAndEquipment.links.equipmentForOperatingRooms'), i18n.global.t('main.carts.toolsAndEquipment.links.medicalFurniture'), i18n.global.t('main.carts.toolsAndEquipment.links.sterilization'), i18n.global.t('main.carts.toolsAndEquipment.links.cardiology'), i18n.global.t('main.carts.toolsAndEquipment.links.medicalInstrument')]);
    return {
      traumatologyLinks,
      surgeryLinks,
      neurosurgeryLinks,
      toolsAndEquipmentLinks,
      modules: [Pagination]
    };
  }

};