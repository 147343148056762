import { computed } from 'vue';
export default {
  name: 'StandScrew',

  setup() {
    const tableData = computed(() => [{
      first: '2',
      second: '3.2.2'
    }, {
      first: '3',
      second: '3.2.3'
    }, {
      first: '4',
      second: '3.2.4'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/stand-screw.jpeg')
    }]);
    return {
      tableData,
      cardData
    };
  }

};