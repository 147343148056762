import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.manipulationTableCM3.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.manipulationTableCM3.title1')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.manipulationTableCM3.text2'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.manipulationTableCM3.title2')
    }]);
    const manipulationTableCM3Data = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.manipulationTableCM3'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/manipulation-table-CM3.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.manipulationTableCM3.dataLeft1'),
        data: "630*530*900"
      }]
    }]);
    return {
      manipulationTableCM3Data,
      textData
    };
  }

};