import { computed, ref } from 'vue';
import i18n from '@/i18n';
export default {
  name: 'sacralScrew',

  setup() {
    const tableData = ref([{
      first: 'Ø6,5',
      second: '40',
      third: '36220-65040'
    }, {
      first: 'Ø6,5',
      second: '45',
      third: '36220-65045'
    }, {
      first: 'Ø6,5',
      second: '50',
      third: '36220-65050'
    }]);
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.spinalSystemsLinks.sacralScrew'),
      subtitle: "",
      image: require('@/assets/neurosurgery/sacral-screw.png'),
      productsParams: []
    }]);
    return {
      cardData,
      tableData
    };
  }

};