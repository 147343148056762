import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PageNotFound = _resolveComponent("PageNotFound");

  const _component_MqResponsive = _resolveComponent("MqResponsive");

  return _openBlock(), _createBlock(_component_MqResponsive, {
    target: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']
  }, {
    default: _withCtx(() => [_createVNode(_component_PageNotFound)]),
    _: 1
  });
}