import { computed } from 'vue';
export default {
  name: 'ShoulderPlate',

  setup() {
    const tableData = computed(() => [{
      first: '90',
      second: '3',
      third: '13710-09003',
      fourth: '33710-09003'
    }, {
      first: '136',
      second: '6',
      third: '13710-13606',
      fourth: '33710-13606'
    }, {
      first: '153',
      second: '7',
      third: '13710 -15307',
      fourth: '33710-15307'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/shoulder-plate-default.jpeg'),
      productsParams: [{
        id: 1,
        name: 'Товщина',
        data: '2,0 мм'
      }, {
        id: 2,
        name: 'Ширина',
        data: '28 мм'
      }, {
        id: 3,
        name: 'Діаметр гвинта',
        data: '4,5 мм'
      }, {
        id: 8,
        name: 'Матеріал',
        data: 'Нерж. сталь або сплав титану'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};