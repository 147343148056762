export default {
  name: 'CatalogCart',
  props: {
    title: {
      type: String,
      default: 'Some default title'
    },
    text: {
      type: String,
      default: 'Some default text'
    },
    img: {
      type: String,
      default: null
    },
    imgPosition: {
      type: String,
      default: 'bottom'
    }
  },

  setup() {
    return {};
  }

};