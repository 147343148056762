import { computed, ref } from 'vue';
import i18n from '@/i18n';
export default {
  name: 'staightGirder',

  setup() {
    const tableData = ref([{
      first: '16',
      second: '5',
      third: '23 mm',
      fourth: '36010-02305'
    }, {
      first: '18',
      second: '5',
      third: '25mm',
      fourth: '36010-02505'
    }, {
      first: '16+18',
      second: '8',
      third: '41mm',
      fourth: '36010-04108'
    }, {
      first: '16+18+18',
      second: '11',
      third: '59mm',
      fourth: '36010-05911'
    }, {
      first: '21',
      second: '5',
      third: '28mm',
      fourth: '36010-02805'
    }, {
      first: '18+21',
      second: '8',
      third: '46mm',
      fourth: '36010-04608'
    }, {
      first: '18+18+21',
      second: '11',
      third: '64mm',
      fourth: '36010-64011'
    }, {
      first: '16+18+18+21',
      second: '14',
      third: '80mm',
      fourth: '36010-08014'
    }]);
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.links.hPlate'),
      subtitle: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.hPlate.subtitle'),
      image: require('@/assets/neurosurgery/h-plate.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.hPlate.dataLeft1'),
        data: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.hPlate.dataRight1')
      }, {
        id: 2,
        name: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.hPlate.dataLeft2'),
        data: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.hPlate.dataRight2')
      }, {
        id: 3,
        name: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.hPlate.dataLeft3'),
        data: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.hPlate.dataRight3')
      }]
    }]);
    return {
      cardData,
      tableData
    };
  }

};