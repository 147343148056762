import { computed } from 'vue';
export default {
  name: 'ShinPlate',

  setup() {
    const tableData = computed(() => [{
      first: '2',
      second: '13890-00001',
      third: '33890-00001'
    }, {
      first: '1',
      second: '13890-00002',
      third: '33890-00002'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/shine-plate.jpeg'),
      productsParams: [{
        id: 1,
        name: 'Товщина',
        data: '3,0 мм'
      }, {
        id: 2,
        name: 'Ширина',
        data: '16 мм'
      }, {
        id: 3,
        name: 'Діаметр гвинта',
        data: '4,5 мм'
      }, {
        id: 8,
        name: 'Матеріал',
        data: 'Нерж. сталь або сплав титану'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};