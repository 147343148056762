import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.title1')
    }]);
    const luminaireL751IISingleData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.links.luminaireL751IISingle'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.subtitle'),
      image: require('@/assets/toolsAndEquipment/equipmentOperatingRooms/luminaire-L751IISingle.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataLeft1'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataRight1')
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataLeft2'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataRight2')
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataLeft3'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataRight3')
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataLeft4'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataRight4')
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataLeft5'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataRight5')
      }, {
        id: 6,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataLeft6'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataRight6')
      }, {
        id: 7,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataLeft7'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataRight7')
      }, {
        id: 8,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataLeft8'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataRight8')
      }, {
        id: 9,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataLeft9'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL751IISingle.dataRight9')
      }]
    }]);
    return {
      luminaireL751IISingleData,
      textData
    };
  }

};