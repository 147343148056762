import { computed } from 'vue';
export default {
  setup() {
    const cardData = computed(() => [{
      title: 'Міні пластини',
      image: require('@/assets/traumatology/mini-plate-t-shaped.jpeg')
    }]);
    return {
      cardData
    };
  }

};