import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-61a33e42"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "inserts-with-angular-stability"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "inserts-with-angular-stability__inner"
};
const _hoisted_4 = {
  class: "inserts-with-angular-stability__card"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MainHeader = _resolveComponent("MainHeader");

  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");

  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");

  const _component_LeftLinksMenu = _resolveComponent("LeftLinksMenu");

  const _component_ProductInfoCard = _resolveComponent("ProductInfoCard");

  const _component_SmallLockingSelfCompressingPlateGrid = _resolveComponent("SmallLockingSelfCompressingPlateGrid");

  const _component_SmallLockingLowContactSelfCompressingPlateGrid = _resolveComponent("SmallLockingLowContactSelfCompressingPlateGrid");

  const _component_LockingSmallTPlateGrid = _resolveComponent("LockingSmallTPlateGrid");

  const _component_LockingSmallTPlateObliqueGrid = _resolveComponent("LockingSmallTPlateObliqueGrid");

  const _component_LockingReconstructionPlateGrid = _resolveComponent("LockingReconstructionPlateGrid");

  const _component_LockingProximalHumeralPlateGrid = _resolveComponent("LockingProximalHumeralPlateGrid");

  const _component_LockingDistalMedialHumerusPlateGrid = _resolveComponent("LockingDistalMedialHumerusPlateGrid");

  const _component_LockingDistalTibiaPlate = _resolveComponent("LockingDistalTibiaPlate");

  const _component_ThreadStabilizedSmallTPlateGrid = _resolveComponent("ThreadStabilizedSmallTPlateGrid");

  const _component_ThreadStabilizedSmallTPlateObliqueGrid = _resolveComponent("ThreadStabilizedSmallTPlateObliqueGrid");

  const _component_LockingTPlateGrid = _resolveComponent("LockingTPlateGrid");

  const _component_LockingTButtressPlate = _resolveComponent("LockingTButtressPlate");

  const _component_LockingLButtressPlate = _resolveComponent("LockingLButtressPlate");

  const _component_LockingProximalTibiaPlateDefaultGrid = _resolveComponent("LockingProximalTibiaPlateDefaultGrid");

  const _component_LockingProximalTibiaPlateGrid = _resolveComponent("LockingProximalTibiaPlateGrid");

  const _component_LockingDistalMedialTibiaPlateGrid = _resolveComponent("LockingDistalMedialTibiaPlateGrid");

  const _component_MainFooter = _resolveComponent("MainFooter");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_MainHeader), _createVNode(_component_el_breadcrumb, {
    class: "breadcrumb",
    separator: "/"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_breadcrumb_item, {
      to: `/${_ctx.$i18n.locale}/catalog/`
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.catalog.block.title')), 1)]),
      _: 1
    }, 8, ["to"]), _createVNode(_component_el_breadcrumb_item, {
      to: `/${_ctx.$i18n.locale}/catalog/traumatology/`
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.carts.traumatology.title')), 1)]),
      _: 1
    }, 8, ["to"]), _createVNode(_component_el_breadcrumb_item, {
      to: `/${_ctx.$i18n.locale}/catalog/traumatology/blocked-plates/`
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.carts.traumatology.links.blockedPlates')), 1)]),
      _: 1
    }, 8, ["to"]), _createVNode(_component_el_breadcrumb_item, null, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.carts.traumatology.links.angularStabilityInserts')), 1)]),
      _: 1
    })]),
    _: 1
  }), _createElementVNode("div", _hoisted_3, [_createVNode(_component_LeftLinksMenu), _createElementVNode("div", _hoisted_4, [_createVNode(_component_ProductInfoCard, {
    image: $setup.cardData[0].image,
    title: $setup.cardData[0].title,
    subtitle: $setup.cardData[0].subtitle
  }, null, 8, ["image", "title", "subtitle"]), _createVNode(_component_SmallLockingSelfCompressingPlateGrid), _createVNode(_component_SmallLockingLowContactSelfCompressingPlateGrid), _createVNode(_component_LockingSmallTPlateGrid), _createVNode(_component_LockingSmallTPlateObliqueGrid), _createVNode(_component_LockingReconstructionPlateGrid), _createVNode(_component_LockingProximalHumeralPlateGrid), _createVNode(_component_LockingDistalMedialHumerusPlateGrid), _createVNode(_component_LockingDistalTibiaPlate), _createVNode(_component_ThreadStabilizedSmallTPlateGrid), _createVNode(_component_ThreadStabilizedSmallTPlateObliqueGrid), _createVNode(_component_LockingTPlateGrid), _createVNode(_component_LockingTButtressPlate), _createVNode(_component_LockingLButtressPlate), _createVNode(_component_LockingProximalTibiaPlateDefaultGrid), _createVNode(_component_LockingProximalTibiaPlateGrid), _createVNode(_component_LockingDistalMedialTibiaPlateGrid)])])])]), _createVNode(_component_MainFooter)], 64);
}