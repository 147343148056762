import { computed, ref } from 'vue';
import i18n from '@/i18n';
export default {
  name: 'curvedLaminarHook',

  setup() {
    const tableData = ref([{
      first: '5x7',
      second: 'Left',
      third: '36200-05007'
    }, {
      first: '5x9',
      second: 'Left',
      third: '36200-05009'
    }, {
      first: '5X7',
      second: 'Right',
      third: '36201-05007'
    }, {
      first: '5x9',
      second: 'Right',
      third: '36201-05009'
    }]);
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.spinalHooksLinks.curvedLaminarHook'),
      subtitle: "",
      image: require('@/assets/neurosurgery/curved-laminar-hook.png'),
      productsParams: []
    }]);
    return {
      cardData,
      tableData
    };
  }

};