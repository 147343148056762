import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "default_mobile__card"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductInfoCardMobile = _resolveComponent("ProductInfoCardMobile");

  const _component_CorticalScrew2and7 = _resolveComponent("CorticalScrew2and7");

  const _component_CorticalScrew3and5 = _resolveComponent("CorticalScrew3and5");

  const _component_CorticalScrew3and5Second = _resolveComponent("CorticalScrew3and5Second");

  const _component_CorticalScrew4and5 = _resolveComponent("CorticalScrew4and5");

  const _component_SpongyScrew4and0 = _resolveComponent("SpongyScrew4and0");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ProductInfoCardMobile, {
      image: $setup.cardData[0].image,
      title: $setup.cardData[0].title,
      subtitle: $setup.cardData[0].subtitle,
      productsParams: $setup.cardData[0].productsParams
    }, null, 8, ["image", "title", "subtitle", "productsParams"]), _createVNode(_component_CorticalScrew2and7), _createVNode(_component_CorticalScrew3and5), _createVNode(_component_CorticalScrew3and5Second), _createVNode(_component_CorticalScrew4and5), _createVNode(_component_SpongyScrew4and0)])]),
    _: 1
  });
}