import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalInstrument.links.loveGruenwald'),
      subtitle: "",
      image: require('@/assets/neurosurgery/love-gruenwald.png'),
      productsParams: []
    }]);
    return {
      cardData
    };
  }

};