import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "default_mobile__card"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductInfoCardMobile = _resolveComponent("ProductInfoCardMobile");

  const _component_SmallLockingSelfCompressingPlateGrid = _resolveComponent("SmallLockingSelfCompressingPlateGrid");

  const _component_SmallLockingLowContactSelfCompressingPlateGrid = _resolveComponent("SmallLockingLowContactSelfCompressingPlateGrid");

  const _component_LockingSmallTPlateGrid = _resolveComponent("LockingSmallTPlateGrid");

  const _component_LockingSmallTPlateObliqueGrid = _resolveComponent("LockingSmallTPlateObliqueGrid");

  const _component_LockingReconstructionPlateGrid = _resolveComponent("LockingReconstructionPlateGrid");

  const _component_LockingProximalHumeralPlateGrid = _resolveComponent("LockingProximalHumeralPlateGrid");

  const _component_LockingDistalMedialHumerusPlateGrid = _resolveComponent("LockingDistalMedialHumerusPlateGrid");

  const _component_LockingDistalTibiaPlate = _resolveComponent("LockingDistalTibiaPlate");

  const _component_ThreadStabilizedSmallTPlateGrid = _resolveComponent("ThreadStabilizedSmallTPlateGrid");

  const _component_ThreadStabilizedSmallTPlateObliqueGrid = _resolveComponent("ThreadStabilizedSmallTPlateObliqueGrid");

  const _component_LockingTPlateGrid = _resolveComponent("LockingTPlateGrid");

  const _component_LockingTButtressPlate = _resolveComponent("LockingTButtressPlate");

  const _component_LockingLButtressPlate = _resolveComponent("LockingLButtressPlate");

  const _component_LockingProximalTibiaPlateDefaultGrid = _resolveComponent("LockingProximalTibiaPlateDefaultGrid");

  const _component_LockingProximalTibiaPlateGrid = _resolveComponent("LockingProximalTibiaPlateGrid");

  const _component_LockingDistalMedialTibiaPlateGrid = _resolveComponent("LockingDistalMedialTibiaPlateGrid");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ProductInfoCardMobile, {
      image: $setup.cardData[0].image,
      title: $setup.cardData[0].title,
      subtitle: $setup.cardData[0].subtitle,
      productsParams: $setup.cardData[0].productsParams
    }, null, 8, ["image", "title", "subtitle", "productsParams"]), _createVNode(_component_SmallLockingSelfCompressingPlateGrid), _createVNode(_component_SmallLockingLowContactSelfCompressingPlateGrid), _createVNode(_component_LockingSmallTPlateGrid), _createVNode(_component_LockingSmallTPlateObliqueGrid), _createVNode(_component_LockingReconstructionPlateGrid), _createVNode(_component_LockingProximalHumeralPlateGrid), _createVNode(_component_LockingDistalMedialHumerusPlateGrid), _createVNode(_component_LockingDistalTibiaPlate), _createVNode(_component_ThreadStabilizedSmallTPlateGrid), _createVNode(_component_ThreadStabilizedSmallTPlateObliqueGrid), _createVNode(_component_LockingTPlateGrid), _createVNode(_component_LockingTButtressPlate), _createVNode(_component_LockingLButtressPlate), _createVNode(_component_LockingProximalTibiaPlateDefaultGrid), _createVNode(_component_LockingProximalTibiaPlateGrid), _createVNode(_component_LockingDistalMedialTibiaPlateGrid)])]),
    _: 1
  });
}