import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-65638638"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "tools-equipment__carts"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "tools-equipment__cart-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ToolsAndEquipmentLayuot = _resolveComponent("ToolsAndEquipmentLayuot");

  return _openBlock(), _createBlock(_component_ToolsAndEquipmentLayuot, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.toolsAndEquipmentCarts, cart => {
      return _openBlock(), _createElementBlock("a", {
        key: cart,
        class: "tools-equipment__cart",
        href: cart.link
      }, [_createElementVNode("img", {
        src: cart.img,
        alt: "",
        class: "tools-equipment__cart-img"
      }, null, 8, _hoisted_3), _createElementVNode("div", _hoisted_4, _toDisplayString(cart.title), 1)], 8, _hoisted_2);
    }), 128))])]),
    _: 1
  });
}