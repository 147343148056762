import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const gynecologicalChairKG1MEData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.gynecologicalChairKG1ME'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairKG1ME.subtitle'),
      image: require('@/assets/toolsAndEquipment/medicalFurniture/gynecological-chair-KG1ME.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairKG1ME.dataLeft1'),
        data: "0-90"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairKG1ME.dataLeft2'),
        data: "5"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairKG1ME.dataLeft3'),
        data: "200"
      }]
    }]);
    return {
      gynecologicalChairKG1MEData
    };
  }

};