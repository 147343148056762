import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  name: 'MainScreen',
  props: {
    msg: String
  },

  setup() {
    const traumatologyLinks = computed(() => [{
      id: 1,
      title: i18n.global.t('main.carts.traumatology.links.intramedullaryPins'),
      path: `/${i18n.global.locale.value}/catalog/traumatology/intramedullary-pins`
    }, {
      id: 2,
      title: i18n.global.t('main.carts.traumatology.links.blockedPlates'),
      path: `/${i18n.global.locale.value}/catalog/traumatology/blocked-plates`
    }, {
      id: 3,
      title: i18n.global.t('main.carts.traumatology.links.tool'),
      path: `/${i18n.global.locale.value}/catalog/traumatology/tools`
    }, {
      id: 4,
      title: i18n.global.t('main.carts.traumatology.links.screwsAT'),
      path: `/${i18n.global.locale.value}/catalog/traumatology/ao-screws`
    }, {
      id: 5,
      title: i18n.global.t('main.carts.traumatology.links.JSCPlates'),
      path: `/${i18n.global.locale.value}/catalog/traumatology/ao-plates`
    }, {
      id: 6,
      title: i18n.global.t('main.carts.traumatology.links.needlesRodsCerclage'),
      path: `/${i18n.global.locale.value}/catalog/traumatology/needles-robs-cerclage`
    }, {
      id: 7,
      title: i18n.global.t('main.carts.traumatology.links.microsurgery'),
      path: `/${i18n.global.locale.value}/catalog/traumatology/micro-surgery`
    }, {
      id: 8,
      title: i18n.global.t('main.carts.traumatology.links.ilizarovsApparatus'),
      path: `/${i18n.global.locale.value}/catalog/traumatology/ilizarovs-apparatus`
    }]);
    const surgeryLinks = computed(() => [{
      id: 1,
      title: i18n.global.t('main.carts.surgery.links.sutureMaterials'),
      path: `/${i18n.global.locale.value}/catalog/surgery/suture-materials`
    }, {
      id: 2,
      title: i18n.global.t('main.carts.surgery.links.endoprosthesesPolymerNets'),
      path: `/${i18n.global.locale.value}/catalog/surgery/endoprostheses-polymer-meshes`
    }, {
      id: 3,
      title: i18n.global.t('main.carts.surgery.links.endoprosthesesForUrogynecology'),
      path: `/${i18n.global.locale.value}/catalog/surgery/endoprostheses-for-urogynecology`
    }, {
      id: 4,
      title: i18n.global.t('main.carts.surgery.links.antiAdhesiveAgents'),
      path: `/${i18n.global.locale.value}/catalog/surgery/antiadhesions`
    }, {
      id: 5,
      title: i18n.global.t('main.carts.surgery.links.woundCovers'),
      path: `/${i18n.global.locale.value}/catalog/surgery/wound-dressings`
    }, {
      id: 6,
      title: i18n.global.t('main.carts.surgery.links.hemostaticSponge'),
      path: `/${i18n.global.locale.value}/catalog/surgery/hemostatic-sponge`
    }, {
      id: 7,
      title: i18n.global.t('main.carts.surgery.links.productsPfZMCompany'),
      path: `/${i18n.global.locale.value}/catalog/surgery/zm-production`
    }]);
    const neurosurgeryLinks = computed(() => [i18n.global.t('main.carts.neurosurgery.links.transpedicularFixationOfTheSpine'), i18n.global.t('main.carts.neurosurgery.links.neurosurgicalPlates'), i18n.global.t('main.carts.neurosurgery.links.vertebralEndoprostheses'), i18n.global.t('main.carts.neurosurgery.links.neurosurgicalInstrument'), i18n.global.t('main.carts.neurosurgery.links.antiAdhesionGel')]);
    const toolsAndEquipmentLinks = computed(() => [i18n.global.t('main.carts.toolsAndEquipment.links.equipmentForOperatingRooms'), i18n.global.t('main.carts.toolsAndEquipment.links.medicalFurniture'), i18n.global.t('main.carts.toolsAndEquipment.links.sterilization'), i18n.global.t('main.carts.toolsAndEquipment.links.cardiology'), i18n.global.t('main.carts.toolsAndEquipment.links.medicalInstrument')]);
    return {
      traumatologyLinks,
      surgeryLinks,
      neurosurgeryLinks,
      toolsAndEquipmentLinks
    };
  }

};