import { ref } from 'vue';
import ChooseLangButton from '@/components/auxiliaryComponents/ChooseLangButton.vue';
import i18n from '@/i18n';
import router from '@/routes';
export default {
  components: {
    ChooseLangButton
  },
  props: {
    msg: {
      type: String,
      default: ''
    }
  },

  setup() {
    function toggleMenu() {
      isActive.value = !isActive.value;
      document.body.classList.toggle('active'); //removing scroll when we have burger menu active
    }

    function setLocale(locale) {
      localStorage.setItem('lang', locale);
      i18n.global.locale.value = locale;
      router.push({
        params: {
          lang: locale
        }
      });
    }

    const isActive = ref(false);
    return {
      isActive,
      toggleMenu,
      setLocale
    };
  }

};