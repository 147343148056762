import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-e8c034e8"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  class: "cart__body"
};
const _hoisted_3 = {
  class: "cart__title"
};
const _hoisted_4 = {
  class: "cart__text"
};
const _hoisted_5 = {
  class: "cart__more-block"
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  key: 1
};
const _hoisted_8 = ["src"];
const _hoisted_9 = {
  class: "cart__body"
};
const _hoisted_10 = {
  class: "cart__title"
};
const _hoisted_11 = {
  class: "cart__text"
};
const _hoisted_12 = {
  class: "cart__more-block"
};
const _hoisted_13 = {
  key: 2
};
const _hoisted_14 = {
  class: "cart__body"
};
const _hoisted_15 = {
  class: "cart__title"
};
const _hoisted_16 = {
  class: "cart__text"
};
const _hoisted_17 = ["src"];
const _hoisted_18 = {
  key: 3
};
const _hoisted_19 = ["src"];
const _hoisted_20 = {
  class: "cart__body"
};
const _hoisted_21 = {
  class: "cart__title"
};
const _hoisted_22 = {
  class: "cart__text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass($props.imgPosition === 'mobile' || $props.imgPosition === 'mobile-top' ? 'cart-mobile' : 'cart')
  }, [$props.imgPosition === 'bottom' ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h2", _hoisted_3, _toDisplayString($props.title), 1), _createElementVNode("p", _hoisted_4, _toDisplayString($props.text), 1)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('main.carts.more')), 1)]), _createElementVNode("img", {
    class: "cart__img",
    src: $props.img,
    alt: ""
  }, null, 8, _hoisted_6)])) : _createCommentVNode("", true), $props.imgPosition === 'top' ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("img", {
    class: "cart__img",
    src: $props.img,
    alt: ""
  }, null, 8, _hoisted_8), _createElementVNode("div", _hoisted_9, [_createElementVNode("h2", _hoisted_10, _toDisplayString($props.title), 1), _createElementVNode("p", _hoisted_11, _toDisplayString($props.text), 1)]), _createElementVNode("div", _hoisted_12, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('main.carts.more')), 1)])])) : _createCommentVNode("", true), $props.imgPosition === 'mobile' ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createElementVNode("h2", _hoisted_15, _toDisplayString($props.title), 1), _createElementVNode("p", _hoisted_16, _toDisplayString($props.text), 1), _renderSlot(_ctx.$slots, "list", {}, undefined, true)]), _createElementVNode("img", {
    class: "cart__img-mobile",
    src: $props.img,
    alt: ""
  }, null, 8, _hoisted_17)])) : _createCommentVNode("", true), $props.imgPosition === 'mobile-top' ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createElementVNode("img", {
    class: "cart__img-mobile-top",
    src: $props.img,
    alt: ""
  }, null, 8, _hoisted_19), _createElementVNode("div", _hoisted_20, [_createElementVNode("h2", _hoisted_21, _toDisplayString($props.title), 1), _createElementVNode("p", _hoisted_22, _toDisplayString($props.text), 1), _renderSlot(_ctx.$slots, "list", {}, undefined, true)])])) : _createCommentVNode("", true)], 2);
}