import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const spinalScrewsCarts = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.spinalScrewsLinks.polyaxialScrew'),
      img: require('@/assets/neurosurgery/polyaxial-screw.png'),
      link: `/${i18n.global.locale.value}/catalog/neurosurgery/spinal-screws/poliaksialnyi-vint`
    }, {
      title: i18n.global.t('main.carts.neurosurgery.spinalScrewsLinks.monoaxialScrew'),
      img: require('@/assets/neurosurgery/monoaxial-screw.png'),
      link: `/${i18n.global.locale.value}/catalog/neurosurgery/spinal-screws/monoaksialnyi-vint`
    }]);
    return {
      spinalScrewsCarts
    };
  }

};