export default {
  name: 'ChooseLangButton',
  props: {
    language: {
      type: String,
      default: ''
    },
    flag: {
      type: String,
      default: ''
    }
  },

  setup() {
    return {};
  }

};