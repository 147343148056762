import { computed, ref } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const tableData = ref([{
      first: '2',
      second: '26mm',
      third: '36000-02602'
    }, {
      first: '3',
      second: '34mm',
      third: '36000-03403'
    }, {
      first: '4',
      second: '42mm',
      third: '36000-04204'
    }, {
      first: '5',
      second: '50mm',
      third: '36000-05005'
    }, {
      first: '6',
      second: '58mm',
      third: '36000-05806'
    }, {
      first: '7',
      second: '66mm',
      third: '36000-06607'
    }, {
      first: '8',
      second: '74mm',
      third: '36000-07408'
    }, {
      first: '9',
      second: '82mm',
      third: '36000-08209'
    }, {
      first: '10',
      second: '90mm',
      third: '36000-09010'
    }]);
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.links.trapezoidalPlate'),
      subtitle: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.trapezoidalPlate.subtitle'),
      image: require('@/assets/neurosurgery/trapezoidal-plate.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.trapezoidalPlate.dataLeft1'),
        data: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.trapezoidalPlate.dataRight1')
      }, {
        id: 2,
        name: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.trapezoidalPlate.dataLeft2'),
        data: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.trapezoidalPlate.dataRight2')
      }, {
        id: 3,
        name: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.trapezoidalPlate.dataLeft3'),
        data: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.trapezoidalPlate.dataRight3')
      }]
    }]);
    return {
      cardData,
      tableData
    };
  }

};