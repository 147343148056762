import { computed } from 'vue';
export default {
  setup() {
    const cardData = computed(() => [{
      title: 'капрогент',
      image: require('@/assets/surgery/caprogent.jpeg')
    }]);
    return {
      cardData
    };
  }

};