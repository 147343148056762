import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.examinationCouch.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.examinationCouch.title1')
    }]);
    const examinationCouchData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.examinationCouch'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/examination-couch.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.examinationCouch.dataLeft1'),
        data: "120"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.examinationCouch.dataLeft2'),
        data: "450 ±10"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.examinationCouch.dataLeft3'),
        data: "0 - 45"
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.examinationCouch.dataLeft4'),
        data: "1960 х 610 х 45"
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.examinationCouch.dataLeft5'),
        data: "1960 х 610 х 450"
      }, {
        id: 6,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.examinationCouch.dataLeft6'),
        data: "30"
      }]
    }]);
    return {
      examinationCouchData,
      textData
    };
  }

};