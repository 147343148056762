import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const formalinChamberSmallData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.formalinChambersLinks.formalinChamberSmall'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.formalinChamberSmall.title'),
      image: require('@/assets/toolsAndEquipment/formalin-chamber-small.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.formalinChamberSmall.formalinChamberSmallData1'),
        data: '286'
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.formalinChamberSmall.formalinChamberSmallData2'),
        data: '193'
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.formalinChamberSmall.formalinChamberSmallData3'),
        data: '191'
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.formalinChamberSmall.formalinChamberSmallData4'),
        data: '1,75'
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.formalinChamberSmall.formalinChamberSmallData5'),
        data: i18n.global.t('main.carts.toolsAndEquipment.formalinChamberSmall.formalinChamberSmallData6')
      }]
    }]);
    return {
      formalinChamberSmallData
    };
  }

};