import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const ElectricSterilizersCarts = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.electricSterilizersLinks.electricSterilizers420'),
      img: require('@/assets/toolsAndEquipment/electric-sterilizers-420.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/electric-sterilizer/sterilizator-elektricheskii-420-e-kipyatilnik`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.electricSterilizersLinks.electricSterilizers320'),
      img: require('@/assets/toolsAndEquipment/electric-sterilizers-320.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/electric-sterilizer/sterilizator-elektricheskii-320-e-kipyatilnik`
    }]);
    return {
      ElectricSterilizersCarts
    };
  }

};