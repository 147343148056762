import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.diagnosticCouchCD1.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.diagnosticCouchCD1.title1')
    }]);
    const diagnosticCouchCD1Data = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.diagnosticCouchCD1'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/diagnostic-couch-CD1.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.diagnosticCouchCD1.dataLeft1'),
        data: "120"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.diagnosticCouchCD1.dataLeft2'),
        data: "820"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.diagnosticCouchCD1.dataLeft3'),
        data: "0 - 45"
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.diagnosticCouchCD1.dataLeft4'),
        data: "1960 х 610 х 45"
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.diagnosticCouchCD1.dataLeft5'),
        data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.diagnosticCouchCD1.dataRight5')
      }, {
        id: 6,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.diagnosticCouchCD1.dataLeft6'),
        data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.diagnosticCouchCD1.dataRight6')
      }, {
        id: 7,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.diagnosticCouchCD1.dataLeft7'),
        data: "45"
      }]
    }]);
    return {
      diagnosticCouchCD1Data,
      textData
    };
  }

};