import { computed } from 'vue';
export default {
  name: 'AnchorScrew4and5',

  setup() {
    const tableData = computed(() => [{
      first: '8',
      second: '32230-45008'
    }, {
      first: '10',
      second: '32230-45010'
    }, {
      first: '12',
      second: '32230-45012'
    }, {
      first: '14',
      second: '32230-45014'
    }, {
      first: '16',
      second: '32230-45015'
    }, {
      first: '18',
      second: '32230-45016'
    }, {
      first: '20',
      second: '32110-35020'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/anchor-screw-4-and-5.jpeg'),
      productsParams: [{
        id: 5,
        name: 'Матеріал',
        data: 'сплав титану'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};