import { computed } from 'vue';
export default {
  name: 'StaimansRobsWithNotch',

  setup() {
    const tableData = computed(() => [{
      first: '100',
      second: '30',
      third: '5.020.100'
    }, {
      first: '110',
      second: '35',
      third: '5.020.110'
    }, {
      first: '120',
      second: '40',
      third: '5.020.120'
    }, {
      first: '140',
      second: '45',
      third: '5.020.140'
    }, {
      first: '160',
      second: '50',
      third: '5.020.160'
    }, {
      first: '180',
      second: '55',
      third: '5.020.180'
    }, {
      first: '200',
      second: '65',
      third: '5.020.200'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/stainman-robs-with-notch.jpeg'),
      productsParams: [{
        id: 1,
        name: 'Матеріал',
        data: 'нерж. сталь'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};