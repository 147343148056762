import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.medicalCabinetSHM2.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.medicalCabinetSHM2.title1')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.medicalCabinetSHM2.text2'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.medicalCabinetSHM2.title2')
    }]);
    const medicalCabinetSHM2Data = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.medicalCabinetSHM2'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/medical-cabinet-SHM2.png'),
      productsParams: []
    }]);
    return {
      medicalCabinetSHM2Data,
      textData
    };
  }

};