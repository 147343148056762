import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const stretcherMedicalB01Data = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.stretcherMedicalB01'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB01.subtitle'),
      image: require('@/assets/toolsAndEquipment/medicalFurniture/stretcher-medical-B01.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB01.dataLeft1'),
        data: "192х55х42"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB01.dataLeft2'),
        data: "132"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB01.dataLeft3'),
        data: "65"
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB01.dataLeft4'),
        data: "25"
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB01.dataLeft5'),
        data: "31	"
      }, {
        id: 6,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB01.dataLeft6'),
        data: "160"
      }]
    }]);
    return {
      stretcherMedicalB01Data
    };
  }

};