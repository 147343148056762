import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "intramedullary-pins_mobile__inner"
};
const _hoisted_2 = {
  class: "intramedullary-pins_mobile__title"
};
const _hoisted_3 = {
  class: "intramedullary-pins_mobile__inner-links"
};
const _hoisted_4 = {
  class: "intramedullary-pins_mobile__carts"
};
const _hoisted_5 = ["href"];
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "intramedullary-pins_mobile__cart-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_swiper_slide = _resolveComponent("swiper-slide");

  const _component_Swiper = _resolveComponent("Swiper");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('main.carts.traumatology.title')), 1), _createElementVNode("div", _hoisted_3, [_createVNode(_component_Swiper, {
      "slides-per-view": 1,
      "space-between": 50,
      pagination: true,
      modules: $setup.modules
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.traumatologyCarts, cart => {
        return _openBlock(), _createBlock(_component_swiper_slide, {
          key: cart
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createElementVNode("a", {
            class: "intramedullary-pins_mobile__cart",
            href: cart.link
          }, [_createElementVNode("img", {
            src: cart.img,
            alt: "",
            class: "intramedullary-pins_mobile__cart-img"
          }, null, 8, _hoisted_6), _createElementVNode("div", _hoisted_7, _toDisplayString(cart.title), 1)], 8, _hoisted_5)])]),
          _: 2
        }, 1024);
      }), 128))]),
      _: 1
    }, 8, ["modules"])])])]),
    _: 1
  });
}