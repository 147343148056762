import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.panmedCamersLinks.uvCameraSmall'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.panmedCamers.uvCameraSmallData'),
      image: require('@/assets/toolsAndEquipment/uv-camera-small.png'),
      productsParams: []
    }]);
    return {
      cardData
    };
  }

};