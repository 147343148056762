import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const FormalinChambersCarts = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.formalinChambersLinks.formalinChamberBig'),
      img: require('@/assets/toolsAndEquipment/formalin-chamber-big.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/camera-paroformalinovaya/kamera-paroformalinovaya-kkhi-dlya-sterilizatsii-medizdelii-i-instrumenta-bolshaya`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.formalinChambersLinks.formalinChamberSmall'),
      img: require('@/assets/toolsAndEquipment/formalin-chamber-small.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/camera-paroformalinovaya/kamera-paroformalinovaya-kkhi-dlya-sterilizatsii-medizdelii-i-instrumenta-malaya`
    }]);
    return {
      FormalinChambersCarts
    };
  }

};