import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.traumatology.links.screwsInsertsWithAngularStability'),
      image: require('@/assets/traumatology/screws-for-plates-with-angular-stability.jpeg')
    }]);
    return {
      cardData
    };
  }

};