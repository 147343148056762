import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const AirSterilizerCarts = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerLinks.airSterilizerNotPassable'),
      img: require('@/assets/toolsAndEquipment/air-sterilizer-not-passable.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/air-sterilizer/sterilizatory-vozdushnye-gp-320-bd-neprokhodnoi-gpd-320-prokhodnoi-usovershenstvovannye-analogi-shss-250p-i-shss-250pr`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerLinks.airSterilizerUkraine'),
      img: require('@/assets/toolsAndEquipment/air-sterilizer-ukraine.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/air-sterilizer/sterilizatory-vozdushnye-gp-20-gp-40-gp-80-ukraina`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerLinks.airSterilizerGP'),
      img: require('@/assets/toolsAndEquipment/air-sterilizer-GP.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/air-sterilizer/sterilizatory-vozdushnye-gp-160bd-gp-640-bd-gp-1300-bd`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerLinks.airSterilizerSpu'),
      img: require('@/assets/toolsAndEquipment/air-sterilizer-spu.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/air-sterilizer/sterilizatory-vozdushnye-gp-20-spu-gp-40-spu-gp-80-spu`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerLinks.sterilizationCabinet'),
      img: require('@/assets/toolsAndEquipment/sterilization-cabinet.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/air-sterilizer/shkaf-sushilno-sterilizatsionnyi-shs-80`
    }]);
    return {
      AirSterilizerCarts
    };
  }

};