import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_button, {
    class: "button-links-mobile",
    type: "primary",
    autofocus: $setup.currentPagePath === `/${_ctx.$i18n.locale}/catalog/traumatology`,
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.push(`/${_ctx.$i18n.locale}/catalog/traumatology`))
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.certificates.links.traumatology')), 1)]),
    _: 1
  }, 8, ["autofocus"]), _createVNode(_component_el_button, {
    class: "button-links-mobile",
    type: "primary",
    autofocus: $setup.currentPagePath === `/${_ctx.$i18n.locale}/catalog/surgery`,
    onClick: _cache[1] || (_cache[1] = $event => _ctx.$router.push(`/${_ctx.$i18n.locale}/catalog/surgery`))
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.catalog.links.surgery')), 1)]),
    _: 1
  }, 8, ["autofocus"]), _createVNode(_component_el_button, {
    class: "button-links-mobile",
    type: "primary",
    autofocus: $setup.currentPagePath === `/${_ctx.$i18n.locale}/catalog/neurosurgery`,
    onClick: _cache[2] || (_cache[2] = $event => _ctx.$router.push(`/${_ctx.$i18n.locale}/catalog/neurosurgery`))
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.catalog.links.neurosurgery')), 1)]),
    _: 1
  }, 8, ["autofocus"]), _createVNode(_component_el_button, {
    class: "button-links-mobile",
    type: "primary",
    autofocus: $setup.currentPagePath === `/${_ctx.$i18n.locale}/catalog/tools-and-equipment`,
    onClick: _cache[3] || (_cache[3] = $event => _ctx.$router.push(`/${_ctx.$i18n.locale}/catalog/tools-and-equipment`))
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.catalog.links.toolsAndEquipment')), 1)]),
    _: 1
  }, 8, ["autofocus"])], 64);
}