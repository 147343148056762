import { computed, ref } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const textData = computed(() => [{
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text1')
    }, {
      id: 3,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text2')
    }, {
      id: 4,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text3')
    }, {
      id: 5,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text4')
    }, {
      id: 6,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text5')
    }, {
      id: 7,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text6')
    }, {
      id: 8,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text7')
    }, {
      id: 9,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text8')
    }, {
      id: 10,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text9')
    }, {
      id: 11,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text10')
    }, {
      id: 12,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text11')
    }, {
      id: 13,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text12')
    }, {
      id: 14,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text13')
    }, {
      id: 15,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text14')
    }, {
      id: 16,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text15')
    }, {
      id: 17,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text16')
    }, {
      id: 18,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text17')
    }, {
      id: 19,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text18')
    }, {
      id: 20,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text19')
    }, {
      id: 21,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text20')
    }, {
      id: 22,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text21')
    }, {
      id: 23,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text22')
    }, {
      id: 24,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text23')
    }, {
      id: 25,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text24')
    }, {
      id: 26,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.monitorResuscitation.text25')
    }]);
    const tableData = ref([{
      first: '1',
      second: '+',
      third: '',
      fourth: '',
      fifth: '',
      sixth: '',
      seventh: '+'
    }, {
      first: '2',
      second: '+',
      third: '+',
      fourth: '',
      fifth: '',
      sixth: '',
      seventh: '+'
    }, {
      first: '3',
      second: '+',
      third: '+',
      fourth: '',
      fifth: '+',
      sixth: '',
      seventh: '+'
    }, {
      first: '4',
      second: '+',
      third: '',
      fourth: '+',
      fifth: '',
      sixth: '',
      seventh: '+'
    }, {
      first: '5',
      second: '+',
      third: '+',
      fourth: '+',
      fifth: '',
      sixth: '',
      seventh: '+'
    }, {
      first: '6',
      second: '+',
      third: '+',
      fourth: '+',
      fifth: '+',
      sixth: '',
      seventh: '+'
    }, {
      first: '15',
      second: '+',
      third: '+',
      fourth: '+',
      fifth: '',
      sixth: '+',
      seventh: '+'
    }, {
      first: '16',
      second: '+',
      third: '+',
      fourth: '+',
      fifth: '+',
      sixth: '+',
      seventh: '+'
    }]);
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.cardiology.links.monitorResuscitation'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/cardiology/monitor-resuscitation.png'),
      productsParams: []
    }]);
    return {
      cardData,
      textData,
      tableData
    };
  }

};