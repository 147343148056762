import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.sterilizationBoxes.sterilizationBoxesRound.text1')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.sterilizationBoxes.sterilizationBoxesRound.text2')
    }, {
      id: 3,
      data: i18n.global.t('main.carts.toolsAndEquipment.sterilizationBoxes.sterilizationBoxesRound.text3')
    }, {
      id: 4,
      data: i18n.global.t('main.carts.toolsAndEquipment.sterilizationBoxes.sterilizationBoxesRound.text3')
    }]);
    const sterilizationBoxesRoundData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.sterilizationBoxesLinks.sterilizationBoxesRound'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/sterilization-boxes-round.png'),
      productsParams: []
    }]);
    return {
      sterilizationBoxesRoundData,
      textData
    };
  }

};