import { computed } from 'vue';
export default {
  setup() {
    const cardData = computed(() => [{
      title: 'АЙОБАН Антимікробна плівка, що розрізається',
      image: require('@/assets/surgery/ayoban.jpeg')
    }]);
    return {
      cardData
    };
  }

};