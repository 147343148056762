import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.text1')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.text2')
    }, {
      id: 3,
      data: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.text3')
    }, {
      id: 4,
      data: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.text4')
    }, {
      id: 5,
      data: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.text5')
    }, {
      id: 6,
      data: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.text6')
    }, {
      id: 7,
      data: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.text7')
    }, {
      id: 8,
      data: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.text8')
    }, {
      id: 9,
      data: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.text9')
    }, {
      id: 10,
      data: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.text10')
    }, {
      id: 11,
      data: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.text11')
    }, {
      id: 12,
      data: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.text12')
    }, {
      id: 13,
      data: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.text13')
    }, {
      id: 14,
      data: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.text14')
    }, {
      id: 15,
      data: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.text15')
    }]);
    const AirSterilizerBdData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerLinks.airSterilizerGP'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/air-sterilizer-spu.png')
    }]);
    return {
      AirSterilizerBdData,
      textData
    };
  }

};