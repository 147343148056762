import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-fe132a06"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "card"
};
const _hoisted_2 = {
  class: "card__header"
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "card__header-inner"
};
const _hoisted_5 = {
  key: 0,
  class: "blue-txt"
};
const _hoisted_6 = {
  key: 1,
  class: "card__header-subtitle"
};
const _hoisted_7 = {
  key: 0,
  class: "card__product-info"
};
const _hoisted_8 = {
  class: "card__product-name"
};
const _hoisted_9 = {
  class: "card__product-data"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [$props.image ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    class: "card__header-img",
    src: $props.image,
    alt: "product"
  }, null, 8, _hoisted_3)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_4, [$props.title ? (_openBlock(), _createElementBlock("h2", _hoisted_5, _toDisplayString($props.title), 1)) : _createCommentVNode("", true), $props.subtitle ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString($props.subtitle), 1)) : _createCommentVNode("", true)])]), $props.productsParams.length ? (_openBlock(), _createElementBlock("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.productsParams, item => {
    return _openBlock(), _createElementBlock("div", {
      class: "card__product-info-inner",
      key: item.id
    }, [_createElementVNode("div", _hoisted_8, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_9, _toDisplayString(item.data), 1)]);
  }), 128))])) : _createCommentVNode("", true)]);
}