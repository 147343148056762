import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.adjustableHeadrestCouch.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.adjustableHeadrestCouch.title1')
    }]);
    const adjustableHeadrestCouchData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.adjustableHeadrestCouch'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/adjustable-headrest-couch.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.adjustableHeadrestCouch.dataLeft1'),
        data: "580"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.adjustableHeadrestCouch.dataLeft2'),
        data: "530"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.adjustableHeadrestCouch.dataLeft3'),
        data: "1900"
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.adjustableHeadrestCouch.dataLeft4'),
        data: "30"
      }]
    }]);
    return {
      adjustableHeadrestCouchData,
      textData
    };
  }

};