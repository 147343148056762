import { computed } from 'vue';
export default {
  name: 'SpokeShoulder',

  setup() {
    const tableData = computed(() => [{
      first: '15030-40110',
      second: '15030-40130',
      third: '15030-40150',
      fourth: '15030-40170',
      fifth: '15030-40190'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/shoulder-spoke.jpeg'),
      productsParams: [{
        id: 1,
        name: 'Матеріал',
        data: 'нерж. сталь'
      }, {
        id: 2,
        name: 'Діаметр різьблення',
        data: '4 мм спонгіозний'
      }, {
        id: 3,
        name: 'Діаметр стержня',
        data: '1,75 мм '
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};