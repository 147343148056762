import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-76906da6"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "tools-equipment-layuot"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "tools-equipment-layuot__inner"
};
const _hoisted_4 = {
  class: "tools-equipment-layuot__block"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MainHeader = _resolveComponent("MainHeader");

  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");

  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");

  const _component_LeftLinksMenu = _resolveComponent("LeftLinksMenu");

  const _component_MainFooter = _resolveComponent("MainFooter");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_MainHeader), _createVNode(_component_el_breadcrumb, {
    class: "breadcrumb",
    separator: "/"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_breadcrumb_item, {
      to: `/${_ctx.$i18n.locale}/catalog/`
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('header.catalog')), 1)]),
      _: 1
    }, 8, ["to"]), _createVNode(_component_el_breadcrumb_item, null, {
      default: _withCtx(() => [_createElementVNode("a", null, _toDisplayString(_ctx.$t('main.carts.toolsAndEquipment.title')), 1)]),
      _: 1
    })]),
    _: 1
  }), _createElementVNode("div", _hoisted_3, [_createVNode(_component_LeftLinksMenu, {
    typeLink: 'toolsAndEquipment',
    linksData: $setup.toolsAndEquipmentLinks
  }, null, 8, ["linksData"]), _createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])])])]), _createVNode(_component_MainFooter)], 64);
}