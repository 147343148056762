import { computed } from 'vue';
import i18n from '@/i18n';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
export default {
  components: {
    Swiper,
    SwiperSlide
  },

  setup() {
    const EndoprosthesesForUrogynecologyCard = computed(() => [{
      title: 'урослінг',
      img: require('@/assets/surgery/urosling.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/endoprostheses-for-urogynecology/urosling`
    }, {
      title: 'урофікс пл',
      img: require('@/assets/surgery/uroflix-pl.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/endoprostheses-for-urogynecology/urofix-pl`
    }, {
      title: 'урофікс то',
      img: require('@/assets/surgery/uroflix-to.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/endoprostheses-for-urogynecology/urofix-to`
    }]);
    return {
      EndoprosthesesForUrogynecologyCard,
      modules: [Pagination]
    };
  }

};