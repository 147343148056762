import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      title: i18n.global.t('main.carts.toolsAndEquipment.cardiology.electrocardiogrphRDArmasoft.title1'),
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.electrocardiogrphRDArmasoft.text1')
    }, {
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.electrocardiogrphRDArmasoft.text2')
    }, {
      id: 2,
      title: i18n.global.t('main.carts.toolsAndEquipment.cardiology.electrocardiogrphRDArmasoft.title2'),
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.electrocardiogrphRDArmasoft.text3')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.electrocardiogrphRDArmasoft.text4')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.electrocardiogrphRDArmasoft.text5')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.electrocardiogrphRDArmasoft.text6')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.electrocardiogrphRDArmasoft.text7')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.electrocardiogrphRDArmasoft.text8')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.cardiology.electrocardiogrphRDArmasoft.text9')
    }]);
    const data = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.cardiology.links.electrocardiogrphRDArmasoft'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/cardiology/electrocardiogrph-rd-armasoft.png'),
      productsParams: []
    }]);
    return {
      data,
      textData
    };
  }

};