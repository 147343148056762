import { ref } from 'vue';
import i18n from '@/i18n';
import router from '../../routes';
export default {
  name: 'LangSelect',
  props: {
    options: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Object,
      default: () => {
        'Язык';
      }
    }
  },
  emits: ['select'],

  setup(_, context) {
    const areOptionsVisible = ref(false);

    function setLocale(locale) {
      localStorage.setItem('lang', locale);
      i18n.global.locale.value = locale;
      router.push({
        params: {
          lang: locale
        }
      });
    }

    function selectOption(option) {
      context.emit('select', option);
      setLocale(option.value);
      areOptionsVisible.value = false;
    }

    return {
      areOptionsVisible,
      selectOption,
      setLocale
    };
  }

};