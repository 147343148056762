import { computed, ref } from 'vue';
import i18n from '@/i18n';
export default {
  name: 'curvedBeam',

  setup() {
    const tableData = ref([{
      first: '30',
      second: '36140-06030'
    }, {
      first: '35',
      second: '36140-06035'
    }, {
      first: '40',
      second: '36140-06040'
    }, {
      first: '45',
      second: '36140-06045'
    }, {
      first: '50',
      second: '36140-06050'
    }, {
      first: '55',
      second: '36140-06055'
    }, {
      first: '60',
      second: '36140-06060'
    }, {
      first: '80',
      second: '36140-06080'
    }, {
      first: '90',
      second: '36140-06090'
    }, {
      first: '100',
      second: '36140-06100'
    }]);
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.spinalBeamsLinks.curvedBeam'),
      subtitle: "",
      image: require('@/assets/neurosurgery/curved-beam.png'),
      productsParams: []
    }]);
    return {
      cardData,
      tableData
    };
  }

};