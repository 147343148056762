import { computed } from 'vue';
import CerclageWireWithHead from './grid/CerclageWireWithHead.vue';
import CerclageWire from './grid/CerclageWire.vue';
import CerclageBracket from './grid/CerclageBracket.vue';
export default {
  name: 'CerclageScreen',

  setup() {
    const cardData = computed(() => [{
      title: 'Серкляж',
      image: require('@/assets/traumatology/cerclage.jpeg')
    }]);
    return {
      cardData
    };
  },

  components: {
    CerclageWireWithHead,
    CerclageWire,
    CerclageBracket
  }
};