import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "default_mobile__card"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductInfoCardMobile = _resolveComponent("ProductInfoCardMobile");

  const _component_ChancetanScrew = _resolveComponent("ChancetanScrew");

  const _component_TscScreen = _resolveComponent("TscScreen");

  const _component_StaimansRobs = _resolveComponent("StaimansRobs");

  const _component_StaimansRobsWithNotch = _resolveComponent("StaimansRobsWithNotch");

  const _component_ShancRobs = _resolveComponent("ShancRobs");

  const _component_ShancRobsSecond = _resolveComponent("ShancRobsSecond");

  const _component_ShancRobsThird = _resolveComponent("ShancRobsThird");

  const _component_ScrewRoster = _resolveComponent("ScrewRoster");

  const _component_ConicalScrew = _resolveComponent("ConicalScrew");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ProductInfoCardMobile, {
      image: $setup.cardData[0].image,
      title: $setup.cardData[0].title,
      subtitle: $setup.cardData[0].subtitle,
      productsParams: $setup.cardData[0].productsParams
    }, null, 8, ["image", "title", "subtitle", "productsParams"]), _createVNode(_component_ChancetanScrew), _createVNode(_component_TscScreen), _createVNode(_component_StaimansRobs), _createVNode(_component_StaimansRobsWithNotch), _createVNode(_component_ShancRobs), _createVNode(_component_ShancRobsSecond), _createVNode(_component_ShancRobsThird), _createVNode(_component_ScrewRoster), _createVNode(_component_ConicalScrew)])]),
    _: 1
  });
}