import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const spinalBeamsCarts = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.spinalBeamsLinks.staightGirder'),
      img: require('@/assets/neurosurgery/staight-girder.png'),
      link: `/${i18n.global.locale.value}/catalog/neurosurgery/spinal-beam/pryamayabalka`
    }, {
      title: i18n.global.t('main.carts.neurosurgery.spinalBeamsLinks.curvedBeam'),
      img: require('@/assets/neurosurgery/curved-beam.png'),
      link: `/${i18n.global.locale.value}/catalog/neurosurgery/spinal-beam/izognutaya-balka`
    }, {
      title: i18n.global.t('main.carts.neurosurgery.spinalBeamsLinks.crossBar'),
      img: require('@/assets/neurosurgery/cross-bar.png'),
      link: `/${i18n.global.locale.value}/catalog/neurosurgery/spinal-beam/perekrestnayabalk`
    }]);
    return {
      spinalBeamsCarts
    };
  }

};