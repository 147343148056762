import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-2f815ab1"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "choose-lang-button__title"
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");

  return _openBlock(), _createBlock(_component_el_button, {
    class: "choose-lang-button"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, _toDisplayString($props.language), 1), _createElementVNode("img", {
      src: $props.flag,
      alt: "country flag"
    }, null, 8, _hoisted_2)]),
    _: 1
  });
}