import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const stretcherMedicalA03Data = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.stretcherMedicalA03'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalA03.subtitle'),
      image: require('@/assets/toolsAndEquipment/medicalFurniture/stretcher-medical-A03.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalA03.dataLeft1'),
        data: "185х54х24"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalA03.dataLeft2'),
        data: "92х54х14"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalA03.dataLeft3'),
        data: "60"
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalA03.dataLeft4'),
        data: "8"
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalA03.dataLeft5'),
        data: "160"
      }]
    }]);
    return {
      stretcherMedicalA03Data
    };
  }

};