import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BactericidalIrradiator4Lamp300MMobile = _resolveComponent("BactericidalIrradiator4Lamp300MMobile");

  const _component_MqResponsive = _resolveComponent("MqResponsive");

  const _component_BactericidalIrradiator4Lamp300M = _resolveComponent("BactericidalIrradiator4Lamp300M");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_MqResponsive, {
    target: ['xs', 'sm']
  }, {
    default: _withCtx(() => [_createVNode(_component_BactericidalIrradiator4Lamp300MMobile)]),
    _: 1
  }), _createVNode(_component_MqResponsive, {
    target: ['md', 'lg', 'xl', 'xxl']
  }, {
    default: _withCtx(() => [_createVNode(_component_BactericidalIrradiator4Lamp300M)]),
    _: 1
  })], 64);
}