import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "compressorInhaler-dolphin"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "compressorInhaler-dolphin__inner"
};
const _hoisted_4 = {
  class: "compressorInhaler-dolphin__card"
};
const _hoisted_5 = {
  class: "card"
};
const _hoisted_6 = {
  class: "card__product-info"
};
const _hoisted_7 = {
  key: 0
};
const _hoisted_8 = {
  class: "card__product-title"
};
const _hoisted_9 = {
  class: "blue-txt"
};
const _hoisted_10 = {
  class: "card__product-data"
};
const _hoisted_11 = {
  key: 1
};
const _hoisted_12 = {
  class: "card__product-title"
};
const _hoisted_13 = {
  class: "blue-txt"
};
const _hoisted_14 = {
  class: "card__product-data"
};
const _hoisted_15 = {
  key: 2
};
const _hoisted_16 = {
  class: "card__product-data"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MainHeader = _resolveComponent("MainHeader");

  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");

  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");

  const _component_LeftLinksMenu = _resolveComponent("LeftLinksMenu");

  const _component_ProductInfoCard = _resolveComponent("ProductInfoCard");

  const _component_MainFooter = _resolveComponent("MainFooter");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_MainHeader), _createVNode(_component_el_breadcrumb, {
    class: "breadcrumb",
    separator: "/"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_breadcrumb_item, {
      to: `/${_ctx.$i18n.locale}/catalog/`
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('header.catalog')), 1)]),
      _: 1
    }, 8, ["to"]), _createVNode(_component_el_breadcrumb_item, {
      to: `/${_ctx.$i18n.locale}/catalog/tools-and-equipment/inhalers-nebulizers`
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.carts.toolsAndEquipment.title')), 1)]),
      _: 1
    }, 8, ["to"]), _createVNode(_component_el_breadcrumb_item, null, {
      default: _withCtx(() => [_createElementVNode("a", null, _toDisplayString(_ctx.$t('main.carts.toolsAndEquipment.inhalersNebulizers.links.compressorInhalerDolphin')), 1)]),
      _: 1
    })]),
    _: 1
  }), _createElementVNode("div", _hoisted_3, [_createVNode(_component_LeftLinksMenu), _createElementVNode("div", _hoisted_4, [_createVNode(_component_ProductInfoCard, {
    image: $setup.compressorInhalerDolphinData[0].image,
    title: $setup.compressorInhalerDolphinData[0].title,
    subtitle: $setup.compressorInhalerDolphinData[0].subtitle,
    productsParams: $setup.compressorInhalerDolphinData[0].productsParams
  }, null, 8, ["image", "title", "subtitle", "productsParams"]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.textData, item => {
    return _openBlock(), _createElementBlock("div", {
      class: "card__product-info-inner",
      key: item.id
    }, [item.id === 1 ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_8, [_createElementVNode("h3", _hoisted_9, _toDisplayString(item.title), 1)]), _createElementVNode("div", _hoisted_10, _toDisplayString(item.data), 1)])])) : item.id === 2 ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_12, [_createElementVNode("h3", _hoisted_13, _toDisplayString(item.title), 1)]), _createElementVNode("div", _hoisted_14, _toDisplayString(item.data), 1)])])) : (_openBlock(), _createElementBlock("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, _toDisplayString(item.data), 1)]))]);
  }), 128))])])])])])]), _createVNode(_component_MainFooter)], 64);
}