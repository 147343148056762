import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const SterilizationBoxesCarts = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.sterilizationBoxesLinks.sterilizationBoxesRound'),
      img: require('@/assets/toolsAndEquipment/sterilization-boxes-round.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/boxes-sterilization-round/boxes-sterilization-round`
    }]);
    return {
      SterilizationBoxesCarts
    };
  }

};