import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.anesthesiologistsTable.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.anesthesiologistsTable.title1')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.anesthesiologistsTable.text2'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.anesthesiologistsTable.title2')
    }]);
    const anesthesiologistsTableData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.anesthesiologistsTable'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/anesthesiologists-table.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.anesthesiologistsTable.dataLeft1'),
        data: "630*530*900"
      }]
    }]);
    return {
      anesthesiologistsTableData,
      textData
    };
  }

};