import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "default_mobile__card"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductInfoCardMobile = _resolveComponent("ProductInfoCardMobile");

  const _component_TShapedPlateThreadResistance = _resolveComponent("TShapedPlateThreadResistance");

  const _component_TShapedPlateCurvedThreadResistance = _resolveComponent("TShapedPlateCurvedThreadResistance");

  const _component_TShapedPlateEyeletThreadResistant = _resolveComponent("TShapedPlateEyeletThreadResistant");

  const _component_TShapedPlateEyeletThreadResistantSecond = _resolveComponent("TShapedPlateEyeletThreadResistantSecond");

  const _component_ShoulderPlateThreadResistant = _resolveComponent("ShoulderPlateThreadResistant");

  const _component_UlnaPlateThreadResistant = _resolveComponent("UlnaPlateThreadResistant");

  const _component_RadiusPlateThreadResistant = _resolveComponent("RadiusPlateThreadResistant");

  const _component_DancePlate = _resolveComponent("DancePlate");

  const _component_ToothedPlate = _resolveComponent("ToothedPlate");

  const _component_ThoothedPlateSecond = _resolveComponent("ThoothedPlateSecond");

  const _component_ThoothedPlateThird = _resolveComponent("ThoothedPlateThird");

  const _component_CondylarSupportPlate = _resolveComponent("CondylarSupportPlate");

  const _component_ShoulderPlate = _resolveComponent("ShoulderPlate");

  const _component_AcetabularPlate = _resolveComponent("AcetabularPlate");

  const _component_ShinPlate = _resolveComponent("ShinPlate");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ProductInfoCardMobile, {
      image: $setup.cardData[0].image,
      title: $setup.cardData[0].title,
      subtitle: $setup.cardData[0].subtitle,
      productsParams: $setup.cardData[0].productsParams
    }, null, 8, ["image", "title", "subtitle", "productsParams"]), _createVNode(_component_TShapedPlateThreadResistance), _createVNode(_component_TShapedPlateCurvedThreadResistance), _createVNode(_component_TShapedPlateEyeletThreadResistant), _createVNode(_component_TShapedPlateEyeletThreadResistantSecond), _createVNode(_component_ShoulderPlateThreadResistant), _createVNode(_component_UlnaPlateThreadResistant), _createVNode(_component_RadiusPlateThreadResistant), _createVNode(_component_DancePlate), _createVNode(_component_ToothedPlate), _createVNode(_component_ThoothedPlateSecond), _createVNode(_component_ThoothedPlateThird), _createVNode(_component_CondylarSupportPlate), _createVNode(_component_ShoulderPlate), _createVNode(_component_AcetabularPlate), _createVNode(_component_ShinPlate)])]),
    _: 1
  });
}