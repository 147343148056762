import { computed } from 'vue';
import TShapedPlateThreadResistance from './grid/TShapedPlateThreadResistance.vue';
import TShapedPlateCurvedThreadResistance from './grid/TShapedPlateCurvedThreadResistance.vue';
import TShapedPlateEyeletThreadResistant from './grid/TShapedPlateEyeletThreadResistant.vue';
import TShapedPlateEyeletThreadResistantSecond from './grid/TShapedPlateEyeletThreadResistantSecond.vue';
import ShoulderPlateThreadResistant from './grid/ShoulderPlateThreadResistant.vue';
import UlnaPlateThreadResistant from './grid/UlnaPlateThreadResistant.vue';
import RadiusPlateThreadResistant from './grid/RadiusPlateThreadResistant.vue';
import DancePlate from './grid/DancePlate.vue';
import ToothedPlate from './grid/ToothedPlate.vue';
import ThoothedPlateSecond from './grid/ThoothedPlateSecond.vue';
import ThoothedPlateThird from './grid/ThoothedPlateThird.vue';
import CondylarSupportPlate from './grid/CondylarSupportPlate.vue';
import ShoulderPlate from './grid/ShoulderPlate.vue';
import AcetabularPlate from './grid/AcetabularPlate.vue';
import ShinPlate from './grid/ShinPlate.vue';
export default {
  name: 'SmallPlates',

  setup() {
    const cardData = computed(() => [{
      title: 'Спеціальні пластини',
      image: require('@/assets/traumatology/special-plates.jpeg')
    }]);
    return {
      cardData
    };
  },

  components: {
    TShapedPlateThreadResistance,
    TShapedPlateCurvedThreadResistance,
    TShapedPlateEyeletThreadResistant,
    TShapedPlateEyeletThreadResistantSecond,
    ShoulderPlateThreadResistant,
    UlnaPlateThreadResistant,
    RadiusPlateThreadResistant,
    DancePlate,
    ToothedPlate,
    ThoothedPlateSecond,
    ThoothedPlateThird,
    CondylarSupportPlate,
    ShoulderPlate,
    AcetabularPlate,
    ShinPlate
  }
};