import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const stretcherMedicalB11Data = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.stretcherMedicalB11'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB11.subtitle'),
      image: require('@/assets/toolsAndEquipment/medicalFurniture/stretcher-medical-B11.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB11.dataLeft1'),
        data: "50х41х85"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB11.dataLeft2'),
        data: "19х45х85"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB11.dataLeft3'),
        data: "11"
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB11.dataLeft4'),
        data: "13"
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalB11.dataLeft5'),
        data: "160"
      }]
    }]);
    return {
      stretcherMedicalB11Data
    };
  }

};