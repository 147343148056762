import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const cervicalPlatesTitaniumCarts = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.cervicalPlatesTitanium.plastin1'),
      img: require('@/assets/neurosurgery/plastin1.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.cervicalPlatesTitanium.plastin2'),
      img: require('@/assets/neurosurgery/plastin2.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.cervicalPlatesTitanium.plastin3'),
      img: require('@/assets/neurosurgery/plastin3.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.cervicalPlatesTitanium.plastin4'),
      img: require('@/assets/neurosurgery/plastin4.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.cervicalPlatesTitanium.plastin5'),
      img: require('@/assets/neurosurgery/plastin5.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.cervicalPlatesTitanium.plastin6'),
      img: require('@/assets/neurosurgery/plastin6.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.cervicalPlatesTitanium.plastin7'),
      img: require('@/assets/neurosurgery/plastin7.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.cervicalPlatesTitanium.plastin8'),
      img: require('@/assets/neurosurgery/plastin8.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.cervicalPlatesTitanium.plastin9'),
      img: require('@/assets/neurosurgery/plastin9.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.cervicalPlatesTitanium.plastin10'),
      img: require('@/assets/neurosurgery/plastin10.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.cervicalPlatesTitanium.plastin11'),
      img: require('@/assets/neurosurgery/plastin11.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.cervicalPlatesTitanium.plastin12'),
      img: require('@/assets/neurosurgery/plastin12.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.cervicalPlatesTitanium.plastin13'),
      img: require('@/assets/neurosurgery/plastin13.png')
    }, {
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalPlatesLinks.cervicalPlatesTitanium.plastin14'),
      img: require('@/assets/neurosurgery/plastin14.png')
    }]);
    return {
      cervicalPlatesTitaniumCarts
    };
  }

};