import { computed } from 'vue';
export default {
  name: 'KirchenersSpoke',

  setup() {
    const tableData = computed(() => [{
      first: 'Z6.1.0.150',
      second: '150',
      third: '1,0'
    }, {
      first: 'Z6.1.0.200',
      second: '200',
      third: '1,0'
    }, {
      first: 'Z6.1.5.250',
      second: '250',
      third: '1,5'
    }, {
      first: 'Z6.1.5.300',
      second: '300',
      third: '1,5'
    }, {
      first: 'Z6.1.8.250',
      second: '250',
      third: '1,8'
    }, {
      first: 'Z6.1.8.300',
      second: '300',
      third: '1,8'
    }, {
      first: 'Z6.1.8.400',
      second: '400',
      third: '1,8'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/kirchner-spoke.jpeg'),
      productsParams: [{
        id: 1,
        name: 'Матеріал',
        data: 'нерж. сталь'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};