import { computed, ref } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const tableData = ref([{
      first: '40',
      second: '36150-4504050'
    }, {
      first: '50',
      second: '36150-45050'
    }, {
      first: '60',
      second: '36150-45060'
    }, {
      first: '70',
      second: '36150-45070'
    }, {
      first: '80',
      second: '36150-45080'
    }, {
      first: '90',
      second: '36150-45090'
    }]);
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.spinalBeamsLinks.crossBar'),
      subtitle: "",
      image: require('@/assets/neurosurgery/cross-bar.png'),
      productsParams: []
    }]);
    return {
      cardData,
      tableData
    };
  }

};