import { computed } from 'vue';
export default {
  name: 'HairpinPartialThread',

  setup() {
    const tableData = computed(() => [{
      first: '130',
      second: '2.3.130'
    }, {
      first: '170',
      second: '2.3.170'
    }, {
      first: '210',
      second: '2.3.210'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/hair-pin-thread.jpeg')
    }]);
    return {
      tableData,
      cardData
    };
  }

};