import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const stretcherMedicalA16Data = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.stretcherMedicalA16'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalA16.subtitle'),
      image: require('@/assets/toolsAndEquipment/medicalFurniture/stretcher-medical-A16.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalA16.dataLeft1'),
        data: "212x75x7"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalA16.dataLeft2'),
        data: "70х60х37"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalA16.dataLeft3'),
        data: "160"
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalA16.dataLeft4'),
        data: "5"
      }]
    }]);
    return {
      stretcherMedicalA16Data
    };
  }

};