export default {
  name: 'ProductTableHeader',
  props: {
    title: {
      type: String,
      default: ''
    },
    productsParams: {
      type: Array,
      default: () => []
    },
    image: {
      type: String,
      default: ''
    }
  }
};