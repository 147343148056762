import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const screwChairWithBackrestSVSData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.screwChairWithBackrestSVS'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.screwChairWithBackrestSVS.subtitle'),
      image: require('@/assets/toolsAndEquipment/medicalFurniture/screw-chairwith-backrest-SVS.png'),
      productsParams: []
    }]);
    return {
      screwChairWithBackrestSVSData
    };
  }

};