import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.bowelFlushingCouchCPC.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.bowelFlushingCouchCPC.title1')
    }]);
    const bowelFlushingCouchCPCData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.bowelFlushingCouchCPC'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/bowel-flushingcouch-CPC.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.bowelFlushingCouchCPC.dataLeft1'),
        data: "850"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.bowelFlushingCouchCPC.dataLeft2'),
        data: "700"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.bowelFlushingCouchCPC.dataLeft3'),
        data: "1900"
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.bowelFlushingCouchCPC.dataLeft4'),
        data: "230"
      }]
    }]);
    return {
      bowelFlushingCouchCPCData,
      textData
    };
  }

};