import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const gynecologicalChairKG2MData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.gynecologicalChairKG2M'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairKG2M.subtitle'),
      image: require('@/assets/toolsAndEquipment/medicalFurniture/gynecological-chair-KG2M.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairKG2M.dataLeft1'),
        data: "0-100"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairKG2M.dataLeft2'),
        data: "0-30"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairKG2M.dataLeft3'),
        data: "750"
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairKG2M.dataLeft4'),
        data: "490"
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairKG2M.dataLeft5'),
        data: "1070"
      }]
    }]);
    return {
      gynecologicalChairKG2MData
    };
  }

};