import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  name: 'ReconstructivePlateGrid',

  setup() {
    const tableData = computed(() => [{
      first: '34',
      second: '3',
      third: '13230-03403',
      fourth: '33230-03403'
    }, {
      first: ' 46',
      second: '4',
      third: '13230-04604',
      fourth: '33230-04604'
    }, {
      first: '58',
      second: '5',
      third: '13230-05805',
      fourth: '33230-05805'
    }, {
      first: '70',
      second: '6',
      third: '13230-07006',
      fourth: '33230-07006'
    }, {
      first: '82',
      second: '7',
      third: '13230-08207',
      fourth: '33230-08207'
    }, {
      first: '94',
      second: '8',
      third: '13230-09408',
      fourth: '33230-09408'
    }, {
      first: '106',
      second: '9',
      third: '13230-10609',
      fourth: '33230-10609'
    }, {
      first: '118',
      second: '10',
      third: '13230-11810',
      fourth: '33230-11810'
    }, {
      first: '142',
      second: '12',
      third: '13230-14212',
      fourth: '33230-14212'
    }, {
      first: '166',
      second: '14',
      third: '13230-16614',
      fourth: '33230-16614'
    }, {
      first: '190',
      second: '16',
      third: '13230-19016',
      fourth: '33230-19016'
    }, {
      first: '214',
      second: '18',
      third: '13230-21418',
      fourth: '33230-21418'
    }, {
      first: '238',
      second: '20',
      third: '13230-23820',
      fourth: '33230-23820'
    }, {
      first: '262',
      second: '22',
      third: '13230-26222',
      fourth: '33230-26222'
    }, {
      first: '286',
      second: '24',
      third: '13230-28624',
      fourth: '33230-28624'
    }, {
      first: '310',
      second: '26',
      third: '13230-31026',
      fourth: '33230-31026'
    }, {
      first: '334',
      second: '28',
      third: '13230-33428',
      fourth: '33230-33428'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/reconstructive-plate.jpeg'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.traumatology.smallInsertsWithAngularStability.thickness'),
        data: i18n.global.t('main.carts.traumatology.smallInsertsWithAngularStability.thicknessData.first')
      }, {
        id: 2,
        name: i18n.global.t('main.carts.traumatology.smallInsertsWithAngularStability.width'),
        data: i18n.global.t('main.carts.traumatology.smallInsertsWithAngularStability.widthData.first')
      }, {
        id: 3,
        name: i18n.global.t('main.carts.traumatology.smallInsertsWithAngularStability.screwDiameter'),
        data: i18n.global.t('main.carts.traumatology.smallInsertsWithAngularStability.screwDiameterData.first')
      }, {
        id: 4,
        name: i18n.global.t('main.carts.traumatology.smallInsertsWithAngularStability.material'),
        data: i18n.global.t('main.carts.traumatology.smallInsertsWithAngularStability.materialData')
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};