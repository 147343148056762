import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  name: 'EndoprosthesesForUrogynecology',

  setup() {
    const endoprosthesesPolymerMeshesCards = computed(() => [{
      title: 'урослінг',
      img: require('@/assets/surgery/urosling.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/endoprostheses-for-urogynecology/urosling`
    }, {
      title: 'урофікс пл',
      img: require('@/assets/surgery/uroflix-pl.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/endoprostheses-for-urogynecology/urofix-pl`
    }, {
      title: 'урофікс то',
      img: require('@/assets/surgery/uroflix-to.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/endoprostheses-for-urogynecology/urofix-to`
    }]);
    return {
      endoprosthesesPolymerMeshesCards
    };
  }

};