import { computed } from 'vue';
export default {
  name: 'CondylarScrewPlateAngleResistance',

  setup() {
    const tableData = computed(() => [{
      first: '4',
      second: '78',
      third: '13902-09504'
    }, {
      first: '5',
      second: '94',
      third: '13902-09505'
    }, {
      first: '6',
      second: '110',
      third: '13902-09506'
    }, {
      first: '7',
      second: '129',
      third: '13902-09507'
    }, {
      first: '8',
      second: '142',
      third: '13902-09508'
    }, {
      first: '10',
      second: '176',
      third: '13902-09510'
    }, {
      first: '12',
      second: '206',
      third: '13902-09512'
    }, {
      first: '14',
      second: '238',
      third: '13902-09514'
    }, {
      first: '16',
      second: '270',
      third: '13902-09516'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/condylar-screw-plate-limited-contact.jpeg'),
      productsParams: [{
        id: 1,
        name: 'Матеріал',
        data: 'Нерж. сталь або сплав титану'
      }, {
        id: 2,
        name: '',
        data: 'У разі сплаву титану перша цифра буде 3'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};