import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "default_mobile__card"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductInfoCardMobile = _resolveComponent("ProductInfoCardMobile");

  const _component_BlockedScrew3and9 = _resolveComponent("BlockedScrew3and9");

  const _component_BlockedScrew4and9 = _resolveComponent("BlockedScrew4and9");

  const _component_BlockedScrew6and5 = _resolveComponent("BlockedScrew6and5");

  const _component_AntirotationScrew6and5 = _resolveComponent("AntirotationScrew6and5");

  const _component_AnchorScrew3and5 = _resolveComponent("AnchorScrew3and5");

  const _component_AnchorScrew4 = _resolveComponent("AnchorScrew4");

  const _component_AnchorScrew4and5 = _resolveComponent("AnchorScrew4and5");

  const _component_FemoralM8 = _resolveComponent("FemoralM8");

  const _component_FemoralM9and5 = _resolveComponent("FemoralM9and5");

  const _component_FemoralScrewR8 = _resolveComponent("FemoralScrewR8");

  const _component_FemoralScrewR9and5 = _resolveComponent("FemoralScrewR9and5");

  const _component_HipScrew9and5 = _resolveComponent("HipScrew9and5");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ProductInfoCardMobile, {
      image: $setup.cardData[0].image,
      title: $setup.cardData[0].title,
      subtitle: $setup.cardData[0].subtitle,
      productsParams: $setup.cardData[0].productsParams
    }, null, 8, ["image", "title", "subtitle", "productsParams"]), _createVNode(_component_BlockedScrew3and9), _createVNode(_component_BlockedScrew4and9), _createVNode(_component_BlockedScrew6and5), _createVNode(_component_AntirotationScrew6and5), _createVNode(_component_AnchorScrew3and5), _createVNode(_component_AnchorScrew4), _createVNode(_component_AnchorScrew4and5), _createVNode(_component_FemoralM8), _createVNode(_component_FemoralM9and5), _createVNode(_component_FemoralScrewR8), _createVNode(_component_FemoralScrewR9and5), _createVNode(_component_HipScrew9and5)])]),
    _: 1
  });
}