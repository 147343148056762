import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const cartRemovableStretcherData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.cartRemovableStretcher'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/cart-removable-stretcher.png'),
      productsParams: []
    }]);
    return {
      cartRemovableStretcherData
    };
  }

};