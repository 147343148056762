import { computed } from 'vue';
import i18n from '@/i18n';
import MiniPlate4x1 from './grid/MiniPlate4x1.vue';
import MiniPlate5x1 from './grid/MiniPlate5x1.vue';
import MiniPlateFacial from './grid/MiniPlateFacial.vue';
import MiniPlateTShaped from './grid/MiniPlateTShaped.vue';
import MiniPlateCondyle from './grid/MiniPlateCondyle.vue';
import MiniPlateTShapedSecond from './grid/MiniPlateTShapedSecond.vue';
import MiniPlateLShaped from './grid/MiniPlateLShaped.vue';
import MiniPlateLShapedCurved from './grid/MiniPlateLShapedCurved.vue';
import MiniPlateHShaped from './grid/MiniPlateHShaped.vue';
import PlateTShapedThird from './grid/PlateTShapedThird.vue';
import PlateLShaped from './grid/PlateLShaped.vue';
import PlateLShapedSecond from './grid/PlateLShapedSecond.vue';
import MiniPlates from './grid/MiniPlates.vue';
import SelfTappingScrew from './grid/SelfTappingScrew.vue';
import ApparatusForTreatment from './grid/ApparatusForTreatment.vue';
export default {
  name: 'MicroSurgery',

  setup() {
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.traumatology.corticalScrews.title'),
      image: require('@/assets/traumatology/cortical-screws.jpeg')
    }]);
    return {
      cardData
    };
  },

  components: {
    MiniPlate4x1,
    MiniPlate5x1,
    MiniPlateFacial,
    MiniPlateTShaped,
    MiniPlateCondyle,
    MiniPlateTShapedSecond,
    MiniPlateLShaped,
    MiniPlateLShapedCurved,
    MiniPlateHShaped,
    PlateTShapedThird,
    PlateLShaped,
    PlateLShapedSecond,
    MiniPlates,
    SelfTappingScrew,
    ApparatusForTreatment
  }
};