import { computed } from 'vue';
export default {
  setup() {
    const cardData = computed(() => [{
      title: 'Стержні',
      image: require('@/assets/traumatology/robs.jpeg')
    }]);
    return {
      cardData
    };
  }

};