import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const CoagulatorsCarts = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.links.coagulator'),
      img: require('@/assets/toolsAndEquipment/equipmentOperatingRooms/coagulator.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/operational-equipment/koagulyatory/coagulator`
    }]);
    return {
      CoagulatorsCarts
    };
  }

};