import { computed } from 'vue';
export default {
  name: 'CerclageWireWithHead',

  setup() {
    const tableData = computed(() => [{
      first: '0,8',
      second: '280',
      third: '15851-08280'
    }, {
      first: '1,0',
      second: '280',
      third: '15851-08280'
    }, {
      first: '1,2',
      second: '280',
      third: '15851-08280'
    }, {
      first: '1,0',
      second: '600',
      third: '15851-08600'
    }, {
      first: '1,2',
      second: '600',
      third: '15851-08600'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/cerclage-wire-with-head.jpeg'),
      productsParams: [{
        id: 4,
        name: 'Матеріал',
        data: 'нерж. сталь'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};