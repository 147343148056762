import { computed } from 'vue';
import DynamicalFemoralSystemsComponent from './grid/DynamicalFemoralSystemsComponent.vue';
import FemoralScrewPlate135 from './grid/FemoralScrewPlate135.vue';
import CondylarScrewPlate95 from './grid/CondylarScrewPlate95.vue';
import FemoralScrewPlate135LimitedContact from './grid/FemoralScrewPlate135LimitedContact.vue';
import CondylarScrewPlate95LimitedContact from './grid/CondylarScrewPlate95LimitedContact.vue';
import FemoralScrewPlateAngleResistance from './grid/FemoralScrewPlateAngleResistance.vue';
import CondylarScrewPlateAngleResistance from './grid/CondylarScrewPlateAngleResistance.vue';
export default {
  name: 'DynamicalFemoralSystems',

  setup() {
    const cardData = computed(() => [{
      title: 'Динамічні стегнові та виросткові системи',
      image: require('@/assets/traumatology/dynamical-hip-systems.jpeg')
    }]);
    return {
      cardData
    };
  },

  components: {
    DynamicalFemoralSystemsComponent,
    FemoralScrewPlate135,
    CondylarScrewPlate95,
    FemoralScrewPlate135LimitedContact,
    CondylarScrewPlate95LimitedContact,
    FemoralScrewPlateAngleResistance,
    CondylarScrewPlateAngleResistance
  }
};