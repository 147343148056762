import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-0447dc82"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "page-not-found"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "page-not-found__inner"
};
const _hoisted_4 = {
  class: "blue"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MainHeader = _resolveComponent("MainHeader");

  const _component_el_button = _resolveComponent("el-button");

  const _component_router_link = _resolveComponent("router-link");

  const _component_MainFooter = _resolveComponent("MainFooter");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_MainHeader), _createElementVNode("div", _hoisted_3, [_createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('404.text')), 1), _createVNode(_component_router_link, {
    to: `/${_ctx.$i18n.locale}`
  }, {
    default: _withCtx(() => [_createVNode(_component_el_button, null, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('404.button')), 1)]),
      _: 1
    })]),
    _: 1
  }, 8, ["to"])])])]), _createVNode(_component_MainFooter)], 64);
}