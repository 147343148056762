import { computed } from 'vue';
export default {
  name: 'LoopNut',

  setup() {
    const tableData = computed(() => [{
      first: '1',
      second: '3.3.1'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/loop-nut.jpeg')
    }]);
    return {
      tableData,
      cardData
    };
  }

};