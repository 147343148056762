import { computed } from 'vue';
export default {
  name: 'AcetabularPlate',

  setup() {
    const tableData = computed(() => [{
      first: '25',
      second: '13893-25005',
      third: '33893-25005'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/acetabular-plate.jpeg')
    }]);
    return {
      tableData,
      cardData
    };
  }

};