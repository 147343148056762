import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "default_mobile__card"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductInfoCardMobile = _resolveComponent("ProductInfoCardMobile");

  const _component_SmallPlate8x2 = _resolveComponent("SmallPlate8x2");

  const _component_SmallPlateWithLimitedContact8x2 = _resolveComponent("SmallPlateWithLimitedContact8x2");

  const _component_ReconstructivePlate8x2and7 = _resolveComponent("ReconstructivePlate8x2and7");

  const _component_DirectPlate10x3 = _resolveComponent("DirectPlate10x3");

  const _component_DirectPlateLimitedContact = _resolveComponent("DirectPlateLimitedContact");

  const _component_SmallSelfCompresivePlate = _resolveComponent("SmallSelfCompresivePlate");

  const _component_SelfCompresivePlateLimitedContact = _resolveComponent("SelfCompresivePlateLimitedContact");

  const _component_PipePlate = _resolveComponent("PipePlate");

  const _component_PipePlateForced = _resolveComponent("PipePlateForced");

  const _component_ReconstructivePlate10x3 = _resolveComponent("ReconstructivePlate10x3");

  const _component_PelvisReconstructivePlate = _resolveComponent("PelvisReconstructivePlate");

  const _component_PlateYShaped = _resolveComponent("PlateYShaped");

  const _component_ScatterBonePlate = _resolveComponent("ScatterBonePlate");

  const _component_TShapedSmallPlate = _resolveComponent("TShapedSmallPlate");

  const _component_SmallTShapedCurvedPlate = _resolveComponent("SmallTShapedCurvedPlate");

  const _component_SelfCompresiveNarrowPlate = _resolveComponent("SelfCompresiveNarrowPlate");

  const _component_SmallSelfCompresivePlateWithLimitedContact = _resolveComponent("SmallSelfCompresivePlateWithLimitedContact");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ProductInfoCardMobile, {
      image: $setup.cardData[0].image,
      title: $setup.cardData[0].title,
      subtitle: $setup.cardData[0].subtitle,
      productsParams: $setup.cardData[0].productsParams
    }, null, 8, ["image", "title", "subtitle", "productsParams"]), _createVNode(_component_SmallPlate8x2), _createVNode(_component_SmallPlateWithLimitedContact8x2), _createVNode(_component_ReconstructivePlate8x2and7), _createVNode(_component_DirectPlate10x3), _createVNode(_component_DirectPlateLimitedContact), _createVNode(_component_SmallSelfCompresivePlate), _createVNode(_component_SelfCompresivePlateLimitedContact), _createVNode(_component_PipePlate), _createVNode(_component_PipePlateForced), _createVNode(_component_ReconstructivePlate10x3), _createVNode(_component_PelvisReconstructivePlate), _createVNode(_component_PlateYShaped), _createVNode(_component_ScatterBonePlate), _createVNode(_component_TShapedSmallPlate), _createVNode(_component_SmallTShapedCurvedPlate), _createVNode(_component_SelfCompresiveNarrowPlate), _createVNode(_component_SmallSelfCompresivePlateWithLimitedContact)])]),
    _: 1
  });
}