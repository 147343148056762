import { computed } from 'vue';
export default {
  name: 'ClipKnittingNeedles',

  setup() {
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/clip-for-knitting-needles.jpeg')
    }]);
    return {
      cardData
    };
  }

};