import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const inhaler403AData = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalInstrument.links.glowardTypeCupJaws'),
      subtitle: "",
      image: require('@/assets/neurosurgery/glowardtype-cup-jaws.png'),
      productsParams: []
    }]);
    return {
      inhaler403AData
    };
  }

};