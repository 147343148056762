import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.patientTransferCart.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.patientTransferCart.title1')
    }]);
    const patientTransferCartData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.patientTransferCart'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/patient-transfer-cart.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.patientTransferCart.dataLeft1'),
        data: "1900"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.patientTransferCart.dataLeft2'),
        data: "700"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.patientTransferCart.dataLeft3'),
        data: "580-800"
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.patientTransferCart.dataLeft4'),
        data: "15-70"
      }]
    }]);
    return {
      patientTransferCartData,
      textData
    };
  }

};