import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "compressorInhaler-boreal"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "compressorInhaler-boreal__inner"
};
const _hoisted_4 = {
  class: "compressorInhaler-boreal__card"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MainHeader = _resolveComponent("MainHeader");

  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");

  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");

  const _component_LeftLinksMenu = _resolveComponent("LeftLinksMenu");

  const _component_ProductInfoCard = _resolveComponent("ProductInfoCard");

  const _component_MainFooter = _resolveComponent("MainFooter");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_MainHeader), _createVNode(_component_el_breadcrumb, {
    class: "breadcrumb",
    separator: "/"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_breadcrumb_item, {
      to: `/${_ctx.$i18n.locale}/catalog/`
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('header.catalog')), 1)]),
      _: 1
    }, 8, ["to"]), _createVNode(_component_el_breadcrumb_item, {
      to: `/${_ctx.$i18n.locale}/catalog/neurosurgery/neurosurgical-tool`
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.carts.neurosurgery.title')), 1)]),
      _: 1
    }, 8, ["to"]), _createVNode(_component_el_breadcrumb_item, null, {
      default: _withCtx(() => [_createElementVNode("a", null, _toDisplayString(_ctx.$t('main.carts.neurosurgery.neurosurgicalInstrument.links.1120214014CM')), 1)]),
      _: 1
    })]),
    _: 1
  }), _createElementVNode("div", _hoisted_3, [_createVNode(_component_LeftLinksMenu), _createElementVNode("div", _hoisted_4, [_createVNode(_component_ProductInfoCard, {
    image: $setup.inhaler403AData[0].image,
    title: $setup.inhaler403AData[0].title,
    subtitle: $setup.inhaler403AData[0].subtitle,
    productsParams: $setup.inhaler403AData[0].productsParams
  }, null, 8, ["image", "title", "subtitle", "productsParams"])])])])]), _createVNode(_component_MainFooter)], 64);
}