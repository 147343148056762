import { computed } from 'vue';
import i18n from '@/i18n';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
export default {
  components: {
    Swiper,
    SwiperSlide
  },

  setup() {
    const toolsAndEquipmentCarts = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.links.sterilization'),
      img: require('@/assets/toolsAndEquipment/sterilization.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.links.inhalersNebulizers'),
      img: require('@/assets/toolsAndEquipment/inhalers-nebulizers.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/inhalers-nebulizers`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.links.equipmentForOperatingRooms'),
      img: require('@/assets/toolsAndEquipment/equipment-for-operating-rooms.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/operational-equipment`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.links.medicalFurniture'),
      img: require('@/assets/toolsAndEquipment/medical-furniture.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/medical-furniture`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.links.cardiology'),
      img: require('@/assets/toolsAndEquipment/cardiology.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/cardiology`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.links.medicalInstrument'),
      img: require('@/assets/toolsAndEquipment/medical-instrument.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/medical-instruments`
    }]);
    return {
      toolsAndEquipmentCarts,
      modules: [Pagination]
    };
  }

};