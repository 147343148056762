import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const cardData = computed(() => [{
      title: i18n.global.t("main.carts.traumatology.spongeScrews.title"),
      image: require("@/assets/traumatology/sponge-screws.jpeg")
    }]);
    return {
      cardData
    };
  }

};