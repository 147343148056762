import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  name: 'SutureMaterials',

  setup() {
    const sutureMaterialsCardsFirst = computed(() => [{
      title: 'уніфлекс',
      img: require('@/assets/surgery/uniflex.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surger/suture-materials/uniflex`
    }, {
      title: 'моноамід',
      img: require('@/assets/surgery/monoamide.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surger/suture-materials/monoamid`
    }, {
      title: 'монофіл',
      img: require('@/assets/surgery/monofil.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surger/suture-materials/monofil`
    }, {
      title: 'капрон',
      img: require('@/assets/surgery/capron.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surger/suture-materials/capron`
    }, {
      title: 'лавсан',
      img: require('@/assets/surgery/lavsan.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surger/suture-materials/lavsan`
    }, {
      title: 'капрогент',
      img: require('@/assets/surgery/caprogent.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surger/suture-materials/caprogent`
    }, {
      title: 'фторлін',
      img: require('@/assets/surgery/fluorolin.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surger/suture-materials/ftorlin`
    }, {
      title: 'шовк',
      img: require('@/assets/surgery/silk.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surger/suture-materials/silk`
    }, {
      title: 'фторекс',
      img: require('@/assets/surgery/fluorex.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surger/suture-materials/ftorex`
    }]);
    const sutureMaterialsCardsSecond = computed(() => [{
      title: 'ультрасорб',
      img: require('@/assets/surgery/ultrasorb.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surger/suture-materials/ultrasorb`
    }, {
      title: 'пга',
      img: require('@/assets/surgery/pga.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surger/suture-materials/pga`
    }, {
      title: 'кетгут простий',
      img: require('@/assets/surgery/simple-catgut.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surger/suture-materials/simple-catgut`
    }, {
      title: 'пга-рапід',
      img: require('@/assets/surgery/pga-rapid.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surger/suture-materials/pga-rapid`
    }, {
      title: 'моносорб',
      img: require('@/assets/surgery/uniflex.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surger/suture-materials/monosorb`
    }]);
    return {
      sutureMaterialsCardsFirst,
      sutureMaterialsCardsSecond
    };
  }

};