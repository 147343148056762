import { computed } from 'vue';
import SpokeFirst from './grid/SpokeFirst.vue';
import SpokeSecond from './grid/SpokeSecond.vue';
import SpokeShoulder from './grid/SpokeShoulder.vue';
import SpokeShoulderTypeK from './grid/SpokeShoulderTypeK.vue';
import SpokeSteinman from './grid/SpokeSteinman.vue';
import SpokeSimple from './grid/SpokeSimple.vue';
import PinMedialThread from './grid/PinMedialThread.vue';
import SpokeCondylus from './grid/SpokeCondylus.vue';
import SpokeShoulderAndTibial from './grid/SpokeShoulderAndTibial.vue';
import KirchenersSpoke from './grid/KirchenersSpoke.vue';
import KirchenersSpokeSecond from './grid/KirchenersSpokeSecond.vue';
import IlizarovsSpoke from './grid/IlizarovsSpoke';
import IlizarovsSpokeSecond from './grid/IlizarovsSpokeSecond.vue';
export default {
  name: 'NeedlesScreen',

  setup() {
    const cardData = computed(() => [{
      title: 'Спиці',
      image: require('@/assets/traumatology/needles.jpeg')
    }]);
    return {
      cardData
    };
  },

  components: {
    SpokeFirst,
    SpokeSecond,
    SpokeShoulder,
    SpokeShoulderTypeK,
    SpokeSteinman,
    SpokeSimple,
    PinMedialThread,
    SpokeCondylus,
    SpokeShoulderAndTibial,
    KirchenersSpoke,
    KirchenersSpokeSecond,
    IlizarovsSpoke,
    IlizarovsSpokeSecond
  }
};