import { computed } from 'vue';
import i18n from '@/i18n';
import CorticalScrew3and5 from './grid/CorticalScrew3and5.vue';
import MalleolarScrew from './grid/MalleolarScrew.vue';
export default {
  name: 'CorticalScrews',

  setup() {
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.traumatology.corticalScrews.title'),
      image: require('@/assets/traumatology/cortical-screws.jpeg')
    }]);
    return {
      cardData
    };
  },

  components: {
    CorticalScrew3and5,
    MalleolarScrew
  }
};