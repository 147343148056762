import { computed } from 'vue';
export default {
  name: 'ScrewBlock',

  setup() {
    const tableData = computed(() => [{
      first: '1.5',
      second: '5.3.1.5'
    }, {
      first: '2',
      second: '5.3.2.0'
    }, {
      first: '4',
      second: '5.3.4.0'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/washer.jpeg')
    }]);
    return {
      tableData,
      cardData
    };
  }

};