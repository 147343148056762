import { computed } from 'vue';
import i18n from '@/i18n';
import LockingSmallTPlateGrid from './grid/LockingSmallTPlateGrid.vue';
import LockingSmallTPlateObliqueGrid from './grid/LockingSmallTPlateObliqueGrid.vue';
import LockingDistalMedialHumerusPlateGrid from './grid/LockingDistalMedialHumerusPlateGrid.vue';
import ThreadStabilizedSmallTPlateGrid from './grid/ThreadStabilizedSmallTPlateGrid.vue';
import ThreadStabilizedSmallTPlateObliqueGrid from './grid/ThreadStabilizedSmallTPlateObliqueGrid.vue';
import LockingDistalFemurPlateGrid from './grid/LockingDistalFemurPlateGrid.vue';
import LockingProximalTibiaPlateGrid from './grid/LockingProximalTibiaPlateGrid.vue';
import LockingDistalMedialTibiaPlateGrid from './grid/LockingDistalMedialTibiaPlateGrid.vue';
export default {
  name: 'SpecialInsertsWithAngularStability',

  setup() {
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.traumatology.specialInsertsWithAngularStability.title'),
      image: require('@/assets/traumatology/special-inserts-with-angular-stability.jpeg')
    }]);
    return {
      cardData
    };
  },

  components: {
    LockingSmallTPlateGrid,
    LockingSmallTPlateObliqueGrid,
    LockingDistalMedialHumerusPlateGrid,
    ThreadStabilizedSmallTPlateGrid,
    ThreadStabilizedSmallTPlateObliqueGrid,
    LockingDistalFemurPlateGrid,
    LockingProximalTibiaPlateGrid,
    LockingDistalMedialTibiaPlateGrid
  }
};