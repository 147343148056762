import { computed } from 'vue';
export default {
  name: 'LoopConnecting',

  setup() {
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/loop-connecting.jpeg')
    }]);
    return {
      cardData
    };
  }

};