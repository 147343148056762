import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.compressorInhalerDolphin.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.compressorInhalerDolphin.title1')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.compressorInhalerDolphin.text2'),
      title: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.compressorInhalerDolphin.title2')
    }]);
    const compressorInhalerDolphinData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.links.compressorInhalerDolphin'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/inhalersNebulizers/compressor-inhaler-dolphin.png'),
      productsParams: []
    }]);
    return {
      compressorInhalerDolphinData,
      textData
    };
  }

};