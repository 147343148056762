import { useRoute } from 'vue-router';
import { computed } from 'vue';
export default {
  setup() {
    const route = useRoute();
    const currentPagePath = computed(() => route.path);
    return {
      currentPagePath
    };
  }

};