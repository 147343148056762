import { computed } from 'vue';
export default {
  name: 'ScrewBlock',

  setup() {
    const tableData = computed(() => [{
      first: '10',
      second: '5.1.10'
    }, {
      first: '16',
      second: '5.1.16'
    }, {
      first: '30',
      second: '5.1.30'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/screw.jpeg')
    }]);
    return {
      tableData,
      cardData
    };
  }

};