import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.title1')
    }]);
    const surgicalElemaData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.links.surgicalElema'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/equipmentOperatingRooms/surgical-elema.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataLeft1'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataRight1')
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataLeft2'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataRight2')
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataLeft3'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataRight3')
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataLeft4'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataRight4')
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataLeft5'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataRight5')
      }, {
        id: 6,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataLeft6'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataRight6')
      }, {
        id: 7,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataLeft7'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataRight7')
      }, {
        id: 8,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataLeft8'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataRight8')
      }, {
        id: 9,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataLeft9'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataRight9')
      }, {
        id: 10,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataLeft10'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataRight10')
      }, {
        id: 11,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataLeft11'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataRight11')
      }, {
        id: 12,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataLeft12'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataRight12')
      }, {
        id: 13,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataLeft13'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataRight13')
      }, {
        id: 14,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataLeft14'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.surgicalElema.surgicalElemaDataRight14')
      }]
    }]);
    return {
      surgicalElemaData,
      textData
    };
  }

};