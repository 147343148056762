import { computed } from 'vue';
export default {
  name: 'PlankThreadedEnd',

  setup() {
    const tableData = computed(() => [{
      first: '135',
      second: '4.2.135'
    }, {
      first: '175',
      second: '4.2.175'
    }, {
      first: '215',
      second: '4.2.215'
    }, {
      first: '255',
      second: '4.2.255'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/plank-threaded-end.jpeg')
    }]);
    return {
      tableData,
      cardData
    };
  }

};