import { computed, ref } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const tableData = ref([{
      first: 'Left',
      second: '36210-10000'
    }, {
      first: 'Right',
      second: '36211-10001'
    }]);
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.spinalSystemsLinks.sacredBlock'),
      subtitle: "",
      image: require('@/assets/neurosurgery/sacred-block.png'),
      productsParams: []
    }]);
    return {
      cardData,
      tableData
    };
  }

};