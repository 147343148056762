import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const gynecologicalPediatricChairElectricDriveData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.gynecologicalPediatricChairElectricDrive'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalPediatricChairElectricDrive.subtitle'),
      image: require('@/assets/toolsAndEquipment/medicalFurniture/gynecological-pediatricchair-electricdrive.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalPediatricChairElectricDrive.dataLeft1'),
        data: "550–1300"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalPediatricChairElectricDrive.dataLeft2'),
        data: "500"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalPediatricChairElectricDrive.dataLeft3'),
        data: "0-90"
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalPediatricChairElectricDrive.dataLeft4'),
        data: "600"
      }]
    }]);
    return {
      gynecologicalPediatricChairElectricDriveData
    };
  }

};