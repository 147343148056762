import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const neurosurgeryLinks = computed(() => [{
      name: i18n.global.t('main.carts.neurosurgery.links.transpedicularFixationOfTheSpine'),
      path: `/${i18n.global.locale.value}/catalog/neurosurgery/transpedikulyarnaya-fiksatsiya-pozvonochnika`
    }, [{
      name: i18n.global.t('main.carts.neurosurgery.secondlinks.spinalScrews'),
      path: `/${i18n.global.locale.value}/catalog/neurosurgery/spinal-screws`
    }, {
      name: i18n.global.t('main.carts.neurosurgery.secondlinks.spinalBeams'),
      path: `/${i18n.global.locale.value}/catalog/neurosurgery/spinal-beam`
    }, {
      name: i18n.global.t('main.carts.neurosurgery.secondlinks.spinalHooks'),
      path: `/${i18n.global.locale.value}/catalog/neurosurgery/spinal-hooks`
    }, {
      name: i18n.global.t('main.carts.neurosurgery.secondlinks.spinalSystems'),
      path: `/${i18n.global.locale.value}/catalog/neurosurgery/spinal-system`
    }], {
      name: i18n.global.t('main.carts.neurosurgery.links.neurosurgicalPlates'),
      path: `/${i18n.global.locale.value}/catalog/neurosurgery/plates-neurosurgical`
    }, {
      name: i18n.global.t('main.carts.neurosurgery.links.vertebralEndoprostheses'),
      path: `/${i18n.global.locale.value}/catalog/neurosurgery/vertebrae-implants`
    }, {
      name: i18n.global.t('main.carts.neurosurgery.links.neurosurgicalInstrument'),
      path: `/${i18n.global.locale.value}/catalog/neurosurgery/neurosurgical-tool`
    }, {
      name: i18n.global.t('main.carts.neurosurgery.links.antiAdhesionGel'),
      path: `/${i18n.global.locale.value}/catalog/neurosurgery/gel_protivospaechny`
    }]);
    return {
      neurosurgeryLinks
    };
  }

};