import { computed, ref } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const tableData = ref([{
      first: '36170-00001'
    }]);
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.spinalHooksLinks.connectionHook'),
      subtitle: "",
      image: require('@/assets/neurosurgery/connection-hook.png'),
      productsParams: []
    }]);
    return {
      cardData,
      tableData
    };
  }

};