import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.title1')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.text2'),
      title: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.title2')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.text3')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.text4')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.text5')
    }]);
    const inhaler403AData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.links.inhaler403A'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/inhalersNebulizers/inhaler-403A.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.dataLeft1'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.dataRight1')
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.dataLeft2'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.dataRight2')
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.dataLeft3'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.dataRight3')
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.dataLeft4'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.dataRight4')
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.dataLeft5'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.dataRight5')
      }, {
        id: 6,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.dataLeft6'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.dataRight6')
      }, {
        id: 7,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.dataLeft7'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.inhaler403A.dataRight7')
      }]
    }]);
    return {
      inhaler403AData,
      textData
    };
  }

};