import { computed } from 'vue';
export default {
  setup() {
    const cardData = computed(() => [{
      title: 'Раневі покриття',
      image: require('@/assets/surgery/wound-dressings.jpeg')
    }]);
    return {
      cardData
    };
  }

};