import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.title1')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.text2'),
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.title2')
    }, {
      id: 3,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.text3')
    }, {
      id: 4,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.text4')
    }, {
      id: 5,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.text5')
    }, {
      id: 6,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.text6')
    }]);
    const electric7a23dData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.links.electric7a23d'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/equipmentOperatingRooms/electric-7a23d.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataLeft1'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataRight1')
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataLeft2'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataRight2')
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataLeft3'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataRight3')
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataLeft4'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataRight4')
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataLeft5'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataRight5')
      }, {
        id: 6,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataLeft6'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataRight6')
      }, {
        id: 7,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataLeft7'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataRight7')
      }, {
        id: 8,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataLeft8'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataRight8')
      }, {
        id: 9,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataLeft9'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataRight9')
      }, {
        id: 10,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataLeft10'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataRight10')
      }, {
        id: 11,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataLeft11'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataRight11')
      }, {
        id: 12,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataLeft12'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataRight12')
      }, {
        id: 13,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataLeft13'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.electric7a23d.electric7a23dDataRight13')
      }]
    }]);
    return {
      electric7a23dData,
      textData
    };
  }

};