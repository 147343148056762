import { computed } from 'vue';
import i18n from '@/i18n';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
export default {
  components: {
    Swiper,
    SwiperSlide
  },

  setup() {
    const traumatologyCarts = computed(() => [{
      title: 'Невеликі пластини',
      img: require('@/assets/traumatology/small-plates.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/ao-plates/small-plates`
    }, {
      title: 'Динамічні системи стегнової кістки та кондилу',
      img: require('@/assets/traumatology/dynamical-hip-systems.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/ao-plates/dynamic-femoral-and-condylar-systems`
    }, {
      title: 'Мініпластини',
      img: require('@/assets/traumatology/mini-plates-card-img.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/ao-plates/mini-plates`
    }, {
      title: 'Спеціальні пластини',
      img: require('@/assets/traumatology/special-plates.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/ao-plates/special-plates`
    }, {
      title: 'Великі пластини',
      img: require('@/assets/traumatology/bit-plates.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/ao-screws/special-screws`
    }]);
    return {
      traumatologyCarts,
      modules: [Pagination]
    };
  }

};