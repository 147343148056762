import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-283ec272"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "links"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  key: 0
};
const _hoisted_6 = {
  key: 2
};
const _hoisted_7 = {
  key: 0
};
const _hoisted_8 = {
  key: 3
};
const _hoisted_9 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PrimaryLink = _resolveComponent("PrimaryLink");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_PrimaryLink, {
    path: `/${_ctx.$i18n.locale}/catalog/traumatology/`,
    linkTitle: _ctx.$t('main.certificates.links.traumatology'),
    blueArrow: true,
    arrowPosition: $props.typeLink === 'traumatology' ? null : 'top'
  }, null, 8, ["path", "linkTitle", "arrowPosition"]), $props.typeLink === 'traumatology' ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.linksData, item => {
    return _openBlock(), _createElementBlock("li", {
      key: item,
      class: "link-list"
    }, [Array.isArray(item) ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, itemData => {
      return _openBlock(), _createElementBlock("li", {
        key: itemData,
        class: "link-list"
      }, [_createVNode(_component_PrimaryLink, {
        path: itemData.path,
        linkTitle: itemData.name,
        blueCircle: true,
        linkMargin: 10
      }, null, 8, ["path", "linkTitle"])]);
    }), 128))])) : (_openBlock(), _createBlock(_component_PrimaryLink, {
      key: 1,
      path: item.path,
      linkTitle: item.name,
      blueCircle: true,
      linkMargin: 10
    }, null, 8, ["path", "linkTitle"]))]);
  }), 128))])) : _createCommentVNode("", true), _createVNode(_component_PrimaryLink, {
    linkTitle: _ctx.$t('main.certificates.links.surgery'),
    path: `/${_ctx.$i18n.locale}/catalog/surgery/`,
    blueArrow: true,
    arrowPosition: $props.typeLink === 'surgery' ? null : 'top'
  }, null, 8, ["linkTitle", "path", "arrowPosition"]), $props.typeLink === 'surgery' ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.linksData, item => {
    return _openBlock(), _createElementBlock("li", {
      key: item,
      class: "link-list"
    }, [Array.isArray(item) ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, itemData => {
      return _openBlock(), _createElementBlock("li", {
        key: itemData,
        class: "link-list"
      }, [_createVNode(_component_PrimaryLink, {
        path: itemData.path,
        linkTitle: itemData.name,
        blueCircle: true,
        linkMargin: 10
      }, null, 8, ["path", "linkTitle"])]);
    }), 128))])) : (_openBlock(), _createBlock(_component_PrimaryLink, {
      key: 1,
      path: item.path,
      linkTitle: item.name,
      blueCircle: true,
      linkMargin: 10
    }, null, 8, ["path", "linkTitle"]))]);
  }), 128))])) : _createCommentVNode("", true), _createVNode(_component_PrimaryLink, {
    linkTitle: _ctx.$t('main.certificates.links.neurosurgery'),
    path: `/${_ctx.$i18n.locale}/catalog/neurosurgery/`,
    blueArrow: true,
    arrowPosition: $props.typeLink === 'neurosurgery' ? null : 'top'
  }, null, 8, ["linkTitle", "path", "arrowPosition"]), $props.typeLink === 'neurosurgery' ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.linksData, item => {
    return _openBlock(), _createElementBlock("li", {
      key: item,
      class: "link-list"
    }, [Array.isArray(item) ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, itemData => {
      return _openBlock(), _createElementBlock("li", {
        key: itemData,
        class: "link-list"
      }, [_createVNode(_component_PrimaryLink, {
        path: itemData.path,
        linkTitle: itemData.name,
        blueCircle: true,
        linkMargin: 10
      }, null, 8, ["path", "linkTitle"])]);
    }), 128))])) : (_openBlock(), _createBlock(_component_PrimaryLink, {
      key: 1,
      path: item.path,
      linkTitle: item.name,
      blueCircle: true,
      linkMargin: 10
    }, null, 8, ["path", "linkTitle"]))]);
  }), 128))])) : _createCommentVNode("", true), _createVNode(_component_PrimaryLink, {
    linkTitle: _ctx.$t('main.certificates.links.toolsAndEquipment'),
    path: `/${_ctx.$i18n.locale}/catalog/tools-and-equipment/`,
    blueArrow: true,
    arrowPosition: $props.typeLink === 'toolsAndEquipment' ? null : 'top'
  }, null, 8, ["linkTitle", "path", "arrowPosition"]), $props.typeLink === 'toolsAndEquipment' ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.linksData, item => {
    return _openBlock(), _createElementBlock("li", {
      key: item,
      class: "link-list"
    }, [Array.isArray(item) ? (_openBlock(), _createElementBlock("ul", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, itemData => {
      return _openBlock(), _createElementBlock("li", {
        key: itemData,
        class: "link-list"
      }, [_createVNode(_component_PrimaryLink, {
        path: itemData.path,
        linkTitle: itemData.name,
        blueCircle: true,
        linkMargin: 10
      }, null, 8, ["path", "linkTitle"])]);
    }), 128))])) : (_openBlock(), _createBlock(_component_PrimaryLink, {
      key: 1,
      path: item.path,
      linkTitle: item.name,
      blueCircle: true,
      linkMargin: 10
    }, null, 8, ["path", "linkTitle"]))]);
  }), 128))])) : _createCommentVNode("", true)]);
}