import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const formalinChamberBigData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.formalinChambersLinks.formalinChamberBig'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.formalinChamberBig.title'),
      image: require('@/assets/toolsAndEquipment/formalin-chamber-big.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.formalinChamberBig.formalinChamberBigData1'),
        data: '416'
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.formalinChamberBig.formalinChamberBigData2'),
        data: '524'
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.formalinChamberBig.formalinChamberBigData3'),
        data: '275'
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.formalinChamberBig.formalinChamberBigData4'),
        data: '5'
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.formalinChamberBig.formalinChamberBigData5'),
        data: i18n.global.t('main.carts.toolsAndEquipment.formalinChamberBig.formalinChamberBigData6')
      }]
    }]);
    return {
      formalinChamberBigData
    };
  }

};