import axios from 'axios';
import catalogueRus from '@/assets/pdf/catalogue-rus.pdf';
import newTraumatologyCatalogue from '@/assets/pdf/new-traumatology-catalogue.pdf';
import Catalogue_2009_vostok from '@/assets/pdf/Catalogue_2009_vostok.pdf';
export default {
  name: 'CatalogScreen',
  methods: {
    downloadPDF(url, name) {
      axios.get(url, {
        responseType: 'blob'
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}.pdf`);
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
        console.log(error);
      });
    }

  },

  setup() {
    return {
      catalogueRus,
      newTraumatologyCatalogue,
      Catalogue_2009_vostok
    };
  }

};