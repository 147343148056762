import { computed } from 'vue';
export default {
  name: 'ChancetanScrew',

  setup() {
    const tableData = computed(() => [{
      first: '70',
      second: '20',
      third: '15300-40070',
      fourth: ''
    }, {
      first: '70',
      second: '25',
      third: '15301-40070',
      fourth: ''
    }, {
      first: '80',
      second: '35',
      third: '15302-40070',
      fourth: ''
    }, {
      first: '100',
      second: '40',
      third: '15300-40080',
      fourth: ''
    }, {
      first: '150',
      second: '40',
      third: '15300-40150',
      fourth: '15300-60150'
    }, {
      first: '160',
      second: '70',
      third: '',
      fourth: '15300-60175'
    }, {
      first: '175',
      second: '40',
      third: '',
      fourth: '15300-60250'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/chancetan-screw.jpeg'),
      productsParams: [{
        id: 1,
        name: 'Матеріал',
        data: 'нерж. сталь'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};