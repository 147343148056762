import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-38644f95"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "catalog"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "catalog__inner"
};
const _hoisted_4 = {
  class: "catalog__links"
};
const _hoisted_5 = {
  class: "catalog__block"
};
const _hoisted_6 = {
  class: "catalog__block-title"
};
const _hoisted_7 = {
  class: "catalog__block-btns"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MainHeader = _resolveComponent("MainHeader");

  const _component_PrimaryLink = _resolveComponent("PrimaryLink");

  const _component_el_button = _resolveComponent("el-button");

  const _component_MainFooter = _resolveComponent("MainFooter");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_MainHeader), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_PrimaryLink, {
    linkTitle: _ctx.$t('main.certificates.links.traumatology'),
    path: `/${_ctx.$i18n.locale}/catalog/traumatology`,
    blueArrow: true,
    arrowPosition: "bottom"
  }, null, 8, ["linkTitle", "path"]), _createVNode(_component_PrimaryLink, {
    linkTitle: _ctx.$t('main.catalog.links.surgery'),
    path: `/${_ctx.$i18n.locale}/catalog/surgery`,
    blueArrow: true,
    arrowPosition: "bottom"
  }, null, 8, ["linkTitle", "path"]), _createVNode(_component_PrimaryLink, {
    linkTitle: _ctx.$t('main.catalog.links.neurosurgery'),
    path: `/${_ctx.$i18n.locale}/catalog/neurosurgery`,
    blueArrow: true,
    arrowPosition: "bottom"
  }, null, 8, ["linkTitle", "path"]), _createVNode(_component_PrimaryLink, {
    linkTitle: _ctx.$t('main.catalog.links.toolsAndEquipment'),
    path: `/${_ctx.$i18n.locale}/catalog/tools-and-equipment`,
    blueArrow: true,
    arrowPosition: "bottom"
  }, null, 8, ["linkTitle", "path"])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.$t('main.catalog.block.title')), 1), _createElementVNode("div", _hoisted_7, [_createVNode(_component_el_button, {
    onClick: _cache[0] || (_cache[0] = $event => $options.downloadPDF($setup.catalogueRus, _ctx.$t('main.catalog.block.button-1'))),
    class: "button-download-pdf"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.catalog.block.button-1')), 1)]),
    _: 1
  }), _createVNode(_component_el_button, {
    onClick: _cache[1] || (_cache[1] = $event => $options.downloadPDF($setup.newTraumatologyCatalogue, _ctx.$t('main.catalog.block.button-2'))),
    class: "button-download-pdf"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.catalog.block.button-2')), 1)]),
    _: 1
  }), _createVNode(_component_el_button, {
    onClick: _cache[2] || (_cache[2] = $event => $options.downloadPDF($setup.Catalogue_2009_vostok, _ctx.$t('main.catalog.block.button-3'))),
    class: "button-download-pdf"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.catalog.block.button-3')), 1)]),
    _: 1
  })])])])])]), _createVNode(_component_MainFooter)], 64);
}