import { computed } from 'vue';
export default {
  name: 'KirchenersSpoke',

  setup() {
    const tableData = computed(() => [{
      first: 'Z17.2,0.300',
      second: '300',
      third: '2,0'
    }, {
      first: 'Z17.2.0.400',
      second: '400',
      third: '2,0'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/ilizarovs-spoke-second.jpeg'),
      productsParams: [{
        id: 1,
        name: 'Матеріал',
        data: 'нерж. сталь'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};