import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.medicalCabinetSHM1.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.medicalCabinetSHM1.title1')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.medicalCabinetSHM1.text2'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.medicalCabinetSHM1.title2')
    }]);
    const medicalCabinetSHM1Data = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.medicalCabinetSHM1'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/medical-cabinet-SHM1.png'),
      productsParams: []
    }]);
    return {
      medicalCabinetSHM1Data,
      textData
    };
  }

};