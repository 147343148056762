import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const tableData = computed(() => [{
      first: '110',
      second: '2,5',
      third: '434.110.2.5'
    }, {
      first: '140',
      second: '3,2',
      third: '434.140.3.2'
    }, {
      first: '200',
      second: '3,5',
      third: '430.200.3.5'
    }, {
      first: '170',
      second: '4,5',
      third: '430.170.4.5'
    }, {
      first: '160',
      second: '5,0',
      third: '430.160.5.0'
    }]);
    const secondTableData = computed(() => [{
      first: '3,5',
      second: '38	',
      third: '600.00.3.5'
    }, {
      first: '4,5',
      second: '38',
      third: '600.00.4.5'
    }, {
      first: '5,0',
      second: '38',
      third: '600.01.5.0'
    }, {
      first: '6,5',
      second: '38',
      third: '600.02.6.5'
    }]);
    const thirdTableData = computed(() => [{
      first: '2,5',
      second: '160',
      third: '01.03.2.5'
    }, {
      first: '3,5',
      second: '160',
      third: '01.03.3.5'
    }, {
      first: '4,0',
      second: '---',
      third: '01.03.4.5'
    }]);
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.traumatology.toolTraumatological.title'),
      image: require('@/assets/traumatology/tool-traumatology.jpeg')
    }]);
    return {
      cardData,
      tableData,
      secondTableData,
      thirdTableData
    };
  }

};