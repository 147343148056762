import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductTableHeader = _resolveComponent("ProductTableHeader");

  const _component_el_table_column = _resolveComponent("el-table-column");

  const _component_el_table = _resolveComponent("el-table");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_ProductTableHeader, {
    title: "Реконструктивна пластина 8х2,7 мм",
    productsParams: $setup.cardData[0].productsParams,
    image: $setup.cardData[0].imageAddition
  }, null, 8, ["productsParams", "image"]), _createVNode(_component_el_table, {
    border: "",
    style: {
      "width": "100%",
      "margin-bottom": "20px"
    },
    "header-cell-style": {
      background: '#00AEEF',
      color: '#FFF'
    },
    "cell-style": {
      padding: '0',
      height: '50px',
      color: '#1B1C1E'
    },
    data: $setup.tableData,
    "scrollbar-always-on": true
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      label: "Кількість отворів",
      prop: "first",
      "min-width": "140px"
    }), _createVNode(_component_el_table_column, {
      label: "L, мм",
      prop: "second",
      "min-width": "140px"
    }), _createVNode(_component_el_table_column, {
      label: "Кат. № нерж. сталь",
      prop: "third",
      "min-width": "140px"
    }), _createVNode(_component_el_table_column, {
      label: "Кат. № сплав титану",
      prop: "fourth",
      "min-width": "140px"
    })]),
    _: 1
  }, 8, ["data"])], 64);
}