import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const bactericidalIrradiator2Lamp150MData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.bactericidalIrradiatorsLinks.bactericidalIrradiator2Lamp150M'),
      subtitle: '',
      image: require('@/assets/toolsAndEquipment/bactericida-iIrradiator-2Lamp150M.png'),
      productsParams: []
    }]);
    return {
      bactericidalIrradiator2Lamp150MData
    };
  }

};