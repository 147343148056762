import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.proceduralCocuhTable.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.proceduralCocuhTable.title1')
    }]);
    const proceduralCocuhTableData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.proceduralCocuhTable'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/procedural-cocuh-table.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.proceduralCocuhTable.dataLeft1'),
        data: "600"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.proceduralCocuhTable.dataLeft2'),
        data: "800"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.proceduralCocuhTable.dataLeft3'),
        data: "2000"
      }]
    }]);
    return {
      proceduralCocuhTableData,
      textData
    };
  }

};