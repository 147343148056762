import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.title1')
    }]);
    const luminaireL739IIData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.links.luminaireL739II'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.subtitle'),
      image: require('@/assets/toolsAndEquipment/equipmentOperatingRooms/luminaire-L739II.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataLeft1'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataRight1')
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataLeft2'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataRight2')
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataLeft3'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataRight3')
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataLeft4'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataRight4')
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataLeft5'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataRight5')
      }, {
        id: 6,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataLeft6'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataRight6')
      }, {
        id: 7,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataLeft7'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataRight7')
      }, {
        id: 8,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataLeft8'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataRight8')
      }, {
        id: 9,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataLeft9'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataRight9')
      }, {
        id: 10,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataLeft10'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataRight10')
      }, {
        id: 11,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataLeft11'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataRight11')
      }, {
        id: 12,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataLeft12'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.luminaireL739II.dataRight12')
      }]
    }]);
    return {
      luminaireL739IIData,
      textData
    };
  }

};