import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const textData = computed(() => []);
    const surgicalTableSH1Data = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.surgicalTableSH1'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/surgical-table-SH1.png'),
      productsParams: []
    }]);
    return {
      surgicalTableSH1Data,
      textData
    };
  }

};