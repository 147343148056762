import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const operatingTablesCarts = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.links.electricTableDS1'),
      img: require('@/assets/toolsAndEquipment/equipmentOperatingRooms/electric-table-DS1.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/operational-equipment/operating-tables/stol-operatsionnyi-elektricheskii-ds-1`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.links.electricTableDS3'),
      img: require('@/assets/toolsAndEquipment/equipmentOperatingRooms/electric-table-DS3.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/operational-equipment/operating-tables/stol-operatsionnyi-elektricheskii-ds-3`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.links.tableHydrDriven3008A'),
      img: require('@/assets/toolsAndEquipment/equipmentOperatingRooms/table-hydr-driven-3008A.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/operational-equipment/operating-tables/stol-operatsionnyi-universalnyi-s-gidravlicheskim-privodom-3008-a`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.links.operatingTable3008s'),
      img: require('@/assets/toolsAndEquipment/equipmentOperatingRooms/operating-table-3008s.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/operational-equipment/operating-tables/stol-operatsionnyi-universalnyi-s-gidravlicheskim-privodom-3008-s`
    }, {
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.links.operatingTable3006'),
      img: require('@/assets/toolsAndEquipment/equipmentOperatingRooms/operating-table-3006.png'),
      link: `/${i18n.global.locale.value}/catalog/tools-and-equipment/operational-equipment/operating-tables/stol-operatsionnyi-universalnyi-s-gidravlicheskim-privodom-peredvizhnoi-3006`
    }]);
    return {
      operatingTablesCarts
    };
  }

};