import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/svg/blue-arrow.svg';

const _withScopeId = n => (_pushScopeId("data-v-7ff312ba"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["href"];
const _hoisted_2 = {
  key: 0,
  class: "blue-circle"
};
const _hoisted_3 = {
  key: 1,
  class: "white-circle"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("a", {
    style: _normalizeStyle(`margin-top: ${$props.linkMargin}px; margin-bottom: ${$props.linkMargin}px`),
    class: "primary-link",
    href: $props.path
  }, [$props.blueCircle ? (_openBlock(), _createElementBlock("div", _hoisted_2)) : _createCommentVNode("", true), $props.whiteCircle ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : _createCommentVNode("", true), _createElementVNode("div", {
    style: _normalizeStyle(`font-size: ${$props.linkSize}px`),
    class: "primary-link__link"
  }, _toDisplayString($props.linkTitle), 5), $props.blueArrow ? (_openBlock(), _createElementBlock("img", {
    key: 2,
    class: _normalizeClass(`primary-link__img_${$props.arrowPosition}`),
    src: _imports_0,
    alt: "blue arrow"
  }, null, 2)) : _createCommentVNode("", true)], 12, _hoisted_1);
}