export default {
  props: {
    linksData: {
      type: Array,
      default: () => []
    },
    typeLink: {
      type: String,
      default: ''
    }
  },

  setup() {
    return {};
  }

};