import { computed } from 'vue';
export default {
  name: 'CerclageBracket',

  setup() {
    const tableData = computed(() => [{
      first: '68х42',
      second: '15860-68042'
    }, {
      first: '48х36',
      second: '15860-48036'
    }, {
      first: '36х30',
      second: '15860-36030'
    }, {
      first: '24х22',
      second: '15860-24022'
    }, {
      first: '15х15',
      second: '15860-15015'
    }, {
      first: '20х20',
      second: '15860-20020'
    }, {
      first: '24х42',
      second: '15860-24042'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/bracket.jpeg'),
      productsParams: [{
        id: 4,
        name: 'Матеріал',
        data: 'нерж. сталь'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};