import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const tableData = computed(() => [{
      first: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.airSterilizerGpData1'),
      second: '460х700х500',
      third: '460х700х500'
    }, {
      first: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.airSterilizerGpData2'),
      second: '80…200',
      third: '80…200'
    }, {
      first: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.airSterilizerGpData3'),
      second: '1...999',
      third: '1...999'
    }, {
      first: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.airSterilizerGpData4'),
      second: '30',
      third: '30'
    }, {
      first: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.airSterilizerGpData5'),
      second: '±3',
      third: '±3'
    }, {
      first: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.airSterilizerGpData6'),
      second: '380',
      third: '380'
    }, {
      first: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.airSterilizerGpData7'),
      second: '6',
      third: '6'
    }, {
      first: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.airSterilizerGpData8'),
      second: '860*1400*650',
      third: '860*1400*650'
    }, {
      first: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.airSterilizerGpData9'),
      second: '100',
      third: '130'
    }, {
      first: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.airSterilizerGpData10'),
      second: '3',
      third: '3'
    }, {
      first: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerGP.airSterilizerGpData11'),
      second: 'one-side',
      third: 'one-side'
    }]);
    const AirSterilizerBdData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.airSterilizerLinks.airSterilizerGP'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/air-sterilizer-GP.png'),
      productsParams: []
    }]);
    return {
      AirSterilizerBdData,
      tableData
    };
  }

};