import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "default_mobile__card"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductInfoCardMobile = _resolveComponent("ProductInfoCardMobile");

  const _component_DynamicalFemoralSystemsComponent = _resolveComponent("DynamicalFemoralSystemsComponent");

  const _component_FemoralScrewPlate135 = _resolveComponent("FemoralScrewPlate135");

  const _component_CondylarScrewPlate95 = _resolveComponent("CondylarScrewPlate95");

  const _component_FemoralScrewPlate135LimitedContact = _resolveComponent("FemoralScrewPlate135LimitedContact");

  const _component_CondylarScrewPlate95LimitedContact = _resolveComponent("CondylarScrewPlate95LimitedContact");

  const _component_FemoralScrewPlateAngleResistance = _resolveComponent("FemoralScrewPlateAngleResistance");

  const _component_CondylarScrewPlateAngleResistance = _resolveComponent("CondylarScrewPlateAngleResistance");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ProductInfoCardMobile, {
      image: $setup.cardData[0].image,
      title: $setup.cardData[0].title,
      subtitle: $setup.cardData[0].subtitle,
      productsParams: $setup.cardData[0].productsParams
    }, null, 8, ["image", "title", "subtitle", "productsParams"]), _createVNode(_component_DynamicalFemoralSystemsComponent), _createVNode(_component_FemoralScrewPlate135), _createVNode(_component_CondylarScrewPlate95), _createVNode(_component_FemoralScrewPlate135LimitedContact), _createVNode(_component_CondylarScrewPlate95LimitedContact), _createVNode(_component_FemoralScrewPlateAngleResistance), _createVNode(_component_CondylarScrewPlateAngleResistance)])]),
    _: 1
  });
}