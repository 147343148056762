import { computed } from 'vue';
import i18n from '@/i18n';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
export default {
  components: {
    Swiper,
    SwiperSlide
  },

  setup() {
    const traumatologyCarts = computed(() => [{
      title: i18n.global.t('main.carts.traumatology.links.intramedullaryPins'),
      img: require('@/assets/traumatology/intramedullary-pins.png'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/intramedullary-pins`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.blockedPlates'),
      img: require('@/assets/traumatology/blocked-plates.png'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/blocked-plates`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.tool'),
      img: require('@/assets/traumatology/tools.png'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/tools`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.screwsAT'),
      img: require('@/assets/traumatology/screews-at.png'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/ao-screws`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.JSCPlates'),
      img: require('@/assets/traumatology/plates-at.png'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/ao-plates`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.needlesRodsCerclage'),
      img: require('@/assets/traumatology/needles-etc.png'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/needles-robs-cerclage`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.microsurgery'),
      img: require('@/assets/traumatology/microsurgery.png'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/micro-surgery`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.ilizarovsApparatus'),
      img: require('@/assets/traumatology/ilizarovs-apparatus.png'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/ilizarovs-apparatus`
    }]);
    return {
      traumatologyCarts,
      modules: [Pagination]
    };
  }

};