import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  name: 'IntramedullaryPins',

  setup() {
    const traumatologyCarts = computed(() => [{
      title: i18n.global.t('main.carts.traumatology.links.smallInsertsWithAngularStability'),
      img: require('@/assets/traumatology/small-inserts-with-angular-stability.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/blockedPlates/small-inserts-with-angular-stability`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.specialInsertsWithAngularStability'),
      img: require('@/assets/traumatology/special-inserts-with-angular-stability.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/blockedPlates/special-inserts-with-angular-stability`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.angularStabilityInserts'),
      img: require('@/assets/traumatology/angular-stability-inserts.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/blockedPlates/inserts-with-angular-stability`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.largeInsertsWithAngularStability'),
      img: require('@/assets/traumatology/large-inserts-with-angular-stability.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/blockedPlates/large-inserts-with-angular-stability`
    }]);
    return {
      traumatologyCarts
    };
  }

};