import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.adjustableMassegeCouch.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.adjustableMassegeCouch.title1')
    }]);
    const adjustableMassegeCouchData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.adjustableMassegeCouch'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/adjustable-massege-couch.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.adjustableMassegeCouch.dataLeft1'),
        data: "120"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.adjustableMassegeCouch.dataLeft2'),
        data: "695 - 800"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.adjustableMassegeCouch.dataLeft3'),
        data: "1960 х 610 х 45"
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.adjustableMassegeCouch.dataLeft4'),
        data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.adjustableMassegeCouch.dataRight4')
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.adjustableMassegeCouch.dataLeft5'),
        data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.adjustableMassegeCouch.dataRight5')
      }, {
        id: 6,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.adjustableMassegeCouch.dataLeft6'),
        data: "45"
      }]
    }]);
    return {
      adjustableMassegeCouchData,
      textData
    };
  }

};