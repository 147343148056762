import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "default_mobile__card"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductInfoCardMobile = _resolveComponent("ProductInfoCardMobile");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ProductInfoCardMobile, {
      image: $setup.gynecologicalChairKG1MData[0].image,
      title: $setup.gynecologicalChairKG1MData[0].title,
      subtitle: $setup.gynecologicalChairKG1MData[0].subtitle,
      productsParams: $setup.gynecologicalChairKG1MData[0].productsParams
    }, null, 8, ["image", "title", "subtitle", "productsParams"])])]),
    _: 1
  });
}