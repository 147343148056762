import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "catalog_mobile__links"
};
const _hoisted_2 = {
  class: "catalog_mobile__inner-links"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ButtonLinks = _resolveComponent("ButtonLinks");

  const _component_el_button = _resolveComponent("el-button");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ButtonLinks)]), _createElementVNode("div", _hoisted_2, [_createElementVNode("h2", null, _toDisplayString(_ctx.$t('main.catalog.block.title')), 1), _createVNode(_component_el_button, {
      class: "button-download-pdf-mobile"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.catalog.block.button-1')), 1)]),
      _: 1
    }), _createVNode(_component_el_button, {
      class: "button-download-pdf-mobile"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.catalog.block.button-3')), 1)]),
      _: 1
    }), _createVNode(_component_el_button, {
      class: "button-download-pdf-mobile"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.catalog.block.button-3')), 1)]),
      _: 1
    })])]),
    _: 1
  });
}