import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const UvCameraLargeData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.panmedCamersLinks.uvCameraLarge'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.panmedCamers.uvCameraLargeData'),
      image: require('@/assets/toolsAndEquipment/uv-camera-large.png'),
      productsParams: []
    }]);
    return {
      UvCameraLargeData
    };
  }

};