import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const gynecologicalChairKG1MData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.gynecologicalChairKG1M'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairKG1M.subtitle'),
      image: require('@/assets/toolsAndEquipment/medicalFurniture/gynecological-chair-KG1M.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairKG1M.dataLeft1'),
        data: "0-90"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairKG1M.dataLeft2'),
        data: "0-25"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.gynecologicalChairKG1M.dataLeft3'),
        data: "200"
      }]
    }]);
    return {
      gynecologicalChairKG1MData
    };
  }

};