import { computed, ref } from 'vue';
import i18n from '@/i18n';
export default {
  name: 'polyaxialScrew',

  setup() {
    const tableData = ref([{
      first: '30',
      second: '36110-45030',
      third: '36110-55030',
      fourth: '',
      fifth: ''
    }, {
      first: '35',
      second: '36110-45035',
      third: '36110-55035',
      fourth: '36110-65035',
      fifth: ''
    }, {
      first: '40',
      second: '36110-45040',
      third: '36110-55040',
      fourth: '36110-65040',
      fifth: '36110-75040'
    }, {
      first: '45',
      second: '',
      third: '36110-55045',
      fourth: '36110-65045',
      fifth: '36110-75045'
    }, {
      first: '50',
      second: '',
      third: '36110-55050',
      fourth: '36110-65050',
      fifth: ''
    }, {
      first: '55',
      second: '',
      third: '',
      fourth: '36110-65055',
      fifth: ''
    }]);
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.spinalScrewsLinks.polyaxialScrew'),
      subtitle: "",
      image: require('@/assets/neurosurgery/polyaxial-screw.png'),
      productsParams: []
    }]);
    return {
      cardData,
      tableData
    };
  }

};