import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const inhaler403AData = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalInstrument.links.INGELARGEPATTERN'),
      subtitle: "",
      image: require('@/assets/neurosurgery/INGE-LARGE-PATTERN.png'),
      productsParams: []
    }]);
    return {
      inhaler403AData
    };
  }

};