import { computed } from 'vue';
export default {
  name: 'ConicalScrew',

  setup() {
    const tableData = computed(() => [{
      first: '1.29.110',
      second: '110',
      third: '45'
    }, {
      first: '1.29.120',
      second: '120',
      third: '45'
    }, {
      first: '1.29.140',
      second: '140',
      third: '50'
    }, {
      first: '1.29.160',
      second: '160',
      third: '60'
    }, {
      first: '1.29.180',
      second: '180',
      third: '65'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/connical-screw.jpeg'),
      productsParams: [{
        id: 1,
        name: 'Матеріал',
        data: 'сплав титану'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};