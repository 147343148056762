import { computed } from 'vue';
import i18n from '@/i18n';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
export default {
  components: {
    Swiper,
    SwiperSlide
  },

  setup() {
    const traumatologyCarts = computed(() => [{
      title: 'Серкляж',
      img: require('@/assets/traumatology/cerclage.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/needles-robs-cerclage/cerclage`
    }, {
      title: 'Спиці',
      img: require('@/assets/traumatology/needles.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/needles-robs-cerclage/needles`
    }, {
      title: 'Стержні',
      img: require('@/assets/traumatology/robs.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/needles-robs-cerclage/robs`
    }]);
    return {
      traumatologyCarts,
      modules: [Pagination]
    };
  }

};