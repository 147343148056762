import { computed } from 'vue';
export default {
  setup() {
    const cardData = computed(() => [{
      title: 'Эндопротезы для урогинекологии',
      image: require('@/assets/surgery/antiadhesions.jpeg')
    }]);
    return {
      cardData
    };
  }

};