import { computed } from 'vue';
export default {
  name: 'ShoulderPlateThreadResistant',

  setup() {
    const tableData = computed(() => [{
      first: '4  4',
      second: '90',
      third: ' 13340-08504',
      fourth: '33340-08504'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/shoulder-t-shaped.jpeg'),
      productsParams: [{
        id: 1,
        name: 'Товщина',
        data: '2,0 мм'
      }, {
        id: 2,
        name: 'Ширина',
        data: '1,2 мм'
      }, {
        id: 3,
        name: 'Діаметр гвинта',
        data: '4,5 мм'
      }, {
        id: 8,
        name: 'Матеріал',
        data: 'Нерж. сталь або сплав титану'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};