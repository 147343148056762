import { computed } from 'vue';
export default {
  name: 'ProductInfoCard',
  props: {
    image: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    productsParams: {
      type: Array,
      default: () => []
    },
    table: {
      type: Array,
      default: () => []
    }
  },

  setup() {
    const tableData = computed(() => this.table);
    return {
      tableData
    };
  }

};