import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const screwChairWithoutBackrestData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.screwChairWithoutBackrest'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.screwChairWithoutBackrest.subtitle'),
      image: require('@/assets/toolsAndEquipment/medicalFurniture/screw-chair-without-backrest.png'),
      productsParams: []
    }]);
    return {
      screwChairWithoutBackrestData
    };
  }

};