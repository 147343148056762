export default {
  name: 'PrimaryLink',
  props: {
    path: {
      type: String,
      default: '#'
    },
    arrowPosition: {
      type: String,
      default: 'right'
    },
    blueCircle: {
      type: Boolean,
      default: false
    },
    whiteCircle: {
      type: Boolean,
      default: false
    },
    linkTitle: {
      type: String,
      default: ''
    },
    blueArrow: {
      type: Boolean,
      default: false
    },
    linkSize: {
      type: Number,
      default: 18
    },
    linkMargin: {
      type: Number,
      default: 10
    }
  },

  setup() {
    return {};
  }

};