import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const toolsAndEquipmentLinks = computed(() => [{
      name: i18n.global.t('main.carts.toolsAndEquipment.links.sterilization'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization`
    }, [{
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.panmedCamers'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/camera-panmed/`
    }, {
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.airSterilizer'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/air-sterilizer/`
    }, {
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.electricSterilizers'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/electric-sterilizer/`
    }, {
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.sterilizationBoxes'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/boxes-sterilization-round/`
    }, {
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.formalinChambers'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/camera-paroformalinovaya/`
    }, {
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.bactericidalIrradiators'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/sterilization/reflectors-bactericidal/`
    }], {
      name: i18n.global.t('main.carts.toolsAndEquipment.links.inhalersNebulizers'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/inhalers-nebulizers`
    }, {
      name: i18n.global.t('main.carts.toolsAndEquipment.links.equipmentForOperatingRooms'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/operational-equipment`
    }, [{
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.suctionUnits'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/operational-equipment/suction/`
    }, {
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.coagulators'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/operational-equipment/koagulyatory/`
    }, {
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.lights'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/operational-equipment/fixtures/`
    }, {
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.operatingTables'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/operational-equipment/operating-tables/`
    }], {
      name: i18n.global.t('main.carts.toolsAndEquipment.links.medicalFurniture'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/medical-furniture`
    }, [{
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.medicalCabinets'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/medical-furniture/medicine-cabinets`
    }, {
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.surgicalTables'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/medical-furniture/table-surgical/`
    }, {
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.chairs'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/medical-furniture/couches/`
    }, {
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.medicalStretcher'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/medical-furniture/litters-medical/`
    }, {
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.gynecologicalChairs'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/medical-furniture/armchair-gynecological/`
    }], {
      name: i18n.global.t('main.carts.toolsAndEquipment.links.cardiology'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/cardiology`
    }, [{
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.defibrillatorMonitor'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/cardiology/defibrillyator-monitor`
    }, {
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.electrocardiographs'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/electrocardiograph`
    }, {
      name: i18n.global.t('main.carts.toolsAndEquipment.secondlinks.careMonitor'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/cardiology/monitor_resuscitation`
    }], {
      name: i18n.global.t('main.carts.toolsAndEquipment.links.medicalInstrument'),
      path: `/${i18n.global.locale.value}/catalog/tools-and-equipment/medical-instruments`
    }]);
    return {
      toolsAndEquipmentLinks
    };
  }

};