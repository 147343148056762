import { computed } from 'vue';
export default {
  name: 'MiniPlates',

  setup() {
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/mini-plates.jpeg')
    }]);
    return {
      cardData
    };
  }

};