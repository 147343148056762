import { computed } from 'vue';
import i18n from '@/i18n';
import SpongeScrew4mm from './grid/SpongeScrew4mm.vue';
import SpongeScrew6and5mm from './grid/SpongeScrew6and5mm.vue';
export default {
  name: "SpongeScrews",

  setup() {
    const cardData = computed(() => [{
      title: i18n.global.t("main.carts.traumatology.spongeScrews.title"),
      image: require("@/assets/traumatology/sponge-screws.jpeg")
    }]);
    return {
      cardData
    };
  },

  components: {
    SpongeScrew4mm,
    SpongeScrew6and5mm
  }
};