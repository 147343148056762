import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.changingTableSPL.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.changingTableSPL.title1')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.changingTableSPL.text2'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.changingTableSPL.title2')
    }]);
    const changingTableSPLData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.changingTableSPL'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/changing-table-SPL.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.changingTableSPL.dataLeft1'),
        data: "10"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.changingTableSPL.dataLeft2'),
        data: "900*800*1000"
      }]
    }]);
    return {
      changingTableSPLData,
      textData
    };
  }

};