import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "default_mobile__card"
};
const _hoisted_2 = {
  class: "card"
};
const _hoisted_3 = {
  class: "card__product-info"
};
const _hoisted_4 = {
  class: "card__product-data",
  style: {
    "color": "#000",
    "text-align": "left",
    "padding": "10px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductInfoCardMobile = _resolveComponent("ProductInfoCardMobile");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ProductInfoCardMobile, {
      image: $setup.electricSterilizersSecondData[0].image,
      title: $setup.electricSterilizersSecondData[0].title,
      subtitle: $setup.electricSterilizersSecondData[0].subtitle,
      productsParams: $setup.electricSterilizersSecondData[0].productsParams
    }, null, 8, ["image", "title", "subtitle", "productsParams"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.textData, item => {
      return _openBlock(), _createElementBlock("div", {
        class: "card__product-info-inner",
        key: item.id
      }, [_createElementVNode("div", _hoisted_4, _toDisplayString(item.data), 1)]);
    }), 128))])])])]),
    _: 1
  });
}