import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "default_mobile__card"
};
const _hoisted_2 = {
  class: "card",
  style: {
    "padding": "10px"
  }
};
const _hoisted_3 = {
  class: "card__product-info"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  class: "card__product-title"
};
const _hoisted_6 = {
  class: "blue-txt"
};
const _hoisted_7 = {
  class: "card__product-data",
  style: {
    "color": "#000",
    "text-align": "left"
  }
};
const _hoisted_8 = {
  key: 1
};
const _hoisted_9 = {
  class: "card__product-title"
};
const _hoisted_10 = {
  class: "blue-txt"
};
const _hoisted_11 = {
  class: "card__product-data",
  style: {
    "color": "#000",
    "text-align": "left"
  }
};
const _hoisted_12 = {
  key: 2
};
const _hoisted_13 = {
  class: "card__product-data",
  style: {
    "color": "#000",
    "text-align": "left"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductInfoCardMobile = _resolveComponent("ProductInfoCardMobile");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ProductInfoCardMobile, {
      image: $setup.medicalCabinetSHM1Data[0].image,
      title: $setup.medicalCabinetSHM1Data[0].title,
      subtitle: $setup.medicalCabinetSHM1Data[0].subtitle,
      productsParams: $setup.medicalCabinetSHM1Data[0].productsParams
    }, null, 8, ["image", "title", "subtitle", "productsParams"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.textData, item => {
      return _openBlock(), _createElementBlock("div", {
        class: "card__product-info-inner",
        key: item.id
      }, [item.id === 1 ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_5, [_createElementVNode("h3", _hoisted_6, _toDisplayString(item.title), 1)]), _createElementVNode("div", _hoisted_7, _toDisplayString(item.data), 1)])])) : item.id === 2 ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_9, [_createElementVNode("h3", _hoisted_10, _toDisplayString(item.title), 1)]), _createElementVNode("div", _hoisted_11, _toDisplayString(item.data), 1)])])) : (_openBlock(), _createElementBlock("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, _toDisplayString(item.data), 1)]))]);
    }), 128))])])])]),
    _: 1
  });
}