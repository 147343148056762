import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const screwChairWithBackrestData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.screwChairWithBackrest'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.screwChairWithBackrest.subtitle'),
      image: require('@/assets/toolsAndEquipment/medicalFurniture/screw-chair-with-backrest.png'),
      productsParams: []
    }]);
    return {
      screwChairWithBackrestData
    };
  }

};