import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.powerDrivenMessageCouch.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.powerDrivenMessageCouch.title1')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.powerDrivenMessageCouch.text2')
    }, {
      id: 3,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.powerDrivenMessageCouch.text3')
    }, {
      id: 4,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.powerDrivenMessageCouch.text4')
    }, {
      id: 5,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.powerDrivenMessageCouch.text5')
    }, {
      id: 6,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.powerDrivenMessageCouch.text6')
    }, {
      id: 7,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.powerDrivenMessageCouch.text7')
    }, {
      id: 8,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.powerDrivenMessageCouch.text8')
    }]);
    const powerDrivenMessageCouchData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.powerDrivenMessageCouch'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/powerdriven-message-couch.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.powerDrivenMessageCouch.dataLeft1'),
        data: "700"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.powerDrivenMessageCouch.dataLeft2'),
        data: "580 - 800"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.powerDrivenMessageCouch.dataLeft3'),
        data: "190"
      }]
    }]);
    return {
      powerDrivenMessageCouchData,
      textData
    };
  }

};