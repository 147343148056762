import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const neurosurgeryCarts = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.links.transpedicularFixationOfTheSpine'),
      img: require('@/assets/neurosurgery/transpedicular-fixation-ofthespine.png'),
      link: `/${i18n.global.locale.value}/catalog/neurosurgery/transpedikulyarnaya-fiksatsiya-pozvonochnika`
    }, {
      title: i18n.global.t('main.carts.neurosurgery.links.neurosurgicalPlates'),
      img: require('@/assets/neurosurgery/neurosurgical-plates.png'),
      link: `/${i18n.global.locale.value}/catalog/neurosurgery/plates-neurosurgical`
    }, {
      title: i18n.global.t('main.carts.neurosurgery.links.vertebralEndoprostheses'),
      img: require('@/assets/neurosurgery/vertebral-endoprostheses.png'),
      link: `/${i18n.global.locale.value}/catalog/neurosurgery/vertebrae-implants`
    }, {
      title: i18n.global.t('main.carts.neurosurgery.links.neurosurgicalInstrument'),
      img: require('@/assets/neurosurgery/neurosurgica-iInstrument.png'),
      link: `/${i18n.global.locale.value}/catalog/neurosurgery/neurosurgical-tool`
    }, {
      title: i18n.global.t('main.carts.neurosurgery.links.antiAdhesionGel'),
      img: require('@/assets/neurosurgery/anti-adhesion-gel.png'),
      link: `/${i18n.global.locale.value}/catalog/neurosurgery/gel_protivospaechny`
    }]);
    return {
      neurosurgeryCarts
    };
  }

};