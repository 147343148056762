import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const spinalSystemsCarts = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.spinalSystemsLinks.sacredBlock'),
      img: require('@/assets/neurosurgery/sacred-block.png'),
      link: `/${i18n.global.locale.value}/catalog/neurosurgery/spinal-system/sakralnyi-blok`
    }, {
      title: i18n.global.t('main.carts.neurosurgery.spinalSystemsLinks.sacralScrew'),
      img: require('@/assets/neurosurgery/sacral-screw.png'),
      link: `/${i18n.global.locale.value}/catalog/neurosurgery/spinal-system/sakralnyi-vint`
    }]);
    return {
      spinalSystemsCarts
    };
  }

};