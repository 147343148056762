import { computed } from 'vue';
import i18n from '@/i18n';
import SmallLockingSelfCompressingPlateGrid from './grid/SmallLockingSelfCompressingPlateGrid.vue';
import SmallLockingLowContactSelfCompressingPlateGrid from './grid/SmallLockingLowContactSelfCompressingPlateGrid.vue';
import LockingSmallTPlateGrid from '../special-inserts-with-angular-stability/grid/LockingSmallTPlateGrid.vue';
import LockingSmallTPlateObliqueGrid from '../special-inserts-with-angular-stability/grid/LockingSmallTPlateObliqueGrid.vue';
import LockingReconstructionPlateGrid from './grid/LockingReconstructionPlateGrid.vue';
import LockingProximalHumeralPlateGrid from './grid/LockingProximalHumeralPlateGrid.vue';
import LockingDistalMedialHumerusPlateGrid from '../special-inserts-with-angular-stability/grid/LockingDistalMedialHumerusPlateGrid.vue';
import LockingDistalTibiaPlate from './grid/LockingDistalTibiaPlate.vue';
import ThreadStabilizedSmallTPlateGrid from '../special-inserts-with-angular-stability/grid/ThreadStabilizedSmallTPlateGrid.vue';
import ThreadStabilizedSmallTPlateObliqueGrid from '../special-inserts-with-angular-stability/grid/ThreadStabilizedSmallTPlateObliqueGrid.vue';
import LockingTPlateGrid from './grid/LockingTPlateGrid.vue';
import LockingTButtressPlate from './grid/LockingTButtressPlate.vue';
import LockingLButtressPlate from './grid/LockingLButtressPlate.vue';
import LockingProximalTibiaPlateDefaultGrid from './grid/LockingProximalTibiaPlateDefaultGrid.vue';
import LockingDistalMedialTibiaPlateGrid from '../special-inserts-with-angular-stability/grid/LockingDistalMedialTibiaPlateGrid.vue';
import LockingProximalTibiaPlateGrid from '../special-inserts-with-angular-stability/grid/LockingProximalTibiaPlateGrid.vue';
export default {
  name: 'InsertsWithAngularStability',

  setup() {
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.traumatology.insertsWithAngularStability.title'),
      image: require('@/assets/traumatology/angular-stability-inserts.jpeg')
    }]);
    return {
      cardData
    };
  },

  components: {
    SmallLockingSelfCompressingPlateGrid,
    SmallLockingLowContactSelfCompressingPlateGrid,
    LockingSmallTPlateGrid,
    LockingSmallTPlateObliqueGrid,
    LockingReconstructionPlateGrid,
    LockingProximalHumeralPlateGrid,
    LockingDistalMedialHumerusPlateGrid,
    LockingDistalTibiaPlate,
    ThreadStabilizedSmallTPlateGrid,
    ThreadStabilizedSmallTPlateObliqueGrid,
    LockingTPlateGrid,
    LockingTButtressPlate,
    LockingLButtressPlate,
    LockingProximalTibiaPlateDefaultGrid,
    LockingDistalMedialTibiaPlateGrid,
    LockingProximalTibiaPlateGrid
  }
};