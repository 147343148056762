import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const uvCameraMediumData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.panmedCamersLinks.uvCameraMedium'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.panmedCamers.uvCameraMediumData'),
      image: require('@/assets/toolsAndEquipment/uv-camera-medium.png'),
      productsParams: []
    }]);
    return {
      uvCameraMediumData
    };
  }

};