import { computed } from 'vue';
import i18n from '@/i18n';
import StraightPlateGrid from './grid/StraightPlateGrid.vue';
import StraightPlateSecondGrid from './grid/StraightPlateSecondGrid.vue';
import SmallPlateTShapedGrid from './grid/SmallPlateTShapedGrid.vue';
import ReconstructivePlateGrid from './grid/ReconstructivePlateGrid.vue';
import PlateTubularGrid from './grid/PlateTubularGrid.vue';
import ShoulderNeckPlateGrid from './grid/ShoulderNeckPlateGrid.vue';
import HookPlateGrid from './grid/HookPlateGrid.vue';
import MedialCondylarPlate from './grid/MedialCondylarPlate.vue';
import StraightPlateThirdGrid from './grid/StraightPlateThirdGrid.vue';
import StraightPlateFourthGrid from './grid/StraightPlateFourthGrid.vue';
export default {
  name: 'SmallInsertsWithAngularStability',

  setup() {
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.traumatology.smallInsertsWithAngularStability.title'),
      image: require('@/assets/traumatology/small-inserts-with-angular-stability.jpeg')
    }]);
    return {
      cardData
    };
  },

  components: {
    StraightPlateGrid,
    StraightPlateSecondGrid,
    SmallPlateTShapedGrid,
    ReconstructivePlateGrid,
    PlateTubularGrid,
    ShoulderNeckPlateGrid,
    HookPlateGrid,
    MedialCondylarPlate,
    StraightPlateThirdGrid,
    StraightPlateFourthGrid
  }
};