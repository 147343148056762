import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  name: 'IntramedullaryPins',

  setup() {
    const traumatologyCarts = computed(() => [{
      title: i18n.global.t('main.carts.traumatology.links.calfPins'),
      img: require('@/assets/traumatology/calf-pins.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/intramedullary-pins/calf-pin`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.pinsVerticalZType'),
      img: require('@/assets/traumatology/pins-vertical-z-type.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/intramedullary-pins/pins-vertical-z-type`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.thighPins'),
      img: require('@/assets/traumatology/thigh-pins.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/intramedullary-pins/thigh-pins`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.pinsForShoulder'),
      img: require('@/assets/traumatology/pins-for-shoulder.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/intramedullary-pins/shoulder-pins`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.retrogradeFemoralPins'),
      img: require('@/assets/traumatology/retrograde-femoral-pins.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/intramedullary-pins/retrograde-femoral-pins`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.distalFemoralPins'),
      img: require('@/assets/traumatology/distal-femoral-nail.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/intramedullary-pins/distal-femoral-pins`
    }, {
      title: i18n.global.t('main.carts.traumatology.links.hipProximalPins'),
      img: require('@/assets/traumatology/hip-proximal-pins.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/traumatology/intramedullary-pins/hip-proximal-pins`
    }]);
    return {
      traumatologyCarts
    };
  }

};