import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "default_mobile__card"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductInfoCardMobile = _resolveComponent("ProductInfoCardMobile");

  const _component_MiniPlate4x1 = _resolveComponent("MiniPlate4x1");

  const _component_MiniPlate5x1 = _resolveComponent("MiniPlate5x1");

  const _component_MiniPlateFacial = _resolveComponent("MiniPlateFacial");

  const _component_MiniPlateTShaped = _resolveComponent("MiniPlateTShaped");

  const _component_MiniPlateCondyle = _resolveComponent("MiniPlateCondyle");

  const _component_MiniPlateTShapedSecond = _resolveComponent("MiniPlateTShapedSecond");

  const _component_MiniPlateLShaped = _resolveComponent("MiniPlateLShaped");

  const _component_MiniPlateLShapedCurved = _resolveComponent("MiniPlateLShapedCurved");

  const _component_MiniPlateHShaped = _resolveComponent("MiniPlateHShaped");

  const _component_PlateTShapedThird = _resolveComponent("PlateTShapedThird");

  const _component_PlateLShaped = _resolveComponent("PlateLShaped");

  const _component_PlateLShapedSecond = _resolveComponent("PlateLShapedSecond");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ProductInfoCardMobile, {
      image: $setup.cardData[0].image,
      title: $setup.cardData[0].title,
      subtitle: $setup.cardData[0].subtitle,
      productsParams: $setup.cardData[0].productsParams
    }, null, 8, ["image", "title", "subtitle", "productsParams"]), _createVNode(_component_MiniPlate4x1), _createVNode(_component_MiniPlate5x1), _createVNode(_component_MiniPlateFacial), _createVNode(_component_MiniPlateTShaped), _createVNode(_component_MiniPlateCondyle), _createVNode(_component_MiniPlateTShapedSecond), _createVNode(_component_MiniPlateLShaped), _createVNode(_component_MiniPlateLShapedCurved), _createVNode(_component_MiniPlateHShaped), _createVNode(_component_PlateTShapedThird), _createVNode(_component_PlateLShaped), _createVNode(_component_PlateLShapedSecond)])]),
    _: 1
  });
}