import { computed, ref } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const tableData = ref([{
      first: '30',
      second: '36100-45030',
      third: '36100-55030',
      fourth: '',
      fifth: ''
    }, {
      first: '35',
      second: '36100-45035',
      third: '36100-55035',
      fourth: '36100-65035',
      fifth: ''
    }, {
      first: '40',
      second: '36100-45040',
      third: '36100-55040',
      fourth: '36100-65040',
      fifth: '36100-75040'
    }, {
      first: '45',
      second: '',
      third: '36100-55045',
      fourth: '36100-65045',
      fifth: '36100-75045'
    }, {
      first: '50',
      second: '',
      third: '36100-55050',
      fourth: '36100-65050',
      fifth: ''
    }, {
      first: '55',
      second: '',
      third: '',
      fourth: '36100-65055',
      fifth: ''
    }]);
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.spinalScrewsLinks.monoaxialScrew'),
      image: require('@/assets/neurosurgery/monoaxial-screw.png')
    }]);
    return {
      cardData,
      tableData
    };
  }

};