import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const textData = computed(() => []);
    const insrumentTableC5Data = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.insrumentTableC5'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/instrument-table-C5.png'),
      productsParams: []
    }]);
    return {
      insrumentTableC5Data,
      textData
    };
  }

};