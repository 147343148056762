import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "default_mobile__card"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ProductInfoCardMobile = _resolveComponent("ProductInfoCardMobile");

  const _component_StraightPlateAngleStableWide = _resolveComponent("StraightPlateAngleStableWide");

  const _component_StraightPlateAngleStableWideLimitedContact = _resolveComponent("StraightPlateAngleStableWideLimitedContact");

  const _component_ReconstructivePlateAngleStable = _resolveComponent("ReconstructivePlateAngleStable");

  const _component_TShapedPlateAngleStable = _resolveComponent("TShapedPlateAngleStable");

  const _component_SupportPlateTShapedAngleStable = _resolveComponent("SupportPlateTShapedAngleStable");

  const _component_SupportPlateLShapedAngleStable = _resolveComponent("SupportPlateLShapedAngleStable");

  const _component_CalfPlateAngleStable = _resolveComponent("CalfPlateAngleStable");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ProductInfoCardMobile, {
      image: $setup.cardData[0].image,
      title: $setup.cardData[0].title,
      subtitle: $setup.cardData[0].subtitle,
      productsParams: $setup.cardData[0].productsParams
    }, null, 8, ["image", "title", "subtitle", "productsParams"]), _createVNode(_component_StraightPlateAngleStableWide), _createVNode(_component_StraightPlateAngleStableWideLimitedContact), _createVNode(_component_ReconstructivePlateAngleStable), _createVNode(_component_TShapedPlateAngleStable), _createVNode(_component_SupportPlateTShapedAngleStable), _createVNode(_component_SupportPlateLShapedAngleStable), _createVNode(_component_CalfPlateAngleStable)])]),
    _: 1
  });
}