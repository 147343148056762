import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stationaryMessageCouch.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stationaryMessageCouch.title1')
    }]);
    const stationaryMessageCouchData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.stationaryMessageCouch'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/stationary-message-couch.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stationaryMessageCouch.dataLeft1'),
        data: "120"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stationaryMessageCouch.dataLeft2'),
        data: "800"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stationaryMessageCouch.dataLeft3'),
        data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stationaryMessageCouch.dataRight3')
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stationaryMessageCouch.dataLeft4'),
        data: "1960 х 610 х 45"
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stationaryMessageCouch.dataLeft5'),
        data: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stationaryMessageCouch.dataRight5')
      }, {
        id: 6,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stationaryMessageCouch.dataLeft6'),
        data: "45"
      }]
    }]);
    return {
      stationaryMessageCouchData,
      textData
    };
  }

};