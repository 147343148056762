import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const bactericidalIrradiator1LampData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.bactericidalIrradiatorsLinks.bactericidalIrradiator1Lamp'),
      subtitle: '',
      image: require('@/assets/toolsAndEquipment/bactericida-iIrradiator-1Lamp.png'),
      productsParams: []
    }]);
    return {
      bactericidalIrradiator1LampData
    };
  }

};