import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-59019ee8"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "thigh-pins"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "thigh-pins__inner"
};
const _hoisted_4 = {
  class: "thigh-pins__card"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MainHeader = _resolveComponent("MainHeader");

  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");

  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");

  const _component_LeftLinksMenu = _resolveComponent("LeftLinksMenu");

  const _component_ProductInfoCard = _resolveComponent("ProductInfoCard");

  const _component_el_table_column = _resolveComponent("el-table-column");

  const _component_el_table = _resolveComponent("el-table");

  const _component_MainFooter = _resolveComponent("MainFooter");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_MainHeader), _createVNode(_component_el_breadcrumb, {
    class: "breadcrumb",
    separator: "/"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_breadcrumb_item, {
      to: `/${_ctx.$i18n.locale}/catalog/`
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.catalog.block.title')), 1)]),
      _: 1
    }, 8, ["to"]), _createVNode(_component_el_breadcrumb_item, {
      to: `/${_ctx.$i18n.locale}/catalog/neurosurgery/`
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.carts.neurosurgery.title')), 1)]),
      _: 1
    }, 8, ["to"]), _createVNode(_component_el_breadcrumb_item, {
      to: `/${_ctx.$i18n.locale}/catalog/neurosurgery/spinal-hooks/`
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.carts.neurosurgery.secondlinks.spinalHooks')), 1)]),
      _: 1
    }, 8, ["to"]), _createVNode(_component_el_breadcrumb_item, null, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.carts.neurosurgery.spinalHooksLinks.connectionHook')), 1)]),
      _: 1
    })]),
    _: 1
  }), _createElementVNode("div", _hoisted_3, [_createVNode(_component_LeftLinksMenu), _createElementVNode("div", _hoisted_4, [_createVNode(_component_ProductInfoCard, {
    image: $setup.cardData[0].image,
    title: $setup.cardData[0].title,
    subtitle: $setup.cardData[0].subtitle,
    productsParams: $setup.cardData[0].productsParams
  }, null, 8, ["image", "title", "subtitle", "productsParams"]), _createVNode(_component_el_table, {
    "header-cell-style": {
      background: '#00AEEF',
      color: '#FFF'
    },
    "cell-style": {
      padding: '0',
      height: '50px',
      color: '#1B1C1E'
    },
    data: $setup.tableData,
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      label: _ctx.$t('main.carts.neurosurgery.connectionHook.table.first'),
      prop: "first"
    }, null, 8, ["label"])]),
    _: 1
  }, 8, ["data"])])])])]), _createVNode(_component_MainFooter)], 64);
}