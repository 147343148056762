import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const inhaler403AData = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.neurosurgicalInstrument.links.microAlligatorStraight'),
      subtitle: "",
      image: require('@/assets/neurosurgery/micro-alligator-straight.png'),
      productsParams: []
    }]);
    return {
      inhaler403AData
    };
  }

};