import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const newbornBabyCartTNData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.newbornBabyCartTN'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/medicalFurniture/newbornbaby-cart-TN.png'),
      productsParams: []
    }]);
    return {
      newbornBabyCartTNData
    };
  }

};