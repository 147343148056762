import { computed } from 'vue';
import i18n from '@/i18n';
import StraightPlateAngleStableWide from './grid/StraightPlateAngleStableWide.vue';
import StraightPlateAngleStableWideLimitedContact from './grid/StraightPlateAngleStableWideLimitedContact.vue';
import ReconstructivePlateAngleStable from './grid/ReconstructivePlateAngleStable.vue';
import TShapedPlateAngleStable from './grid/TShapedPlateAngleStable.vue';
import SupportPlateTShapedAngleStable from './grid/SupportPlateTShapedAngleStable.vue';
import SupportPlateLShapedAngleStable from './grid/SupportPlateLShapedAngleStable.vue';
import CalfPlateAngleStable from './grid/CalfPlateAngleStable.vue';
export default {
  name: 'LargeInsertsWithAngularStability',

  setup() {
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.traumatology.largeInsertsWithAngularStability.title'),
      image: require('@/assets/traumatology/large-inserts-with-angular-stability.jpeg')
    }]);
    return {
      cardData
    };
  },

  components: {
    StraightPlateAngleStableWide,
    StraightPlateAngleStableWideLimitedContact,
    ReconstructivePlateAngleStable,
    TShapedPlateAngleStable,
    SupportPlateTShapedAngleStable,
    SupportPlateLShapedAngleStable,
    CalfPlateAngleStable
  }
};