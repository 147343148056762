import { computed } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.title1')
    }, {
      id: 2,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.text2'),
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.title2')
    }, {
      id: 3,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.text3')
    }, {
      id: 4,
      data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.text4')
    }]);
    const pedal7cData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.links.pedal7c'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/equipmentOperatingRooms/pedal-7c.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataLeft1'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataRight1')
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataLeft2'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataRight2')
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataLeft3'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataRight3')
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataLeft4'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataRight4')
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataLeft5'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataRight5')
      }, {
        id: 6,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataLeft6'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataRight6')
      }, {
        id: 7,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataLeft7'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataRight7')
      }, {
        id: 8,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataLeft8'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataRight8')
      }, {
        id: 9,
        name: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataLeft9'),
        data: i18n.global.t('main.carts.toolsAndEquipment.equipmentOperatingRooms.pedal7c.pedal7cDataRight9')
      }]
    }]);
    return {
      pedal7cData,
      textData
    };
  }

};