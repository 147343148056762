import { computed, ref } from 'vue';
import i18n from "@/i18n";
export default {
  setup() {
    const tableData = ref([{
      first: '5X7',
      second: '36181-05007'
    }]);
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.neurosurgery.spinalHooksLinks.closedPedicleHook'),
      subtitle: "",
      image: require('@/assets/neurosurgery/closed-pedicle-hook.png'),
      productsParams: []
    }]);
    return {
      cardData,
      tableData
    };
  }

};