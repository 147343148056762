import { MqResponsive } from 'vue3-mq';
import { onMounted } from 'vue';
import { updateBreakpoints } from 'vue3-mq';
export default {
  components: {
    MqResponsive
  },

  setup() {
    onMounted(() => {
      updateBreakpoints({
        preset: 'bootstrap5'
      });
    });
  }

};