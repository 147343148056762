import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-40a98950"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "intro"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  class: "intro__block"
};
const _hoisted_4 = {
  class: "intro__block-inner"
};
const _hoisted_5 = {
  class: "intro__title"
};
const _hoisted_6 = {
  class: "intro__text_blue"
};
const _hoisted_7 = {
  class: "intro__text"
};
const _hoisted_8 = ["href"];
const _hoisted_9 = {
  class: "carts"
};
const _hoisted_10 = {
  class: "cart"
};
const _hoisted_11 = {
  class: "cart"
};
const _hoisted_12 = {
  class: "cart"
};
const _hoisted_13 = {
  class: "cart"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MainHeader = _resolveComponent("MainHeader");

  const _component_el_button = _resolveComponent("el-button");

  const _component_CatalogCart = _resolveComponent("CatalogCart");

  const _component_PrimaryLink = _resolveComponent("PrimaryLink");

  const _component_MainFooter = _resolveComponent("MainFooter");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_MainHeader), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("h2", _hoisted_5, [_createTextVNode(_toDisplayString(_ctx.$t('main.intro.movement')) + " ", 1), _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('main.intro.itsLife')), 1)]), _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('main.intro.text')), 1), _createElementVNode("a", {
    href: `/${_ctx.$i18n.locale}/about`
  }, [_createVNode(_component_el_button, {
    class: "intro__button"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.intro.more')), 1)]),
    _: 1
  })], 8, _hoisted_8)])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createVNode(_component_CatalogCart, {
    title: _ctx.$t('main.carts.traumatology.title'),
    text: _ctx.$t('main.carts.traumatology.text'),
    img: require('@/assets/traumatology-img.png'),
    "img-position": "bottom",
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$router.push({
      name: 'catalog-traumatology'
    }))
  }, null, 8, ["title", "text", "img"]), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.traumatologyLinks, item => {
    return _openBlock(), _createElementBlock("li", {
      key: item.id,
      class: "cart__list"
    }, [_createVNode(_component_PrimaryLink, {
      path: item.path,
      linkTitle: item.title,
      blueCircle: true,
      linkSize: 15,
      linkMargin: 20
    }, null, 8, ["path", "linkTitle"])]);
  }), 128))])]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_CatalogCart, {
    title: _ctx.$t('main.carts.surgery.title'),
    text: _ctx.$t('main.carts.surgery.text'),
    img: require('@/assets/surgery-img.png'),
    "img-position": "bottom",
    onClick: _cache[1] || (_cache[1] = $event => _ctx.$router.push({
      name: 'catalog-surgery'
    }))
  }, null, 8, ["title", "text", "img"]), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.surgeryLinks, item => {
    return _openBlock(), _createElementBlock("li", {
      key: item,
      class: "cart__list"
    }, [_createVNode(_component_PrimaryLink, {
      path: item.path,
      linkTitle: item.title,
      blueCircle: true,
      linkSize: 15,
      linkMargin: 20
    }, null, 8, ["path", "linkTitle"])]);
  }), 128))])]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_CatalogCart, {
    title: _ctx.$t('main.carts.neurosurgery.title'),
    text: _ctx.$t('main.carts.neurosurgery.text'),
    img: require('@/assets/neurosurgery-img.png'),
    "img-position": "bottom",
    onClick: _cache[2] || (_cache[2] = $event => _ctx.$router.push({
      name: 'catalog-neurosurgery'
    }))
  }, null, 8, ["title", "text", "img"]), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.neurosurgeryLinks, item => {
    return _openBlock(), _createElementBlock("li", {
      key: item,
      class: "cart__list"
    }, [_createVNode(_component_PrimaryLink, {
      path: `/${_ctx.$i18n.locale}/catalog/neurosurgery/`,
      linkTitle: item,
      blueCircle: true,
      linkSize: 15,
      linkMargin: 20
    }, null, 8, ["path", "linkTitle"])]);
  }), 128))])]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_CatalogCart, {
    title: _ctx.$t('main.carts.toolsAndEquipment.title'),
    text: _ctx.$t('main.carts.toolsAndEquipment.text'),
    img: require('@/assets/tools-and-equipment-img.png'),
    "img-position": "top",
    onClick: _cache[3] || (_cache[3] = $event => _ctx.$router.push({
      name: 'catalog-tools-and-equipment'
    }))
  }, null, 8, ["title", "text", "img"]), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.toolsAndEquipmentLinks, item => {
    return _openBlock(), _createElementBlock("li", {
      key: item,
      class: "cart__list"
    }, [_createVNode(_component_PrimaryLink, {
      path: `/${_ctx.$i18n.locale}/catalog/tools-and-equipment/`,
      linkTitle: item,
      blueCircle: true,
      linkSize: 15,
      linkMargin: 20
    }, null, 8, ["path", "linkTitle"])]);
  }), 128))])])])])]), _createVNode(_component_MainFooter)], 64);
}