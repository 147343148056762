import { computed } from 'vue';
export default {
  name: 'PlateLShapedSecond',

  setup() {
    const tableData = computed(() => [{
      first: 'Ліва',
      second: '13160-03423',
      third: '33150-03423'
    }, {
      first: 'Права',
      second: '13161-03423',
      third: '33151-03423'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/plate-l-shaped-second.jpeg'),
      productsParams: [{
        id: 1,
        name: 'Товщина',
        data: '1,2 мм'
      }, {
        id: 2,
        name: 'Ширина',
        data: '7,0 мм'
      }, {
        id: 3,
        name: 'Діаметр гвинта',
        data: '2,7 мм'
      }, {
        id: 4,
        name: 'Матеріал',
        data: 'нерж. сталь або сплав титану'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};