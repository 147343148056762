import { computed } from 'vue';
import i18n from '@/i18n';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css';
export default {
  components: {
    Swiper,
    SwiperSlide
  },

  setup() {
    const surgeryCarts = computed(() => [{
      title: i18n.global.t('main.carts.surgery.links.sutureMaterials'),
      img: require('@/assets/surgery/suture-materials.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/suture-materials`
    }, {
      title: i18n.global.t('main.carts.surgery.links.endoprosthesesPolymerNets'),
      img: require('@/assets/surgery/endoprostheses.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/endoprostheses-polymer-meshes`
    }, {
      title: i18n.global.t('main.carts.surgery.links.endoprosthesesForUrogynecology'),
      img: require('@/assets/surgery/endoprostheses-for-urogenology.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/endoprostheses-for-urogynecology`
    }, {
      title: i18n.global.t('main.carts.surgery.links.antiAdhesiveAgents'),
      img: require('@/assets/surgery/antiadhesions.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/antiadhesions`
    }, {
      title: i18n.global.t('main.carts.surgery.links.woundCovers'),
      img: require('@/assets/surgery/wound-dressings.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/wound-dressings`
    }, {
      title: i18n.global.t('main.carts.surgery.links.hemostaticSponge'),
      img: require('@/assets/surgery/hemostatic-sponge.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/hemostatic-sponge`
    }, {
      title: i18n.global.t('main.carts.surgery.links.productsPfZMCompany'),
      img: require('@/assets/surgery/zm-company-products.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/zm-production`
    }]);
    return {
      surgeryCarts,
      modules: [Pagination]
    };
  }

};