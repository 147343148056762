import { computed } from 'vue';
export default {
  name: 'CerclageWireWithHead',

  setup() {
    const tableData = computed(() => [{
      first: '0,6',
      second: '10',
      third: '15850-06010'
    }, {
      first: '0,8',
      second: '10',
      third: '15850-08010'
    }, {
      first: '1,0',
      second: '10',
      third: '15850-10010'
    }, {
      first: '1,2',
      second: '10',
      third: '15850-12010'
    }, {
      first: '1,4',
      second: '10',
      third: '15850-14010'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/cerclage.jpeg'),
      productsParams: [{
        id: 4,
        name: 'Матеріал',
        data: 'нерж. сталь'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};