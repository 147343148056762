import { computed } from 'vue';
import SmallPlate8x2 from './grid/SmallPlate8x2.vue';
import SmallPlateWithLimitedContact8x2 from './grid/SmallPlateWithLimitedContact8x2.vue';
import ReconstructivePlate8x2and7 from './grid/ReconstructivePlate8x2and7.vue';
import DirectPlate10x3 from './grid/DirectPlate10x3.vue';
import DirectPlateLimitedContact from './grid/DirectPlateLimitedContact.vue';
import SmallSelfCompresivePlate from './grid/SmallSelfCompresivePlate.vue';
import SelfCompresivePlateLimitedContact from './grid/SelfCompresivePlateLimitedContact.vue';
import PipePlate from './grid/PipePlate.vue';
import PipePlateForced from './grid/PipePlateForced.vue';
import ReconstructivePlate10x3 from './grid/ReconstructivePlate10x3.vue';
import PelvisReconstructivePlate from './grid/PelvisReconstructivePlate.vue';
import PlateYShaped from './grid/PlateYShaped.vue';
import ScatterBonePlate from './grid/ScatterBonePlate.vue';
import TShapedSmallPlate from './grid/TShapedSmallPlate.vue';
import SmallTShapedCurvedPlate from './grid/SmallTShapedCurvedPlate.vue';
import SelfCompresiveNarrowPlate from './grid/SelfCompresiveNarrowPlate.vue';
import SmallSelfCompresivePlateWithLimitedContact from './grid/SmallSelfCompresivePlateWithLimitedContact.vue';
export default {
  name: 'SmallPlates',

  setup() {
    const cardData = computed(() => [{
      title: 'Невеликі пластини',
      image: require('@/assets/traumatology/small-plates.jpeg')
    }]);
    return {
      cardData
    };
  },

  components: {
    SmallPlate8x2,
    SmallPlateWithLimitedContact8x2,
    ReconstructivePlate8x2and7,
    DirectPlate10x3,
    DirectPlateLimitedContact,
    SmallSelfCompresivePlate,
    SelfCompresivePlateLimitedContact,
    PipePlate,
    PipePlateForced,
    ReconstructivePlate10x3,
    PelvisReconstructivePlate,
    PlateYShaped,
    ScatterBonePlate,
    TShapedSmallPlate,
    SmallTShapedCurvedPlate,
    SelfCompresiveNarrowPlate,
    SmallSelfCompresivePlateWithLimitedContact
  }
};