import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const bactericidalIrradiator4Lamp300MData = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.bactericidalIrradiatorsLinks.bactericidalIrradiator4Lamp300M'),
      subtitle: '',
      image: require('@/assets/toolsAndEquipment/bactericida-iIrradiator-4Lamp300M.png'),
      productsParams: []
    }]);
    return {
      bactericidalIrradiator4Lamp300MData
    };
  }

};