import { computed } from 'vue';
import i18n from '@/i18n';
import ThreadResistantScrew3and5 from './grid/ThreadResistantScrew3and5.vue';
export default {
  name: 'ThreadResistantScrews',
  components: {
    ThreadResistantScrew3and5
  },

  setup() {
    const cardData = computed(() => [{
      title: i18n.global.t('main.carts.traumatology.threadResistantScrews.title'),
      image: require('@/assets/traumatology/screws-thread-stable.jpeg')
    }]);
    return {
      cardData
    };
  }

};