import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const stretcherMedicalA01Data = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.links.stretcherMedicalA01'),
      subtitle: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalA01.subtitle'),
      image: require('@/assets/toolsAndEquipment/medicalFurniture/stretcher-medical-A01.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalA01.dataLeft1'),
        data: "185х52х23"
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalA01.dataLeft2'),
        data: "93х52х10"
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalA01.dataLeft3'),
        data: "5"
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.medicalFurniture.stretcherMedicalA01.dataLeft4'),
        data: "160"
      }]
    }]);
    return {
      stretcherMedicalA01Data
    };
  }

};