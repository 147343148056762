import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-0396a7a9"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "blocked-plates"
};
const _hoisted_2 = {
  class: "container"
};
const _hoisted_3 = {
  href: "#"
};
const _hoisted_4 = {
  class: "blocked-plates__inner"
};
const _hoisted_5 = {
  class: "blocked-plates__inner-block"
};
const _hoisted_6 = {
  class: "blocked-plates__block"
};
const _hoisted_7 = {
  class: "blocked-plates__carts"
};
const _hoisted_8 = ["href"];
const _hoisted_9 = ["src"];
const _hoisted_10 = {
  class: "blocked-plates__cart-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MainHeader = _resolveComponent("MainHeader");

  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");

  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");

  const _component_LeftLinksMenu = _resolveComponent("LeftLinksMenu");

  const _component_MainFooter = _resolveComponent("MainFooter");

  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_MainHeader), _createVNode(_component_el_breadcrumb, {
    class: "breadcrumb",
    separator: "/"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_breadcrumb_item, {
      to: `/${_ctx.$i18n.locale}/catalog/`
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.catalog.block.title')), 1)]),
      _: 1
    }, 8, ["to"]), _createVNode(_component_el_breadcrumb_item, {
      to: `/${_ctx.$i18n.locale}/catalog/traumatology/`
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.carts.traumatology.title')), 1)]),
      _: 1
    }, 8, ["to"]), _createVNode(_component_el_breadcrumb_item, {
      to: `/${_ctx.$i18n.locale}/catalog/`
    }, {
      default: _withCtx(() => [_createElementVNode("a", _hoisted_3, _toDisplayString(_ctx.$t('main.carts.traumatology.links.aoScrews')), 1)]),
      _: 1
    }, 8, ["to"])]),
    _: 1
  }), _createElementVNode("div", _hoisted_4, [_createVNode(_component_LeftLinksMenu), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.traumatologyCarts, cart => {
    return _openBlock(), _createElementBlock("a", {
      key: cart,
      class: "blocked-plates__cart",
      href: cart.link
    }, [_createElementVNode("img", {
      src: cart.img,
      alt: "",
      class: "blocked-plates__cart-img"
    }, null, 8, _hoisted_9), _createElementVNode("div", _hoisted_10, _toDisplayString(cart.title), 1)], 8, _hoisted_8);
  }), 128))])])])])])]), _createVNode(_component_MainFooter)], 64);
}