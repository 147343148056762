import { computed } from 'vue';
export default {
  name: 'MiniPlateCondyle',

  setup() {
    const tableData = computed(() => [{
      first: '1,5',
      second: 'Ліва',
      third: '13130-02104',
      fourth: '33130-02104'
    }, {
      first: '1,5',
      second: 'Права',
      third: '13131-02104',
      fourth: '33131-02104'
    }, {
      first: '2,0',
      second: 'Ліва',
      third: '13130-02604',
      fourth: '33130-02604'
    }, {
      first: '2,0',
      second: 'Права',
      third: '13131-02604',
      fourth: '33131-02604'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/mini-plate-condyle.jpeg'),
      productsParams: [{
        id: 1,
        name: 'Товщина',
        data: '1,0 мм'
      }, {
        id: 2,
        name: 'Ширина',
        data: '4,5 мм'
      }, {
        id: 3,
        name: 'Діаметр гвинта',
        data: '1,5-2,0 мм'
      }, {
        id: 4,
        name: 'Матеріал',
        data: 'нерж. сталь або сплав титану'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};