import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-1d33436b"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "catalog-default_mobile__links"
};
const _hoisted_2 = {
  class: "catalog-default_mobile__inner-links"
};
const _hoisted_3 = {
  class: "catalog-default_mobile__carts"
};
const _hoisted_4 = ["href"];
const _hoisted_5 = ["src"];
const _hoisted_6 = {
  class: "catalog-default_mobile__cart-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ButtonLinks = _resolveComponent("ButtonLinks");

  const _component_swiper_slide = _resolveComponent("swiper-slide");

  const _component_Swiper = _resolveComponent("Swiper");

  const _component_MobilePageWrapper = _resolveComponent("MobilePageWrapper");

  return _openBlock(), _createBlock(_component_MobilePageWrapper, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_ButtonLinks)]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_Swiper, {
      "slides-per-view": 1,
      "space-between": 50,
      pagination: true,
      modules: $setup.modules
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.carts, cart => {
        return _openBlock(), _createBlock(_component_swiper_slide, {
          key: cart
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createElementVNode("a", {
            class: "catalog-default_mobile__cart",
            href: cart.link
          }, [_createElementVNode("img", {
            src: cart.img,
            alt: "",
            class: "catalog-default_mobile__cart-img"
          }, null, 8, _hoisted_5), _createElementVNode("div", _hoisted_6, _toDisplayString(cart.title), 1)], 8, _hoisted_4)])]),
          _: 2
        }, 1024);
      }), 128))]),
      _: 1
    }, 8, ["modules"])])]),
    _: 1
  });
}