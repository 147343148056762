import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "medical-instruments__container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");

  const _component_ToolsAndEquipmentLayuot = _resolveComponent("ToolsAndEquipmentLayuot");

  return _openBlock(), _createBlock(_component_ToolsAndEquipmentLayuot, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
      class: "button-download-pdf"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('main.carts.toolsAndEquipment.medicalInstrument.links.medicalInstrumentButton')), 1)]),
      _: 1
    })])]),
    _: 1
  });
}