import { computed } from 'vue';
export default {
  name: 'AnchorScrew4',

  setup() {
    const tableData = computed(() => [{
      first: '8',
      second: '32230-40008'
    }, {
      first: '10',
      second: '32230-40010'
    }, {
      first: '12',
      second: '32230-40012'
    }, {
      first: '14',
      second: '32230-40014'
    }, {
      first: '16',
      second: '32230-40015'
    }, {
      first: '18',
      second: '32230-40016'
    }, {
      first: '20',
      second: '32230-40018'
    }]);
    const cardData = computed(() => [{
      imageAddition: require('@/assets/traumatology/anchor-screw-4.jpeg'),
      productsParams: [{
        id: 5,
        name: 'Матеріал',
        data: 'сплав титану'
      }]
    }]);
    return {
      tableData,
      cardData
    };
  }

};