import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  setup() {
    const textData = computed(() => [{
      id: 1,
      data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.text1'),
      title: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.title1')
    }]);
    const omronNeC29Data = computed(() => [{
      title: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.links.omronNeC29'),
      subtitle: "",
      image: require('@/assets/toolsAndEquipment/inhalersNebulizers/omron-ne-C29.png'),
      productsParams: [{
        id: 1,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataLeft1'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataRight1')
      }, {
        id: 2,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataLeft2'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataRight2')
      }, {
        id: 3,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataLeft3'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataRight3')
      }, {
        id: 4,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataLeft4'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataRight4')
      }, {
        id: 5,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataLeft5'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataRight5')
      }, {
        id: 6,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataLeft6'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataRight6')
      }, {
        id: 7,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataLeft7'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataRight7')
      }, {
        id: 8,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataLeft8'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataRight8')
      }, {
        id: 9,
        name: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataLeft9'),
        data: i18n.global.t('main.carts.toolsAndEquipment.inhalersNebulizers.omronNeC29.dataRight9')
      }]
    }]);
    return {
      omronNeC29Data,
      textData
    };
  }

};