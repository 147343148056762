import { computed } from 'vue';
import i18n from '@/i18n';
export default {
  name: 'CatalogSurgery',

  setup() {
    const surgeryLinks = computed(() => [{
      name: i18n.global.t('main.carts.surgery.links.sutureMaterials'),
      path: `/${i18n.global.locale.value}/catalog/surgery/suture-materials`
    }, {
      name: i18n.global.t('main.carts.surgery.links.endoprosthesesPolymerNets'),
      path: `/${i18n.global.locale.value}/catalog/surgery/endoprostheses-polymer-meshes`
    }, {
      name: i18n.global.t('main.carts.surgery.links.endoprosthesesForUrogynecology'),
      path: `/${i18n.global.locale.value}/catalog/surgery/endoprostheses-for-urogynecology`
    }, {
      name: i18n.global.t('main.carts.surgery.links.antiAdhesiveAgents'),
      path: `/${i18n.global.locale.value}/catalog/surgery/antiadhesions`
    }, {
      name: i18n.global.t('main.carts.surgery.links.woundCovers'),
      path: `/${i18n.global.locale.value}/catalog/surgery/wound-dressings`
    }, {
      name: i18n.global.t('main.carts.surgery.links.hemostaticSponge'),
      path: `/${i18n.global.locale.value}/catalog/surgery/hemostatic-sponge`
    }, {
      name: i18n.global.t('main.carts.surgery.links.productsPfZMCompany'),
      path: `/${i18n.global.locale.value}/catalog/surgery/zm-production`
    }]);
    const surgeryCarts = computed(() => [{
      title: i18n.global.t('main.carts.surgery.links.sutureMaterials'),
      img: require('@/assets/surgery/suture-materials.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/suture-materials`
    }, {
      title: i18n.global.t('main.carts.surgery.links.endoprosthesesPolymerNets'),
      img: require('@/assets/surgery/endoprostheses.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/endoprostheses-polymer-meshes`
    }, {
      title: i18n.global.t('main.carts.surgery.links.endoprosthesesForUrogynecology'),
      img: require('@/assets/surgery/endoprostheses-for-urogenology.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/endoprostheses-for-urogynecology`
    }, {
      title: i18n.global.t('main.carts.surgery.links.antiAdhesiveAgents'),
      img: require('@/assets/surgery/antiadhesions.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/antiadhesions`
    }, {
      title: i18n.global.t('main.carts.surgery.links.woundCovers'),
      img: require('@/assets/surgery/wound-dressings.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/wound-dressings`
    }, {
      title: i18n.global.t('main.carts.surgery.links.hemostaticSponge'),
      img: require('@/assets/surgery/hemostatic-sponge.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/hemostatic-sponge`
    }, {
      title: i18n.global.t('main.carts.surgery.links.productsPfZMCompany'),
      img: require('@/assets/surgery/zm-company-products.jpeg'),
      link: `/${i18n.global.locale.value}/catalog/surgery/zm-production`
    }]);
    return {
      surgeryLinks,
      surgeryCarts
    };
  }

};